import { Injectable } from '@angular/core';

import { HttpResponse } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { Task } from '../../models/troly/task.model';
import { TrolyObject } from '../../models/troly_object';
import { ITrolyService, TrolyService } from './troly.service';


@Injectable({
    providedIn: 'root',
})

/*
  This class is in charge of all loading and unloading of a tag's profile.

  There can only ever be a single profile loaded for a given app instance.

  i.e. if you load an order, it will load and set the current tag to the tag in question
*/
export class TaskService extends TrolyService<Task> implements ITrolyService<Task> {

	/**
	 * The name or identifier of the current class, not otherwise available when running in "production mode". 
	 * It is used to output debugging information on the console, and also attached to translations of labels, product tours, etc
	 */
	public readonly __name: string = 'TaskService';

	constructor() { super('tasks'); }

	public make(payload: {} = {}): Task { return payload instanceof Task ? payload : new Task(payload); }

	/**
	 * Overrides the `TrolyService.call` method in order to extract taskable data from any of the supported nodes
	 * @param verb 
	 * @param obj 
	 * @param method 
	 * @param params 
	 * @param headers 
	 * @returns 
	 */
	protected cassssll<Task>(verb: string, obj?: TrolyObject, method?: string, params?: {}, headers?: {}): Observable<HttpResponse<Task>> {
		return super.call<Task>(verb, obj, method, params, headers).pipe(
			map((_: HttpResponse<Task>) => {
				if (_ && _.body) { 
					if (_.body[this.singular_node] && _.body[this.singular_node]['taskable']) {
						const taskable_node = _.body[this.singular_node]['taskable']['type']
						_.body[this.singular_node]['taskable'] = _.body[this.singular_node]['taskable'][taskable_node]
					} else if (_.body[this.plural_node])	{
						_.body[this.plural_node] = _.body[this.plural_node].map((task: Task) => {
							if (task['taskable']) {
								const taskable_node = task['taskable']['type']
								task['taskable'] = task['taskable'][taskable_node];
							}
							return task;
						});
					}
				}
				return _
			})
		);
	}

}
