import { Injectable } from '@angular/core';

import { Observable, map } from 'rxjs';
import { Interaction } from '../../models/troly/interaction.model';
import { Message } from '../../models/troly/message.model';
import { uuid } from '../../models/utils.models';
import { ITrolyService, TrolyService } from './troly.service';


@Injectable({
    providedIn: 'root',
})

/*
  This class is in charge of all loading and unloading of a interaction's profile.

  There can only ever be a single profile loaded for a given app instance.

  i.e. if you load an order, it will load and set the current interaction to the interaction in question
*/
export class InteractionService extends TrolyService<Interaction> implements ITrolyService<Interaction> {

	/**
	 * The name or identifier of the current class, not otherwise available when running in "production mode". 
	 * It is used to output debugging information on the console, and also attached to translations of labels, product tours, etc
	 */
	public readonly __name: string = 'InteractionService';

	constructor() { super('interactions'); }

	public make(payload: {} = {}): Interaction { return payload instanceof Interaction ? payload : new Interaction(payload); }

	public resendMessage(id:uuid): Observable<Message> {
		return this.call<Message>('post', new Interaction({id:id}), 'resend').pipe(map(_ => new Message(_.body['message'])))
	}
}
