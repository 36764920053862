import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";

@Component({
    selector: 'star-rating',
    templateUrl: './star-rating.component.html',
    styleUrls: ['./star-rating.component.scss'],
})
export class StarRatingComponent implements OnInit {

    public stretch:number;
    public imageUrl:SafeStyle;
    public bgUrl:SafeStyle;

    @Input() stars:number;
    @Input() scale:number=5;

    @Input() status:string='enabled';
    
    constructor (protected sanitizer: DomSanitizer) {

    }
    ngOnInit(): void {
        this.stretch = this.stars / this.scale * 100;
    }
}
