import { Injectable } from '@angular/core';

import { Tag } from '../../models/troly/tag.model';
import { ITrolyService, TrolyService } from './troly.service';

import { filter, map, tap } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
	providedIn: 'root',
})

/*
  This class is in charge of all loading and unloading of a tag's profile.

  There can only ever be a single profile loaded for a given app instance.

  i.e. if you load an order, it will load and set the current tag to the tag in question
*/
export class TagService extends TrolyService<Tag> implements ITrolyService<Tag> {

	/**
	 * The name or identifier of the current class, not otherwise available when running in "production mode". 
	 * It is used to output debugging information on the console, and also attached to translations of labels, product tours, etc
	 */
	public readonly __name: string = 'TagService';

	constructor() { super('tags'); }

	public make(payload: {} = {}): Tag { return payload instanceof Tag ? payload : new Tag(payload); }

	/**
	 * 
	 * @param service 
	 * @returns 
	 */
	public loadProductConfigTags(params?: {}, force?: boolean): Observable<Tag[]> {

		params = params || {}

		params['usage[]'] ||= ['products']
		params['category[]'] ||= ['config']

		if (params['variety']) {
			params['usage[]'].push(`products-${params['variety']}`)
			delete params['variety'];
		}

		let callKey:string = `~` + JSON.stringify(params);
		
		if (this.onInitOrForce(callKey, force)) {

			// no need to handle the duplicate calls // concurrent promises here, loadAttribute does that already;
			return this.list(params,1,50).pipe(
				filter(_ => !!_), 
				map(resp => resp.results.map(_ => this.make(_))), 
				tap(tags => { this.initObservers[callKey].next(tags) })
			)
		}

		return this.initObservers[callKey];
	}

	/**
	 * 
	 * @param service 
	 * @returns 
	 */
	public loadProductMarketingTags(params?: {}, force?: boolean): Observable<Tag[]> {

		params = params || {}

		params['usage[]'] ||= ['products']
		params['category[]'] ||= ['marketing']

		if (params['variety']) {
			params['usage[]'].push(`products-${params['variety']}`)
			delete params['variety'];
		}

		let callKey:string = `~` + JSON.stringify(params);
		
		if (this.onInitOrForce(callKey, force)) {

			// no need to handle the duplicate calls // concurrent promises here, loadAttribute does that already;
			return this.list(params,1,50).pipe(
				filter(_ => !!_), 
				map(resp => resp.results.map(_ => new Tag(_))), 
				tap(tags => { this.initObservers[callKey].next(tags) })
			)
		}

		return this.initObservers[callKey];
	}

}
