import { NgModule, inject } from '@angular/core';

//import * as Sentry from "@sentry/angular-ivy";

import { AppComponent } from './app.component';

import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';


import { SidenavService } from './shared/sidenav.service';

import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CloudinaryModule } from '@cloudinary/ng';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app.routing';
import { I18nMissingTranslationHandler, createI18nLoader } from './core/i18n_loader';
import { NetworkInterceptor } from './core/services/network.interceptor';
import { TrolyInterceptor } from './core/services/troly/troly.interceptor';
import { LayoutsModule } from './layouts/layouts.module';

// Prepare registration of other locales
//import localeFrExtra from '@angular/common/locales/extra/fr';
//import localeFr from '@angular/common/locales/fr';

//registerLocaleData(localeFr, 'fr-FR', localeFrExtra);

@NgModule({

	declarations: [ AppRoutingModule.components ],
	
	imports: [

		BrowserModule,      // needed in app to load browser-based dependencies -- https://stackoverflow.com/questions/59031570/nullinjectorerror-no-provider-for-highcontrastmodedetector

		LayoutsModule,      // controls which layout should be rendered based on subsequent routing

		AppRoutingModule,   // All Troly Routing is defined here, along with lazy-laoded modules

		BrowserAnimationsModule,

			//TrolyMaterialModule,
			//CommonModule,       // common angular declarations

		//HttpClientModule,   // required for httpclient used to load translations below.

		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createI18nLoader,
				deps: [HttpClient],
			},
			missingTranslationHandler: {
				provide: MissingTranslationHandler,
				useClass: I18nMissingTranslationHandler
			}
		}),

		CloudinaryModule,
		NgbModule

		// required to make the app as PWA -- serves the manifest.webmanifest
		//ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),

	],

	providers: [
		
		{ provide: HTTP_INTERCEPTORS, useClass: NetworkInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: TrolyInterceptor, multi: true },
		
		provideHttpClient(withInterceptorsFromDi()),

		SidenavService,
		
		//TranslateStore, // See https://stackoverflow.com/questions/47672667/error-no-provider-for-translatestore
		/*Title, TrolyApi*/
		/*{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler({
				showDialog: true,
			}),
		},
		{
		provide: Sentry.TraceService,
		deps: [Router],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => {},
			deps: [Sentry.TraceService],
			multi: true,
			},*/
		],
	exports: [TranslateModule],
	bootstrap: [AppComponent],
})
export class AppModule {

	protected translate: TranslateService = inject(TranslateService);
	constructor() {

		//this.translate.setDefaultLang('en');

		// If we have user in localStorage, attempt to use them
		let user = JSON.parse(localStorage.getItem('us')); // 'us' is stored/retrieved from Troly API Service // and also user nav settings
		let company = JSON.parse(localStorage.getItem('co')); // 'co' is stored/retrieved from Troly API Service
		let language = user?.locale || company?.locale || navigator.language;

		this.translate.addLangs(['de-DE', 'en-US', 'en-AU', 'en-CA', 'en-GB', 'en-ZA', 'es-ES', 'fr-FR', 'fr-CA', 'it-IT', 'nl-NL'])
		if (this.translate.langs.includes(language)) {
			this.translate.use(language);
		} else {
			this.translate.use('en-US');
		}
	}
}