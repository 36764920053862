import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[preventDefault]',
  standalone: true
})
export class preventDefaultDirective {

	@HostListener("click", ["$event"])
	public onClick(event: any): void {
		event.preventDefault();
		event.stopPropagation();
	}
}