import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class RoleGuard  {
    constructor(private router: Router) {}

    // angular definition to lock routes that require authentication
    // returns either true or false depending on if the active company is locked
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        
        // not locked so return true
        return true;
    }
}
