import { Component, Input } from '@angular/core';
import { TrolyComponent } from 'src/app/core/components/troly.component';
import { TrolyWidgetDatapoint } from '../datapoints-w-charts/datapoints-w-charts.card';

@Component({
    selector: 'datapoint-widget',
    templateUrl: './datapoint.widget.html',
    styleUrls: ['./datapoint.widget.scss'],
})
export class DatapointWidget extends TrolyComponent {

	/**
	 * The name or identifier of the current class, not otherwise available when running in "production mode".
	 * @see TrolyComponent.__name (Override)
	 */
	override readonly __name:string = 'DatapointWidget';

	@Input() heading:string;
	@Input() number:TrolyWidgetDatapoint; 
	@Input() tooltip:string;

}