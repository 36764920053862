<div class="row justify-content-center mt-4">
	<div class="col-4 text-center">
		<div *ngIf="loading" class="loader"></div>
		<img *ngIf="!loading" src="https://res.cloudinary.com/troly/image/upload/w_150/cdn/brand/3.0/troly-logo-circle-0288d1-43a047.png"
			alt="Troly logo" />
	</div>
</div>
<div class="row justify-content-center mt-4">
	<div class="col-12 col-sm-9 col-md-7 col-lg-5 col-xl-5">
		<h4 *ngIf="loading" class="p-3 text-center" style="font-weight:800;color:var(--bs-white)">Redirecting..</h4>
		<h4 *ngIf="!loading" class="p-3 text-center" style="font-weight:800;color:var(--bs-light)">Not Found</h4>

	<div class="card auto-height-flash-card" *ngIf="!loading">
		<div class="{{ bsCssClassPrefix }}-header">
			<div
				class="header-background bg-secondary bg-soft"
				style="background-image: url('https://res.cloudinary.com/troly/image/upload/cdn/app/static/companies/seasons/wine/summer.jpg');"
			></div>
		</div>
		<!-- card-header -->

		<div class="{{ bsCssClassPrefix }}-body">

			<div class="row">
				<div class="col-12 text-center mt-4">
					<h4>Link Invalid</h4>
					<p>The link followed appears to be invalid. Please try again.</p>
				</div>
			</div>
		</div>
	</div>
	<!-- card -->
	</div>
</div>