import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
   name: 'callbackFilter',
})
export class CallbackFilterPipe implements PipeTransform {
	
	transform<T>(items: T[], callback: (item: T, args?:any) => boolean, args?:any|any[]): any {
		if (!items || !callback) {
			return items;
		}
		if (args) { return items.filter((item) => callback(item)); }
		else { return items.filter((item) => callback(item, args)); }
	}
}
