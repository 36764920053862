<apx-chart
    class="apex-charts"
    dir="ltr"
    [series]="chartOptions.series"
	 [legend]="chartOptions.legend"
	 [stroke]="chartOptions.stroke"
	 [xaxis]="chartOptions.xaxis"
	 [yaxis]="chartOptions.yaxis"
    [chart]="chartOptions.chart"
    [colors]="chartOptions.colors"
    [plotOptions]="chartOptions.plotOptions"
    [dataLabels]="chartOptions.dataLabels"
	 [grid]="chartOptions.grid"
>
</apx-chart>
