import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { take } from 'rxjs';

import { TrolyComponent } from 'src/app/core/components/troly.component';
import { I18nLoader } from 'src/app/core/i18n_loader';
import { IWebsocketStatus, WebsocketService } from 'src/app/core/services/troly/websocket.service';


@Component({
	selector: 'socket-status',
	templateUrl: './socket-status.component.html',
	styleUrls: ['./socket-status.component.scss'],
})
export class SocketStatusComponent extends TrolyComponent {

	override readonly __name:string = 'SocketStatusComponent'

	@Input() showBadge: boolean = true;

	@Input() messageFilterPattern: RegExp|string = '';
	@Output() messageCallback: EventEmitter<any> = new EventEmitter();

	public status: IWebsocketStatus;

	protected websocketService: WebsocketService = inject(WebsocketService)

	constructor() {
		super();

		(this.translateService.currentLoader as I18nLoader).getTranslation(this.translateService.currentLang, 'shared/widgets/sockets', 'Sockets').pipe(take(1)).subscribe(_ => {
			this.translateService.setTranslation(this.translateService.currentLang, _);
		});

		this.websocketService.status.subscribe(_ => this.status = _)
	}
}
