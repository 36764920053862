<span *ngIf="_debug.length > 0">

<ng-container *ngIf="json">
	<div class="{{class}} p-2 border-{{color}} bg-soft bg-{{color}} mb-2" style="border-radius:4px;border:1px dashed transparent">
		<pre>{{ json | json }}</pre>
	</div>
</ng-container>

<ng-container *ngIf="record">
<div class="{{class}} p-2 border-{{color}} bg-soft bg-{{color}} mb-2" style="border-radius:4px;border:1px dashed transparent">
	<span class="text-start pt-1 badge bg-{{color}} " style="font-family: monospace; vertical-align: baseline;">{{record._trolyModelName.substring(0,3)}} {{ record.id }}</span> 
	<a *ngIf="url" class="ms-1" href="{{ url }}" target="_blank"><i class="bx bx-link-external text-{{color}}"></i></a>
	<pre *ngIf="attributes.length > 0" class="text-{{color}} mt-1 mb-0"><ng-container *ngFor="let attr of attributes"><strong>{{attr}}:</strong> {{ record[attr] }}<br/></ng-container></pre>
</div>
</ng-container>

<ng-container *ngIf="component">
	<div class="{{class}} p-2 border-{{color}} bg-soft bg-{{color}} mb-2" style="border-radius:4px;border:1px dashed transparent">
		<span class="text-start pt-1 badge bg-{{color}} " style="font-family: monospace; vertical-align: baseline;">{{component.__name}}</span> 
		<pre *ngIf="attributes.length > 0" class="text-{{color}} mt-1 mb-0"><ng-container *ngFor="let attr of attributes"><strong>{{attr}}:</strong> {{ component[attr] }}<br/></ng-container></pre>
	</div>
</ng-container>


<ng-container *ngIf="form">
<div class="p-2 border-{{color}} bg-soft bg-{{color}} mb-2" style="border-radius:4px;border:1px dashed transparent">
	<h5><i class="bx bxs-caret-down-square float-end float-end" [class.rotate]="showFormDetails" (click)="showFormDetails=!showFormDetails"></i>
		Ready to submit: {{ !form.cannotSubmit }}</h5>
	<pre>loading: {{ form.loading }}, invalid: {{ form.invalid }}, pristine: {{ form.pristine }}</pre>
	<table class="w-100" *ngIf="showFormDetails">
		<thead>
			<tr class="bg-{{color}} text-white">
				<th>Form Attribute</th>
				<th>Control Details</th>
				<th style="width:20px !important;"></th>
				<th class="text-center" style="width:20px !important;">P</th>
				<th class="text-center" style="width:20px !important;">V</th>
				<th class="text-center" style="width:20px !important;">T</th>
			</tr>
		</thead>
		<tbody class="font-size-11" style="line-height:13px;">

			<ng-container [ngTemplateOutlet]="renderGroup" [ngTemplateOutletContext]="{name: '', form:form, controls: form.controls, depth:0}"></ng-container>
						
		</tbody>
	</table>
	<h5>Form changes
		<span *ngIf="form.code">({{form.code}})</span>
		<i class="bx bxs-caret-down-square float-end float-end" [class.rotate]="showFormChanges" (click)="showFormChanges=!showFormChanges"></i>
	</h5>
	<pre class="mb-0" *ngIf="showFormChanges">{{ form.getChanges() | json }}</pre>
	<h5>Form raw
		<i class="bx bxs-caret-down-square float-end float-end" [class.rotate]="showFormRaw" (click)="showFormRaw=!showFormRaw"></i>
	</h5>
	<pre class="mb-0" *ngIf="showFormRaw">{{ form.getRawValue()| json }}</pre>
</div>

<ng-template #renderControl let-name="name" let-prefix="prefix" let-control="control" let-depth="depth">

	<tr>
		<td style="vertical-align: top;">
			<p class="pt-1 mb-2 ps-{{depth}} ms-{{depth}}"><span *ngIf="prefix">{{ prefix }}. </span><strong>{{ name }}:</strong><br/>
			<span class="badge" *ngIf="control.value" [ngClass]="{
				'badge-soft-orange': control.value.constructor.name == 'String',
				'badge-soft-primary': control.value.constructor.name == 'Number',
				'badge-soft-green': control.value.constructor.name == 'Boolean',
				'badge-soft-purple': control.value.constructor.name == 'Object',
				'badge-soft-pink': control.value.constructor.name == 'Array',
				'badge-soft-secondary': control.value.constructor.name == 'Color',
			}">{{ control.value.constructor.name }}</span></p>
		</td>
		<td style="vertical-align: top;">
			<ng-container *ngIf="control.value">
				<pre class="pt-1" *ngIf="control.value.constructor.name == 'String' || control.value.constructor.name == 'Number' || control.value.constructor.name == 'Boolean'">{{ control.value }}</pre>
				<pre class="pt-1" *ngIf="control.value.constructor.name == 'Array' && !control.value.controls">{{ control.value }}</pre>
			</ng-container>
			<code *ngIf="control?.errors">{{ control.errors | json }}</code>
		</td>
		
		<td class="text-center">
			<i class="bx bx-undo font-size-16 text-secondary" *ngIf="control.dirty" (click)="control.reset()" ngbTooltip="RESET"></i>
		</td>
		<td class="text-center">
			<i class="bx bx-check font-size-24 text-success" *ngIf="control.valid" ngbTooltip="VALID"></i>
			<i class="bx bx-x font-size-24 text-danger" *ngIf="control.invalid" ngbTooltip="INVALID"></i>
		</td>
		<td class="text-center cursor-pointer">
			<div *ngIf="control.pristine" ngbTooltip="PRISTINE" placement="left" (click)="control.markAsDirty()">•</div>
			<div *ngIf="control.dirty" ngbTooltip="DIRTY" placement="left" (click)="control.markAsPristine()">💩</div>
		</td>
		<td class="text-center cursor-pointer">
			<div *ngIf="control.touched" ngbTooltip="TOUCHED" placement="left" (click)="control.markAsUntouched()">📝</div>
			<div *ngIf="control.untouched" ngbTooltip="UNTOUCHED" placement="left" (click)="control.markAsTouched()">•</div>
		</td>
	</tr>
</ng-template>

<ng-template #renderGroup let-prefix="prefix" let-controls="controls" let-form="form" let-depth="depth">

	<ng-container *ngFor="let ctl of controls | keyvalue">
				
		<ng-container [ngTemplateOutlet]="renderControl" [ngTemplateOutletContext]="{prefix:prefix, name: ctl.key, control: ctl.value, depth:depth}"></ng-container>
		
		<ng-container *ngIf="isGroup(form.get(ctl.key))">
			<ng-container *ngFor="let sctl of form.get(ctl.key).controls | keyvalue" [ngTemplateOutlet]="renderControl" [ngTemplateOutletContext]="{name: sctl.key, form: form.get(ctl.key), control: sctl.value, depth:depth+1}"></ng-container>
		</ng-container>
		
		<ng-container *ngIf="isArray(form.get(ctl.key)) && form.get(ctl.key).controls">
			<ng-container *ngFor="let sctl of form.get(ctl.key).controls; let i = index" [ngTemplateOutlet]="renderGroup" [ngTemplateOutletContext]="{prefix: ''+i, form: form.get(ctl.key + '.' + i), controls: sctl.controls, depth:depth+1}"></ng-container>
		</ng-container>

	</ng-container>

	<!--ng-container *ngFor="let ctl of controls | keyvalue" [ngTemplateOutlet]="renderControl" [ngTemplateOutletContext]="{name: ctl.key, control: ctl.value, depth:depth+1}"></ng-container-->
</ng-template>

</ng-container>

</span>

