import { Directive, ElementRef, Input, OnChanges, OnInit } from '@angular/core';

import { uuid } from '../../models/utils.models';
import { AvatarService } from './avatar.service';

@Directive({
  selector: '[avatar]'
})
export class AvatarDirective implements OnInit, OnChanges {

  protected defaultHTML:string;

  constructor(
    private elemRef: ElementRef, 
    private avatarService: AvatarService
    ) {

   }

  @Input('cid') id: uuid;
  @Input('email') email?: string;
  @Input('size') size?: number=144;
  @Input('avatar-class') avatarClass?: string='';

  ngOnInit() {
    this.defaultHTML = this.elemRef.nativeElement.innerHTML
  }

	ngOnChanges() {
		if (this.id) {
			this.avatarService.url(this.id, this.email, this.size).then((_url:string) => {
				if (_url) { 
				this.renderImg(_url) 
				} else {
				this.elemRef.nativeElement.innerHTML = this.defaultHTML;
				}
			});
		}
	}

  	renderImg(url:string) {
		this.elemRef.nativeElement.innerHTML = this.avatarClass ? `<img src="${url}" class="${this.avatarClass}" />` : `<img src="${url}" />` ;
	}
}