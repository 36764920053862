import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'obfuscate'
})
export class ObfuscatePipe implements PipeTransform {

	transform(value: string, options: {} = {}): string {

		const level = options['level'] || 'normal'

		if (value.match('@')) {
			value = this.obfuscateEmail(value, level);
		} else {
			value = this.obfuscatePhoneNumber(value, level);
		}
		
		return value.trim()
	}


	private obfuscateEmail(email: string, level: 'low' | 'normal' | 'high'): string {
		const parts = email.split('@');
		const localPart = parts[0];
		const domainPart = parts[1];

		switch (level) {
			case 'low':
				return `${this.obfuscate(localPart, 40)}@${domainPart}`;
			case 'normal':
				return `${this.obfuscate(localPart, 30)}@${this.obfuscate(domainPart, 30, 'start')}`;
			case 'high':
				const ext = domainPart.split('.');
				const domain = ext.shift();
				console.log(ext, domain)
				return `${this.obfuscate(localPart, 50)}@${this.obfuscate(domain,99)}.${ext.join('.')}`;
			default:
				return email;
		}
	}


	private obfuscatePhoneNumber(phoneNumber: string, level: 'low' | 'normal' | 'high'): string {
		const normalizedPhoneNumber = phoneNumber.replace(/\D/g, ''); // Remove non-numeric characters

		switch (level) {
			case 'low':
				// Replace the middle part of the number
				const middleIndex = Math.floor(normalizedPhoneNumber.length / 2);
				const obfuscatedMiddle = this.obfuscate(normalizedPhoneNumber.substring(middleIndex - 2, middleIndex + 2), 100);
				return normalizedPhoneNumber.substring(0, middleIndex - 2) + obfuscatedMiddle + normalizedPhoneNumber.substring(middleIndex + 2);
			case 'normal':
				// Replace the last four digits
				return normalizedPhoneNumber.substring(0, normalizedPhoneNumber.length - 4) + '****';
			case 'high':
				// Replace all but the last four digits
				return '****' + normalizedPhoneNumber.substring(normalizedPhoneNumber.length - 4);
			default:
				return phoneNumber;
		}
	}

	private obfuscate (str: string, percentage: number, mode:'start'|'end'='end'): string {
		let charsToReplace = Math.ceil(str.length * percentage / 100);

		if (charsToReplace == str.length) { charsToReplace-- } 
		
		const start = str.length - charsToReplace

		if (mode == 'end') {
			return str.substring(0, start) + '*'.repeat(charsToReplace)
		} else {
			return '*'.repeat(charsToReplace) + str.substring(start-2)
		}
	};

}
