import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'happy-or-not',
	templateUrl: './happy-or-not.widget.html',
	styleUrls: ['./happy-or-not.widget.scss'],
})
export class HappyOrNotWidget {

	@Input() neutral_bg: string = 'ffffff';
	@Input() neutral_fg: string = '000000';

	@Input() happy_bg: string = '43A047'; 
	@Input() happy_fg: string = '43A047' 
	@Input() unhappy_bg: string = 'dd4d4d'; 
	@Input() unhappy_fg: string = 'dd4d4d' 

	@Input() size: number = 80;

	@Input() set value(v: 'happy'|'unhappy') { this._value = v; }
	@Output() change: EventEmitter<'happy'|'unhappy'|null> = new EventEmitter<'happy'|'unhappy'|null>();

	public _value:'happy'|'unhappy';

	ngOnInit() {
		this.change.subscribe(_ => { this._value = _})
	}
	
	toggleValue(trigger:'happy'|'unhappy') {
		if (this._value == trigger) { this.change.next(null) } 
		else { this.change.next(trigger); }
	}

	get happyTransformations(): {} {
		return { c:'scale', h:this.size*3, w:this.size*3, e:`replace_color:${this.happy_fg}:99:000000`, f:'png' }
	}
	get unhappyTransformations(): {} {
		return { c:'scale', h:this.size*3, w:this.size*3, e:`replace_color:${this.unhappy_fg}:99:000000`, f:'png' }
	}
}