import { Directive, HostListener, inject } from '@angular/core';
import { WindowService } from '../services/window.service';

@Directive({
  selector: '[closeBeforeNavigate]',
  standalone: true
})
export class closeBeforeNavigateDirective {

	private windowService:WindowService = inject(WindowService);

	@HostListener("click", ["$event"])
	public onClick(event: any): void {
		this.windowService.currentModal?.dismiss();
	}
}