import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { TrolySearch } from '../../models/form_objects';
import { Job } from '../../models/troly/job.model';
import { TrolyObject } from '../../models/troly_object';
import { uuid } from '../../models/utils.models';
import { GenericService } from '../generic.service';
import { TrolyValidationError } from './troly.interceptor';



/**
 * 
 */
@Injectable()
export abstract class TrolyService<T extends TrolyObject> extends GenericService<T> {

	/**
	 * The name or identifier of the current class, not otherwise available when running in "production mode". 
	 * It is used to output debugging information on the console, and also attached to translations of labels, product tours, etc
	 */
	public readonly __name: string = 'TrolyService';

	public readonly singular_node:string;
	public readonly plural_node:string;

	constructor(endpoint:string, plural_node?:string, singular_node?:string) {
		super(endpoint);

		this.plural_node = plural_node || endpoint;
		this.singular_node = singular_node || this.make()._trolyModelName;
	}

	/**
	 * 
	 * @param operation 
	 * @param result 
	 * @returns 
	 */
	protected serviceError<T>(error: HttpErrorResponse, caught: Observable<any>): Observable<HttpResponse<T>> {

		if (error instanceof TrolyValidationError) {
			throw error;
		}

		// throw the error so that the individual services can handle them
		return super.serviceError(error, caught);
	}

	public objectUrl(obj?: T): string {
		if (obj) {
			return `${this.apiBaseUrl}/${this.endpoint}/${obj.id}.json`;
		} else {
			return `${this.apiBaseUrl}/${this.endpoint}.json`;
		}
	}

	/**
	 * 
	 * @param obj {T} 
	 * @param preserve_attr {string[]}
	 * @returns {T|null}
	 */
	public _next(obj: T, flush_attr?: string|string[], force:boolean=false): T {

		// we only want to notify a new record to subscribers if 
		// 1-we don't have a current record
		// 2-we have a current record and it's the curent one.
		// 3-we have a force flush requested
		if (!this.record$.value || !this.record$.value.id || obj.sameAs(this.record$?.value)) {

			let prevObj = this.record$.value;

			// then we only want to preserve some attributes if we are 
			// 1-      NOT flushing (forcing a fresh new dataset),
			// 2- and  we have a previous object to get previous values from
			// 3- and  we have attributes to preserve
			if (prevObj && !force) {
				
				flush_attr ||= []
				const flush_arr:string[] = flush_attr instanceof Array ? flush_attr : [flush_attr]
				
				Object.keys(prevObj).filter(_ => !flush_arr.includes(_)).forEach((k) => {
					if (prevObj[k] != undefined && obj[k] == undefined) {
						// previous attributes are applied if the new object DOESN'T have them
						// and the previous one DOES have them
						obj[k] = prevObj[k];
					}
				})
			}
			
			this.record$.next(obj);
			return obj;
		} else if (force) {
			this.record$.next(obj);
			return obj;
		}

		return null;
	}

	/**
	 * 
	 * ? note: this is a public method, but in most cases we should use the 'list' method, which is essentially a search. the TaggableModule however overrides the endpoint called, so it must be public (short of passing a new param to list())
	 * @param term 
	 * @param page 
	 * @param limit 
	 * @param filter 
	 * @returns An observable (STARTS WITH NULL) to a Search Response call for a `T` given list of objects
	 */
	public search(term: string = '', page: number = 1, limit: number = 25, params?: {}, method?:string): Observable<TrolySearch<T>> {

		params = params || {}

		if (term && term.trim().length > 0) { params['search'] = term.trim() }
		
		params['page'] = page;
		params['limit'] = limit;

		let callKey:string = `~${this.__name}.search~${page}:${limit}` + JSON.stringify(params);

		if (this.onInitOrForce(callKey)) {
			this.callSearch(method,params).subscribe(this.initObservers[callKey])
		}

		return this.initObservers[callKey]
	}

	protected callSearch(method, params): Observable<TrolySearch<T>> {
	
		return this.call<TrolySearch<T>>('get', null, method, params).pipe(
			//tap(_ => this.log(`> search '${term}': ${_.body.meta.count} result${_.body.meta.count != 1 ? 's' : ''} (p:${_.body.meta.page} l:${_.body.meta.limit} )`)),
			map(_ => {
				let search = new TrolySearch<T>(_.body, this.plural_node)
				search.results = search.results.map(o => this.make(o))
				return search;
			})
		)
	}


	/**
	 * Retrieves the object requested we are looking for, without notifying subscribers (As opposed to `_find()`)
	 * @param id `uuid` for the desired api object
	 * @param filter any retrieval options as required -- see `CompaniesController.rb`
	 * @returns `Observable<T>` (NOT NULL)
	 */
	public find(id: uuid, params?: {}, method?:string): Observable<T> {
		// we set the params to {} if not supplied, because when we 'make' an object, any attributes of that object (other than the id) become params and polutes the query
		return this.get(id ? this.make({id:id}) : null, method, params || {}).pipe(map(_ => this.make(_.body[this.singular_node])));
	}

	/**
	 * Retrieves and communicates the object requested / we are looking for, **notifies subscribers** of this object (as opposed to `find()` which loads any arbitrary record )
	 * @param id 
	 * @param params 
	 * @param force 
	 * @returns An observable (STARTS WITH NULL) to a `T` object
	 */
	public _find(id: uuid, params?: {}, method?:string, force?: boolean): Observable<T> {

		force = force || (this.record$?.value && this.record$.value['id'] != id)

		let callKey:string = `~${id}~` + JSON.stringify(params);

		if (this.onInitOrForce(callKey, force)) {
			this.find(id, params, method).pipe(
				tap(_ => {
					if (_ = this._next(_, [], force)) {
						// the _next function returns the updated record IF it was updated, or nil otherwise.
						// we only update the subsribers if it was updated (hence it's the correct match)
						// for the underscored FIND.
						// to avoid this, we can call .find() directly, instead of ._find().
						this.initObservers[callKey].next(_);
					}
				}))
				.subscribe(this.initObservers[callKey]);
		}
		return this.initObservers[callKey]
	}

	/**
	 * 
	 * @param payload 
	 */
	protected listToCache(payload: T[]) {
		const updatedRecords = (this.records$.value || []).concat(payload);
		if (this._recordsCacheKey) {
			this.records$.next(this.cachedDbKey<T[]>(this._recordsCacheKey, updatedRecords));
		} else {
			this.records$.next(updatedRecords);
		}
	}

	/**
	 * 
	 * @param term 
	 * @param page 
	 * @param limit 
	 * @param filter
	 * @returns An observable (STARTS WITH NULL) to a list of objects
	 */
	public list(params?: {}, page?: number, limit?: number, force?: boolean): Observable<TrolySearch<T>> {

		page = page || 1
		limit = limit || 25
		// autoload = false;
		force = force || false;
		const searchQuery = params['query'] || params['q'] || '';
		delete params['query']; delete params['q'];

		
		// onInitOrForce is handled already by the the `.search`
		return this.search(searchQuery, page, limit, params).pipe(filter(_ => !!_))
	}

	/**
	 * Retrieves and stores a single record count for any search parameters supported by the API/Controller.
	 * This is a convenience method for the list() method, which returns a TrolySearch object.
	 * Value retrieved is sent to uiNotifier$ as key to a hash of values.
	 * @param params 
	 * @param force 
	 * @returns An observable (STARTS WITH NULL) to a number which
	 */
	public updateRecordsCount(key:string, params?: {}): void {

		this.list(params, 1, 0).pipe(filter(_ => !!_)).subscribe({
			next: (_) => this.storeUiKey(key, _.meta.count.toString(), 12),
			error: (_) => console.error(_)
		})
	}

	/**
	 * 
	 * @param obj 
	 * @param method 
	 * @param params 
	 * @param force 
	 * @returns An observable (STARTS WITH NULL) to a list of objects
	 */
	public getList<U>(obj: TrolyObject, method, params, force, node_name?:string): Observable<U[]> {

		let callKey = `~${obj.id}~${method}~` + JSON.stringify(params);

		if (this.onInitOrForce(callKey, force)) {
			this.call('get', obj, method, params).pipe(map(_ => _.body[node_name || method])).subscribe(this.initObservers[callKey])
		}
		return this.initObservers[callKey]
	}

	/**
	 * Creates a new object `T` after posting the API, extracting the object from the response and updating the cache in the process
	 * @param payload
	 * @param method 
	 * @returns Observable<T> object instantiated as created
	 */
	public create(payload: T, params?: {}, method?: string): Observable<T> {
		return this.post(payload, method, params).pipe(
			map(_ => this.make(_.body[this.singular_node])),
			tap((_) => this.addToCache(_)),
		);
	}

	/**
	 * 
	 * @param payload
	 * @param method 
	 * @returns 
	 */
	public createJob(payload: TrolyObject, method?: string, params?:{}): Observable<Job> {
		return this.call<Job>('post', payload, method, params).pipe(
			map(_ => new Job(_.body['job'])),
		);
	}

	/**
	 * Adds a new record to the list of records, notifies subscribers and optionally, updates the cache.
	 * If the new record DID exist, it's removed. The new one added to the end.
	 * @param payload 
	 */
	protected addToCache(payload: T) {

		if (payload.id) {
			const updatedRecords = (this.records$.value || []).filter(_ => !_.sameAs(payload));
			updatedRecords.push(payload);

			if (this._recordsCacheKey) {
				this.records$.next(this.storeDbKey<T[]>(this._recordsCacheKey, updatedRecords));
			} else {
				this.records$.next(updatedRecords);
			}
		}
	}

	/**
	 * 
	 * @param payload 
	 * @returns 
	 */
	public save(payload?: T, params?: {}, method?: string): Observable<T> {
		return this.put(payload, method, params).pipe(
			map(_ => this.make(_.body[this.singular_node])),
			tap((_) => {
				this._next(_); 										// just in case we are editing the record currently being viewed
				this.updateInCache(_);
			}),
			catchError(e => {
				debugger;
				// prevents from returning a 400 error directly to the subscriber, instead returns an object with errors
				if (e.error[this.singular_node]) { return of(e.error[this.singular_node]) }
				throw e
			})
			  
		);
	}

	/**
	 * Updates a record in the list of records, notifies subscribers and optionally, updates the cache.
	 * If the new record didn't exist, it's added, else it's replaced entirely (not merged).
	 * @param payload 
	 */
	protected updateInCache(payload: T) {
		const updatedRecords = (this.records$.value || []).map(_ => _.sameAs(payload) ? payload : _);
		if (this._recordsCacheKey) {
			this.records$.next(this.storeDbKey<T[]>(this._recordsCacheKey, updatedRecords));
		} else {
			this.records$.next(updatedRecords);
		}
	}

	/**
	 *
	 * @param payload 
	 * @returns 
	 */
	public remove(id: uuid, params?: {}, method?: string): Observable<T> {
		// it's possible that we are receiving an object already built here, and this object will have
		// its ID attribute deleted, by the .call() function. As a result/convenience, we can then force a clean
		// payload here and send that instead. I don't believe any service would ever be deleting an object as which they don't manage.
		let cleanPayload = this.make({id:id}) // payload can be null if we're deleting a session via DELETE /users/auth, this means all public methods (which wrap the protected http-verbs methods) should support a missing payload. `.call` does
		return this.delete(cleanPayload, method, params).pipe(
			map(_ => this.make(_.body[this.singular_node])),
			tap(_ => this.removeFromCache(_)),
			catchError(e => of(e.error[this.singular_node])) // prevents from returning a 400 error directly to the subscriber, instead returns an object with errors
		);
	}

	/**
	 * Removes a record from the list of records, notifies subscribers and optionally, updates the cache.
	 * If the new record did exist, it's removed. Irrespectively, a notification is sent.
	 * @param payload 
	 */
	protected removeFromCache(payload: T) {
		const updatedRecords = (this.records$.value || []).filter(_ => !_.sameAs(payload));
		if (this._recordsCacheKey) {
			this.records$.next(this.storeDbKey<T[]>(this._recordsCacheKey, updatedRecords));
		} else {
			this.records$.next(updatedRecords);
		}
	}

	/**
	 * Overrides the GenericService.call method in order to extract the X-CSRF-Token header when present.
	 * This is used when authenticating a user, to properly receive the correct value. This value is stored, and later, added to
	 * each POST and PUT requests. 
	 * investigate: it's not clear how the value is updated from subsequent API call responses?
	 * @param verb 
	 * @param obj 
	 * @param method 
	 * @param params 
	 * @param headers 
	 * @returns 
	 */
	protected call<T>(verb: string, obj?: TrolyObject, method?: string, params?: {}, headers?: {}): Observable<HttpResponse<T>> {
		return super.call<T>(verb, obj, method, params, headers).pipe(
			tap((_: HttpResponse<T>) => { // note: this is a tap, not a map
				if (_ && _.headers) {
					let csrf = _.headers.get('X-CSRF-Token');
					if (csrf) { this.storedCsrf(csrf); 
						//console.log(`X-CSRF-Token updated to ${csrf}`); 
					}
				}
			})
		);
	}

	public socketCall(verb: string, obj?: TrolyObject, method: string = '', endpoint?: string): Observable<Job> {
		return this.call<Job>(verb, obj, method, endpoint).pipe(map(_ => _.body))
	}


	/**
	 * 
	 * @param payload 
	 * @param method 
	 * @returns An observable (NEVER NULL) to a `T` object
	 */
	public saveOrCreate(payload: T, params?:{}, method?: string): Observable<T> {
		if (!payload.id && !payload[payload._trolyModelName]?.id) { return this.create(payload, params, method) }
		else { return this.save(payload, params, method) }
	}


	/**
	 * Loads statistics data for the current endpoint, at large or alternatively, for all records.
	 * 
	 * loadStat() => calls /endpoint/stat
	 * loadStat(null,'stats') => calls /endpoint/stats
	 * loadStat(record) => calls /endpoint/:id/stat
	 * loadStat(record,'stats') => calls /endpoint/:id/stats
	 * Only the filter id is being used, and stats lookup attributes are supported for 'stats' only (eg. `range` and `p`). 
	 * @param record the record for which we would like to load the stat. This record will be updated with the correct stat|stats attribute
	 * @param method whether to load most current
	 * @param force whether or not to load and reload data on an object
	 * @returns Observable (STARTS WITH NULL) to a an object which includes the stat or stats attribute
	 */
	public getSingleObject<U>(obj?: TrolyObject, params?: {}, method: string = '', force: boolean = false, node_name?:string): Observable<U> {

		obj = obj || this.make({id:''});
		params = params || {}

		const callKey = `~${obj}~${method}~` + JSON.stringify(params);

		if (this.onInitOrForce(callKey, force)) { // force is definitely required as an option -- integrations uninstalled+reinstalled, need to fetch the latest.
			this.call<any>('get', obj, method, params).pipe(map(_ => node_name != 'x' ? _.body[node_name || this.singular_node] : _.body)).subscribe(this.initObservers[callKey]);
		}
		return this.initObservers[callKey];
	}
}

/**
 * 
 */
export interface ITrolyService<T extends TrolyObject> {

	/**
	 * 
	 */
	record$: BehaviorSubject<T>;

	/**
	 * 
	 */
	records$: BehaviorSubject<T[]>;

	/**
	 * 
	 * @param id 
	 * @param filter 
	 */
	find(id: uuid, filter?: {}, method?:string): Observable<T>;

	/**
	 * 
	 * @param term 
	 * @param page 
	 * @param limit 
	 * @param filter 
	 * @param autoLoad 
	 */
	list(params?: {}, page?: number, limit?: number, autoLoad?: boolean): Observable<TrolySearch<T>>;

	/**
	 * 
	 * @param payload 
	 */
	create(payload: T): Observable<T>;

	/**
	 * 
	 * @param payload 
	 */
	save(payload: T): Observable<T>;

	/**
	 * 
	 * @param payload 
	 */
	remove(id:uuid, params?: {}, method?:string): Observable<T>;

	/**
	 * 
	 * @param payload 
	 */
	make(payload?: {}): T;

	// unlike the other methods in ITrolyService, the two understore methods _find and _list return object and array because
	// they consume (subscribe) the promise in order to broadcast the response data to all subscribers and return it to a single caller., 
	// broadcast through distinct, BehaviorSubject `record` and `records` in TrolyService

	/**
	 * 
	 * @param id 
	 * @param params 
	 */
	_find(id: uuid, params?: {}, method?:string, force?: boolean): Observable<T>

	/**
	 * 
	 * @param obj 
	 * @param preserve_attr 
	 */
	_next(obj: T, flush_attr?: string|string[], force?:boolean): T;
}