import { TrolyObject } from "../troly_object";
import { uuid } from "../utils.models";

export class Job extends TrolyObject {

	declare priority: number;
	declare attempts: number;
 	declare handler: string
	declare last_error: Date;
	declare run_at: Date;
	declare locked_at: Date;
	declare failed_at: Date;
	declare locked_by: uuid;
	declare queue: string;
	declare company_id: uuid;
	declare user_id: uuid;
	declare created_at: Date;
	declare updated_at: Date;

	declare last_status_details: IJobStatusDetail;

	constructor(values?: Object) {
		super('job', values);
	}

}


/**
 * 
 */
export interface IJobStatusDetail {

	type: 'sending' | 'queued' | 'start' | 'update' | 'pulse' | 'end' | 'error' | 'result';

	message: string // "normally an internationalised identifier indicating the type of message -- such as "transactions-processing"
	customised_message?: string; // contains a custom english string to clarify any error encounteered

	// available for type UPDATE only
	last_record_processed?: string;

	// available for types ERROR and PULSE
	completion?: number; // value to reach overall completion of progress
	progress?: number; // current progress

	// available for types ERROR and END
	step?: number;
	fail?: number; // number of records that were not processed.
	ok?: number; // total number of successfully processed records
	total?: number;

	// available for type RESULT only
	//result:Document;
	result:string; // the form status is currently storing documents in 'errorDetails'

	// available for type QUEUED and set internally only
	track_job_id?:uuid
}


/**
export interface IJobStatusDetailError extends Omit <IJobStatusDetail, 'type'> {

	type: 'error';

	step: number;
	fail: number; // number of records that were not processed.
	ok: number; // total number of successfully processed records
	total: number;

}
*/