<div class="card {{cardClass}} {{loadingstatus}}">
   <div class="{{ bsCssClassPrefix }}-header">
   	<h4 class="{{ bsCssClassPrefix }}-title">
			<ng-container>
            <i *ngIf="icon" class="{{icon[0] =='b' ? 'bx':'mdi'}} {{ icon }}  {{ numberOne.variation >=0 ? 'text-success' : 'text-danger' }}"></i>
            <span>{{ heading }}</span>
            <card-badge-widget [loading]="loadingstatus"></card-badge-widget>
			</ng-container>
		</h4>
    </div>
    <div class="{{ bsCssClassPrefix }}-body">
    
        <div class="row">
            <div *ngIf="numberOne" [ngClass]="{ 
                'col-3': numberOne && numberTwo && chartData,
                'col-5': numberOne && !numberTwo && chartData,
                'col-6': numberOne && numberTwo && !chartData,
                'col-12': numberOne && !numberTwo && !chartData,
                'pe-0': numberTwo || chartData
            }">
                <h3>
                    <i *ngIf="numberOne.variation_unit == 'arrow'" class="bx font-size-16 text-primary me-1" 
                            [ngClass]="{
                                'bxs-up-arrow-circle' : numberOne.variation > 0,
                                'bxs-down-arrow-circle' : numberOne.variation < 0
                            }">
                        </i>
                    <span *ngIf="numberOne.unit != 'currency'">{{ numberOne.value | number:'1.0-0' }}<small>{{ numberOne.unit }}</small></span>
                    <span *ngIf="numberOne.unit == 'currency'">{{ numberOne.value | currency: selectedCompany.currency:'symbol-narrow':'1.0-0' }}</span>
                    <ng-container *ngIf="numberOne.variation != 0">
                        <span 
                            *ngIf="numberOne.variation_unit != 'arrow'"
                            class="align-middle badge ms-1 font-size-10"
                            [ngClass]="{
                                'badge-soft-success': (numberOne.variation > 0 && !numberOne.variation_less_is_best) || (numberOne.variation < 0 && numberOne.variation_less_is_best), 
                                'badge-soft-danger': (numberOne.variation < 0 && !numberOne.variation_less_is_best) || (numberOne.variation > 0 && numberOne.variation_less_is_best)
                            }">{{ numberOne.variation > 0 ? '+' : ''}}{{numberOne.variation | number: '1.0-0' }}{{ numberOne.variation_unit }}</span>
                        
                    </ng-container>
                </h3>
                <h6 class="text-secondary text-uppercase font-size-11 mb-0" [innerHTML]="numberOne.legend"></h6>
            </div>
            <div *ngIf="numberTwo" [ngClass]="{ 
                'col-3': numberOne && numberTwo && chartData,
                'col-5': !numberOne && numberTwo && chartData,
                'col-6': numberOne && numberTwo && !chartData,
                'col-12': !numberOne && numberTwo && !chartData,
                'pe-0' : chartData
            }">
                <h3>
                    <i *ngIf="numberTwo.variation_unit == 'arrow'" class="bx font-size-16 text-primary me-1" 
                            [ngClass]="{
                                'bxs-up-arrow-circle' : numberTwo.variation > 0,
                                'bxs-down-arrow-circle' : numberTwo.variation < 0
                            }">
                        </i>
                    <span *ngIf="numberTwo.unit != 'currency'">{{ numberTwo.value | number:'1.0-0' }}<small>{{ numberTwo.unit }}</small></span>
                    <span *ngIf="numberTwo.unit == 'currency'">{{ numberTwo.value | currency: selectedCompany.currency:'symbol-narrow':'1.0-0' }}</span>
                    <ng-container *ngIf="numberTwo.variation != 0">
                        <span 
                            *ngIf="numberTwo.variation_unit != 'arrow'"
                            class="align-middle badge ms-1 font-size-10"
                            [ngClass]="{
                                'badge-soft-success': (numberTwo.variation > 0 && !numberTwo.variation_less_is_best) || (numberTwo.variation < 0 && numberTwo.variation_less_is_best), 
                                'badge-soft-danger': (numberTwo.variation < 0 && !numberTwo.variation_less_is_best) || (numberTwo.variation > 0 && numberTwo.variation_less_is_best)
                            }">{{ numberTwo.variation > 0 ? '+' : ''}}{{numberTwo.variation | number: '1.0-0' }}{{ numberTwo.variation_unit }}</span>
                        
                    </ng-container>
                </h3>
                <h6 class="text-secondary text-uppercase font-size-11 mb-0" [innerHTML]="numberTwo.legend"></h6>
            </div>
            
            <div *ngIf="chartData" [ngClass]="{ 
                'col-6': numberOne && numberTwo,
                'col-7': (numberOne || numberTwo) && !(numberOne && numberTwo),
                'col-12': !numberOne && !numberTwo,
                'ps-0': (numberOne || numberTwo) && !(numberOne && numberTwo)
            }">
                    <apx-chart
                    class="apex-charts"
                    dir="ltr"
                    [chart]="chartOptions.chart"
                    [fill]="chartOptions.fill"
                    [stroke]="chartOptions.stroke"
                    [colors]="chartOptions.colors"
                    [series]="chartData"
                    [xaxis]="chartRange"
                ></apx-chart>
            </div>
        </div>
    </div>
</div>