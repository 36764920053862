import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
   name: 'round'
})
export class RoundPipe implements PipeTransform {
  
	transform(value: any, direction:'up'|'down'='up'): string {
		if (direction == 'up')
    		return Math.ceil(value).toString();
		if (direction == 'down')
			return Math.trunc(value).toString();
  }
}