import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

/**
 * Since the numeric input needs to be set as a 'text' to accept various 
 * localized number formats, we need to set a validation pattern to invalidate 
 * non-numbers inputs
 */
@Component({
    selector: 'app-localized-numeric-input',
    templateUrl: './localized-numeric-input.component.html',
    styleUrls: ['./localized-numeric-input.component.scss'],
})
export class LocalizedNumericInputComponent implements OnInit {
    @Input() form: FormControl;

    @Input() label: string;

    @Input() formControlName: string;

	 /**
	  * Currency prefix
	  */
	 @Input() prefix: string;

	 /**
     * Same as DecimalPipe
     * {minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}
     * 
     * @see https://angular.io/api/common/DecimalPipe#digitsinfo
     */
	 @Input() digitsInfo: string = '1.2-2';

    constructor() {}

    ngOnInit(): void {}
}
