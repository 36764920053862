import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { WindowService } from './window.service';

@Injectable()
export class NetworkInterceptor implements HttpInterceptor {

	protected windowService: WindowService = inject(WindowService);

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		
		// Handle the request and catch errors
		return next.handle(req).pipe(

			catchError(error => {

				// Handle network connection errors specifically
				if (checkNoNetworkConnection(error)) {
								
					// Show a user-friendly message
					this.windowService.openSnackBar('No network connection. Please try again later.', 'error');

					// Create a custom network error
					const error = new HttpNoNetworkConnectionError();

					// Log the error for debugging purposes
					console.warn(error);

					// Mark the error as caught to prevent duplicate handling
					error.wasCaught = true;

					// Re-throw the (modified) network error to allow for potential additional handling
					// in the component or other error handlers further down the line.
					throw error;
				}

				// Re-throw the error to propagate it further
				throw error;
			})

		);
	}
}

/**
 * Custom error class for handling network connection errors (e.g., no internet).
 * Includes a `wasCaught` flag to track whether the error was already handled by the interceptor.
 */
class HttpNoNetworkConnectionError extends Error {
	// Flag to indicate if this error has been handled
	wasCaught = false;
 
	constructor() {
	  super('No network connection');
	}
 }

 /**
 * Helper function to check if an error is likely due to a network connection issue.
 *
 * @param error The error object to check.
 * @returns `true` if it's likely a network error, `false` otherwise.
 */
function checkNoNetworkConnection(error: any): boolean {
	return(
		error instanceof HttpErrorResponse
		&& !error.headers.keys().length
		&& !error.ok
		&& !error.status
		&& !error.error.loaded
		&& !error.error.total
	)
}