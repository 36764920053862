<div class="card {{ loading.record }}" [ngClass]="{ 'is-modal': bsCssClassPrefix == 'modal' }">
	<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown)="submitOnEnter($event)" class="{{ form.cssClass }}">

        <div class="{{ bsCssClassPrefix }}-header">
            <h4 class="{{ bsCssClassPrefix }}-title" *ngIf="!nowEditingRecord">Create a new Task</h4>
            <h4 class="{{ bsCssClassPrefix }}-title" *ngIf="nowEditingRecord">Task Details for 
					<strong>{{ taskable_name || form.get('name').value || 'NO NAME' }}</strong>
				</h4>
            <button type="button" class="btn-close" [disabled]="form.seconds > 0"
                (click)="resolveModal('x')"></button>
        </div>
        <div class="{{ form.loading }} {{ bsCssClassPrefix }}-body">

            <form-status [formGroup]="form" successAction="close" [record_label]="form.get('name').value" (dismissCallback)="resolveModal($event)">

            </form-status>
            <div class="row">
				<ng-container *ngIf="!nowEditingRecord">
					<div class="col-5">
						<mat-form-field>
								<mat-label>{{ "Models.Task.Attributes.taskable_type" | translate }}</mat-label>
								<mat-select formControlName="taskable_type">
									<mat-option value="">{{ "Models.Task.Values.taskable_type.Company" | translate }}
									</mat-option>
									<mat-option value="Customer">{{ "Models.Task.Values.taskable_type.Customer" | translate }}
									</mat-option>
									<mat-option value="Order">{{ "Models.Task.Values.taskable_type.Order" | translate }}
									</mat-option>
									<mat-option value="Product">{{ "Models.Task.Values.taskable_type.Product" | translate }}
									</mat-option>
								</mat-select>
						</mat-form-field>
					</div>

					<div class="col-7" *ngIf="['Order', 'Customer','Product'].includes(form.get('taskable_type').value)">
						<search-dropdown matFieldClass="{{ bsCssClassPrefix == 'modal' ? 'no-live-loading' : '' }}"
							[allowScanner]="true"
							searchLabel="Select a {{ form.get('taskable_type').value }}"
							searchScope="{{ form.get('taskable_type').value[0].toLowerCase() }}"
							[inputRequired]="true" [disabled]="form.disabled || nowEditingRecord"
							[defaultValue]="taskable_name" (selectCallback)="onSearchSelect($event)">
						</search-dropdown>
					</div>
				</ng-container>

                <div class="col-9">
							<mat-form-field>
								<mat-label>Summary</mat-label>
								<input matInput formControlName="name" type="text" />
								<mat-error>Must be provided for a {{ "Models.Task.Values.taskable_type.Company" | translate }}
								</mat-error>
							</mat-form-field>
                </div>

					 <div class="col-3 text-center" *ngIf="!nowEditingRecord || !record.created_by_id || authenticatedUser.id == record.created_by_id">
						<div class="btn-group priority pt-2" role="group">
							<button type="button" ngbTooltip="Low Priority" class="btn btn-outline-light" (click)="togglePriority('low')" [ngClass]="{'active':form.get('priority').value == 'low'}"><i class="text-success font-size-15 bx bxs-downvote"></i></button>
							<button type="button" ngbTooltip="High Priority" class="btn btn-outline-light" (click)="togglePriority('high')" [ngClass]="{'active':form.get('priority').value == 'high'}"><i class="text-danger font-size-15 bx bxs-flag"></i></button>
						 </div>
					 </div>

                <div class="col-12 pb-3">
						<editor [init]="tinyMCEConfig('small')" formControlName="details" tinymce="tinymce"></editor>
                </div>
                <div class="col-md-6 col-12">
						  <mat-form-field>
                        <mat-label>Due Date</mat-label>
                     	<input matInput formControlName="due_date" [matDatepicker]="actionPicker" placeholder="yyyy-mm-dd">
							  	<mat-datepicker-toggle matSuffix [for]="actionPicker"></mat-datepicker-toggle>
							  	<mat-datepicker #actionPicker></mat-datepicker>
                    </mat-form-field>
                </div>
					 <div class="col-md-6 col-12">
						<mat-form-field>
							<mat-label>Assigned to</mat-label>
							<mat-select formControlName="assigned_to_id">
								<mat-option *ngFor="let user of selectedCompany.confirmedUsers" [value]="user.id" class="font-size-12">{{ user.toString() }}</mat-option>
							</mat-select>
						</mat-form-field>
				  	</div>
				
				<ng-container *ngIf="nowEditingRecord">
					<div class="col-12" *ngIf="nowEditingRecord">
						<h6 class="mb-0 mt-1">Task Progress</h6>
						<mat-slider style="width:100%" [max]="100" [min]="0" [step]="25" color="primary"><input matSliderThumb formControlName="progress_value"/></mat-slider>
					</div>
					<div class="col-12" *ngIf="nowEditingRecord">
						<mat-form-field>
							<mat-label>Progress Update</mat-label>
							<input matInput formControlName="latest_progress_notes" type="text"/>
						</mat-form-field>
						<code class="text-dark" *ngIf="this.record.progress_notes">
							<ng-container *ngFor="let entry of this.record.progress_notes">
								<strong class="text-secondary ms-1">{{ moment(entry['created_at']).format(datetimeFull) }}:</strong> {{ entry['notes'] }}<br/>
							</ng-container>
							</code>
					</div>
				</ng-container>
            </div>

				<debug [form]="form"></debug>
        </div>

        <div class="{{ bsCssClassPrefix }}-footer">
            <button type="submit" class="btn btn-primary {{ form.loading }}"
                [disabled]="form.cannotSubmit">
                Save
            </button>
        </div>
    </form>
</div>