
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { filter } from "rxjs";
import { TrolyComponent } from "./troly.component";

@Component({
	template: ''
})
export class TrolyCard extends TrolyComponent {

	override readonly bsCssClassPrefix:string = 'card'

	private previousWindowTitle:string;
	/**
	 * When a card has 'focus' we can set the window title to reflect the card's title. this is not automatically reverted.
	 * see also `TrolyModal.setWindowTitle`.
	 */
	protected setWindowTitle(replacements:{}, translationKey?:string) {
		this.previousWindowTitle ||= this.titleService.getTitle();
		translationKey = `Pages.Titles.${translationKey || this.__name}`;
		this.titleService.setTitle(this.translateService.instant(translationKey, replacements) + ' ― Troly');
	}

	protected revertWindowTitle() {
		this.titleService.setTitle(this.previousWindowTitle);
		this.previousWindowTitle = null;
	}

	/**
	 * 
	 */
	protected _renderMode:string = 'card';
	public get isCard():boolean { return this._renderMode == 'card'; }
	public get isEmbeded():boolean { return this._renderMode == 'embeded'; }
	@Input("renderMode") set renderMode(value:string) { this._renderMode = value; }


	submitOnEnter($event) {
		// when embdeding a form inside an other, we use the the validFormChanged event instead of directly submitting the form.
		if (this.isEmbeded) { return; }
		super.submitOnEnter($event);
	}
	@Output() public onValidFormChanged: EventEmitter<{}> = new EventEmitter<{}>();
	protected attachChangeNotifiers(): void {
		super.attachChangeNotifiers();
		this.form.valueChanges.pipe(filter(_ => this.form.valid)).subscribe(_ => this.onValidFormChanged.emit(_));
	}

}