<div class="{{ cssClass }} card">
	<help pellet="recent" [collection]="helpCollection"></help>
	<div class="{{ bsCssClassPrefix }}-header pb-2">
		<h4 class="{{ bsCssClassPrefix }}-title">{{ header }}</h4>
	</div>
	<ng-scrollbar visibility="hover">
		<div class="{{ bsCssClassPrefix }}-body">

			<div class="row" *ngIf="modelName == 'customer'">

				<div class="col-12 pt-4 mt-4" *ngIf="!recent_records.length">
					<status-hint
						icon="bx-tachometer"
						class="text-secondary"
						size="small"
						description="Customers you have most recently accessed will appear here with membership indicator, so no need to search for a record you're working with."
					>
					</status-hint>
				</div>

				<div class="col-3" *ngFor="let recent of recent_records.slice(0,maxRecords)">
					 <a [routerLink]="recent.url">
						  <div class="avatar-sm mx-auto mb-1">
								<span class="avatar-title rounded-circle" [ngClass]="{
									 'bg-secondary': recent.meta.trade_account_enabled,
									 'bg-pink': !recent.meta.trade_account_enabled && recent.meta.gender == 'female',
									 'bg-success': !recent.meta.trade_account_enabled && recent.meta.gender == 'non-binary',
									 'bg-primary': !recent.meta.trade_account_enabled && recent.meta.gender == 'male',
									 'bg-dark': !recent.meta.trade_account_enabled && !['male','female', 'non-binary'].includes(recent.meta.gender)
								}" avatar [cid]="recent.id">
									 {{ recent.meta.initials }}
								</span>
						  </div>
						  <p class="text-center mb-0 text-truncate" *ngIf="showLabel">
								<i *ngIf="recent.meta.membership" class="bx bxs-star text-warning me-1"></i>{{ recent.label }}
						  </p>
						  <p *ngIf="showAccessTime" class="text-center font-size-10 text-secondary">
								{{ moment(recent.lastAccess).fromNow() }}
						  </p>
					 </a>
				</div>
		  </div>
		  
		  <div class="row" *ngIf="modelName == 'product'">

				<div class="col-12 pt-4 mt-4" *ngIf="!recent_records.length">
					<status-hint
						icon="bx-tachometer"
						class="text-secondary"
						size="small"
						description="Products you have most recently accessed will appear here with inventory indicator, so no need to search for a record you're working with."
					>
					</status-hint>
				</div>

					 <div class="col-3" *ngFor="let recent of recent_records.slice(0,maxRecords)">
						  <a [routerLink]="recent.url">
								<div class="avatar-md mx-auto mb-1">
									 <div class="avatar-title bg-light bg-soft text-center">
										  <img *ngIf="recent.meta.avatar_url" [src]="recent.meta.avatar_url" />
										  <i *ngIf="!recent.meta.avatar_url" class="text-dark font-size-22 {{ getProductIcon( recent.meta.variety )}}"></i>
									 </div>
								</div>
								<p class="text-center mb-0 text-truncate" *ngIf="showLabel">{{ recent.meta.sku || recent.label }}</p>
								<p *ngIf="showAccessTime" class="text-center font-size-10 text-secondary">{{ moment(recent.lastAccess).fromNow() }}</p>
						  </a>
					 </div>
				</div>
		</div>
	</ng-scrollbar>
</div>