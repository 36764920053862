import { Component, EventEmitter, Input, Output, SimpleChanges } from "@angular/core";
import { SweetAlertOptions } from "sweetalert2";
import { TrolyFormGroup } from "../models/troly_form";
import { TrolyObject } from "../models/troly_object";
import { TrolyCard } from "./troly.card";

@Component({
	selector: 'troly-component',
	template: '',
}) // @Component and @Directive include @Injectable
export class TrolyModal<T extends TrolyObject> extends TrolyCard {
	
	override readonly __name: string = 'TrolyModal';

	override readonly bsCssClassPrefix:string = 'modal'

	/**
	 * This is the record we are currently editing if any. The 'record' is a standard naming from the Troly
	 */
	@Input("record") declare record?:T;

	/**
	 * Publishes changes made (validated + saved) to the record.
	 * Generally, we prefer for Modals to publish changes to the services they have updated the data for, however in somecases, it's not possible or not preferrable to do so.
	 */
	@Output() public recordSaved: EventEmitter<T> = new EventEmitter<T>();

	/**
	 * Initialises a form used in the context of a Modal window, where changes are saved in a group (on submit) and not when individual changes are made.
	 * @param attachChangeNotifier 
	 * @param loadingComplete 
	 * @param updateOn 
	 */
	protected initForm(attachChangeNotifier?: boolean, loadingComplete: boolean = true, updateOn?: 'blur' | 'change') {
		super.initForm(attachChangeNotifier, loadingComplete, updateOn || 'change');
	}

	/**
	 * In the context of a model window prior to resolving, we ask for a user confirmation if any changes have been made / detected in the form.
	 * @param from 
	 * @param vars 
	 * @returns 
	 */
	public resolveModal(from?: string, vars?: any): boolean {

		this.acceptDiscardChangesPrompt().then(() => {

			this._acceptingInput = false;

			if (this.form) { this.form.clearClock(); }
			this.observablesDestroy$.next(true);

			if (from == 'logout') {
				this.log(`${this.__name}.resolveModal(${from}) LOGOUT`, 'UI')
				this.authService.logout();

			} else if (from == 'hide') {
				if (this.form) {
					this.form.enable();
					this.form.hideFormStatusMessage = true;
				}
			} else {
				if (this.windowService.currentModal) { // quietly avoiding errors in the case this modal is a normal card, not a full popup    
					if (this.record) {
						this.log(`${this.__name}.resolveModal(${from})`, 'UI')
						this.windowService.currentModal.close(Object.assign(vars || {}, { record: this.record, trigger: from }));
						this.markAsLoading('record');
					} else {
						this.windowService.currentModal.close({ record: null, trigger: from });
					}
				}
			}
		})

		return false; // always returns false, allowing for usage as click: `href (click)="!!resolveModal()"`
	}

	/**
	 * Closes the current modal before redirecting somewhere.
	 * @param url 
	 * @param params 
	 * @returns 
	 */
	public resolveRedirect(url: any, params?: {}): boolean {
		this.resolveModal();
		this.router.navigate(url, params);
		return false;
	}

	/**
	 * 
	 */
	public nowEditingRecord:boolean = false;

	/**
	 * 
	 * @param changes 
	 */
	protected receiveInputParams(changes: SimpleChanges): void {

		if (changes.record && changes.record.currentValue && changes.record.currentValue.id) {
			this.nowEditingRecord = true;
		}
		super.receiveInputParams(changes)
	}

	
	/**
	 * 
	 */
	protected afterViewInit(): void {
		super.afterViewInit();
		
		this.windowService.currentModal.dismissed.subscribe(_ => this._acceptingInput = false);
		// At times modals receive input data (updates) AFTER the modal has started to be dismissed, record updates trigger form updates and this prevents error that follow
	}

	/**
	 * 
	 * @param changes 
	 * @returns 
	 */
	ngOnChanges(changes: SimpleChanges): void {
		if (!this._acceptingInput) { return; }
		this.receiveInputParams(changes)
	}
	
	/**
	 * 
	 */
	private _acceptingInput:boolean = true;

	/**
	 * 
	 */
	protected acceptDiscardChangesPrompt(form?:TrolyFormGroup): Promise<boolean> {
		
		form ||= this.form
		if (!form?.dirty || Object.keys(form.controls).length == 0) {
			return Promise.resolve(true);
		}

		let options: SweetAlertOptions = this.alertService.alertOptions('error', 'discard', 'cancel');
		options.title = 'Changes will be lost!';
		options.html = 'We have detected changes that were not saved. You can discard these changes or click cancel to review and save your modifications.';
		//options.footer = this.crispHelpdeskLink('Learn about automated emails','xfgvbr');

		const promise = this.alertService.confirm(options);
		promise.catch(() => { this.resetButtonPossible=true; });
		return promise;
	}

	protected resetButtonPossible:boolean=false;
	public get showResetButton():boolean { return this.resetButtonPossible && this.form.dirty; }

	/**
	 * 
	 * @param replacements 
	 */
	protected setWindowTitle(replacements:{}) {
		const translationKey = this.nowEditingRecord ? this.__name.replace('Create', 'Edit') : this.__name;
		super.setWindowTitle(replacements, translationKey);
		this.windowService.currentModal.result.finally(() => { this.revertWindowTitle(); })
	}


	/**
	 * 
	 */
	protected override _renderMode:string = 'modal';
	public get isModal():boolean { return this._renderMode == 'modal'; }

}