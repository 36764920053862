import { TrolyObject } from "../troly_object";
import { uuid } from "../utils.models";
import { Club } from "./club.model";
import { Customer } from "./customer.model";
import { Order } from "./order.model";
import { Product } from "./product.model";
import { Task } from "./task.model";
import { User } from "./user.model";
import { Warehouse } from "./warehouse.model";

export class IntegrationLog extends TrolyObject {

	/** Company id to who this log entry belongs to */
	declare company_id: uuid;
	/** Integration who has created this log entry */
	declare integration_id: uuid;
	
	declare provider: string;
	declare processor: string;

	declare communicatable_id: uuid;
	declare communicatable_type: string;

	declare severity: string;
	declare message: string;
	declare operation: string;

	declare interface: string;

	declare data: any;
	declare user_id: uuid;

	declare loggable_id: uuid;
	declare loggable_type: string;
	declare loggable: Customer|Product|Order|Warehouse|Task|Club;

	declare provider_data: {
		users: User[]
	}

	constructor(values?: Object) {
		super('integration_log', values);

		if (values['loggable']) {
			if (values['loggable_type'] == 'Warehouse') { this.loggable = new Warehouse(this.loggable['warehouse']) }
			if (values['loggable_type'] == 'Order') { this.loggable = new Order(this.loggable['order']) }
			if (values['loggable_type'] == 'Club') { this.loggable = new Club(this.loggable['club']) }
			if (values['loggable_type'] == 'Customer') { this.loggable = new Customer(this.loggable['customer']) }
			if (values['loggable_type'] == 'Task') { this.loggable = new Task(this.loggable['task']) }
			if (values['loggable_type'] == 'Product') { this.loggable = new Product(this.loggable['product']) }
		}

	}

	public get usersInvolved(): User[] { return (this.provider_data?.users || []).map(_ => new User(_)) }
	public user(): User | null {
		if (!this.user_id) { return null }
		return super._user(this.user_id, this.usersInvolved)
	}
}
