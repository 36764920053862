import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'sortBy'
})
export class SortByPipe<T> implements PipeTransform {

	transform(value: T[], ...args: any[]): T[] {
		
		if (!value || value.length == 0) { return value; }		

		return value.sort((n1,n2) =>  {

			if (n1.toString() == n2.toString()) {
				return 0;
			}
			return n1.toString() < n2.toString() ? -1 : 1;
		});
	}

}
