import { ChangeDetectionStrategy, Component, ViewChildren, inject } from "@angular/core";
import { BehaviorSubject, filter, takeUntil } from "rxjs";
import { SortEvent, SortableDirective } from "../../shared/ui/sortable.directive";
import { ITrolyLoadingStatus, ITrolySocketNotification, TrolySearch, TrolySearchMeta } from "../models/form_objects";
import { TrolyObject } from '../models/troly_object';
import { uuid } from "../models/utils.models";
import { TrolyService } from "../services/troly/troly.service";
import { WebsocketService } from "../services/troly/websocket.service";
import { compareArrays, compareHashMap } from "./base.component";
import { TrolyCard } from "./troly.card";

@Component({
	template: '',
	styleUrls: ['./list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush

})
export abstract class TrolyListComponent<T extends TrolyObject> extends TrolyCard {

	override readonly __name:string = 'TrolyListComponent'

	/**
	 *
	 */
	public readonly __qParamPrefix:string = '';

	/**
	 * The list of loaded records. This can be a paginated list or a full list (depending on autoLoadAllPages settings.
	 */
	declare records: T[];

	/**
	 *
	 */
	declare meta: TrolySearchMeta;

	/**
	 * This is a list of records loaded with any Frontend-filtering applied.
	 * The component MUST subscribe to this._notifier$ to receive the records to filter on.
	 */
	declare filteredRecords: T[]; // just a convenience definition -- we know most lists will need to be filtered, usually via setQuery()

	/**
	 * Attribute to match when updating a record in a list. Defaults to 'id'. (integrations use 'provider')
	 */
	protected compareAttr:string='id';

	/**
	 * Defines whether new records received are added at the begining (start) of the list, or the end. Defaults to 'start'.
	 */
	protected insertAt:'end'|'start'='start'

	protected readonly DEFAULT_SEARCH_ATTRIBUTES = { currentPage:1, pageSize:10, advSearch:false }

	/**
	 * define the search attributes to reset when the resetList() function is called, and the default values to set.
	 */
	protected attributesToResetSearch = this.DEFAULT_SEARCH_ATTRIBUTES

	/**
	 * The current page number for the resultset. Use gotoPage() to change.
	 */
	public currentPage: number = this.DEFAULT_SEARCH_ATTRIBUTES.currentPage;

	/**
	 * The desired pagination size required from the server. Use setPageSize() or changePageSize() to maintain internal integrity.
	 */
	private _pageSize = this.DEFAULT_SEARCH_ATTRIBUTES.pageSize;
	public set pageSize(value: number) {
		
		this._pageSize = value;
		
		this.attributesToResetSearch.pageSize = this.pageSize;
		
		this.totalPages = Math.ceil(this.totalRecords / this.pageSize);

		if (this.currentPage > this.totalPages) { this.lastPage(); }

	}
	public get pageSize(): number { return this._pageSize; }

	/**
	 * The current range of record being rendered.
	 */
	public recordRange: string = '?';

	/**
	 * The current total number of pages retrieved by the latest search, and based on record counts and page size.
	 */
	public totalPages: number = 0; // 0 indicates we do not know.

	/**
	 * The current total number of records being displayed by the latest search.
	 */
	public totalRecords: number = 0;

	/**
	 * The latest search terms used. This is persisted across pagination.
	 */
	protected search_terms: string = '';

	/**
	 *
	 */
	protected dataColumns: { [key:string]:boolean } = {};

	/**
	 * Search parameters persisted across and (optionally) changed between searches, but maintain across pagination.
	 * As opposed to api_record_restrictions, which are enforced with ALL searches, search_params are user-selected filters.
	 */
	protected api_filters_applied$: BehaviorSubject<{}> = new BehaviorSubject(null);

	/**
	 * Filter params are treated like search params but are applied locally without triggering the api to be pinged
	 */
	protected ui_filters_applied$: BehaviorSubject<{}> = new BehaviorSubject(null);

	/**
	 *
	 */
	public all_filters_applied$: BehaviorSubject<{}[]> = new BehaviorSubject(null);

	/**
	 * List filters automatically applied, routed, and saved in user localCache to be re-applied on return, if calling queryParams without
	 * setting this first, it's assumed params will be auto-searched
	 */
	protected routed_api_filters:string[] = null;
	protected routed_ui_filters:string[] = null;

	public searchFilterApplied: boolean = false;

	/**
	 * Set of restrictions forced to every search, and intended to limit which records are
	 * retrieved in the component. Readonly needs to be initialised in constructor.
	 */
	public api_record_restrictions: {} = {};

	public advSearch:boolean=false; // whether or not the advanced search is shown

	/**
	 *
	 */
	@ViewChildren(SortableDirective) sortableColumns: SortableDirective[];

	afterViewInit(): void {
		super.afterViewInit();
		const currentSort = this.api_filters_applied$.value?.['order'] || this.api_record_restrictions['order'];
		if (currentSort) { this.sortableColumns.forEach((header) => { header.setSort(currentSort, this.api_record_restrictions['order']) }); };
   }


	/**
	 *
	 */
	override loading: {
		record: ITrolyLoadingStatus,  // defined in by TrolyComponent -- stores the status of the record being loaded for the component
		records: ITrolyLoadingStatus, // the list of records retrieved -- note this can be 'refreshed' when loaded from cache
		troly: ITrolyLoadingStatus }={ record:undefined, records:undefined, troly:undefined }/* holds the loading class indicators, slow internet is indicated by loading-slow by */

	/**
	 * Additional services used this this component.
	 * ? Keeping in mind CompanyService and UserService are already available in TrolyComponent.
	 */
	protected ws: WebsocketService = inject(WebsocketService);

	constructor() {
		super();
		this.attachRecordsSocket();
	}

	protected resetOnNavigate(oldId: uuid, newId: uuid): void {
		if (super._resetOnNavigate(oldId, newId)) {
			this.records = null;
			this.filteredRecords = null;
		}
	}

	public resetList(reload:boolean=true) {

		Object.keys(this.attributesToResetSearch).forEach(_attr => {
			this[_attr] = this.attributesToResetSearch[_attr];
		}, this);


		if (this.form) { this.form.reset(); }

		if (reload) { this.loadRecords(); }

		this.pageSize = super.service.storeUiKey(`${this.__name}.pageSize`, this.attributesToResetSearch.pageSize);
		this.currentPage = super.service.storeUiKey(`${this.__name}.currentPage`, this.attributesToResetSearch.currentPage);
		
		this.toggleAdvSearch(false);
		this.toggleSelectRecords(false);
	}

	public canReset():boolean {
		return this.searchFilterApplied || 
				(this.attributesToResetSearch.currentPage != this.currentPage && !this._autoLoadAllPages) || 
				this.attributesToResetSearch.pageSize != this.pageSize ||
				Object.keys(this.attributesToResetSearch).length > 3 ||
				this.selectedRecords.length > 0 ||
				this.selectingRecords;
	}

	public toggleAdvSearch(value?:boolean) {
		if (value != undefined) { this.advSearch = value; }
		else { this.advSearch = !this.advSearch; }
		this.service.storeUiKey(`${this.__name}.advSearch`, this.advSearch);
	}

	/**
	 * The records notifier to attach in order to retrieve records and apply any filtering. it generally defaults to this.service.records, but can be overriden via `protected LoadData`
	 */
	public get listNotifier(): BehaviorSubject<T[]> { return this._notifier$; }
	private _notifier$: BehaviorSubject<T[]>;
	protected set listNotifier(value:BehaviorSubject<T[]>) {
		if (this._notifier$) { throw new Error(`listNotifier cannot be set more than once ${this.__name}`); }
		 
		this._notifier$ = value;
		this._notifier$.pipe(filter(_ => !!_), takeUntil(this.observablesDestroy$)).subscribe({
			next: (_records) => {
				this.records = _records;
				if (this._autoLoadAllPages && this.records.length < this.totalRecords) {
					// the first when auto-loading the first page is either coming from the db
					// or the service has found records in the cache. In either cases, we can consider the records as loaded, and the UI updates the loading status to 'refreshing'
					this.markAs('records','refresh');
				}
				this.filterRecords();
			}
		})
	}

	protected get service(): TrolyService<T> { return super.service; }
	protected set service(value: TrolyService<T>) {  super.service = value; this.applyListSettings(); }

	protected applyListSettings() {

		this.pageSize = super.service.cachedUiKey(`${this.__name}.pageSize`, this.pageSize);
		this.currentPage = super.service.cachedUiKey(`${this.__name}.currentPage`, this.currentPage);
		
		if (this.route.snapshot.queryParams[`${this.__qParamPrefix}adv`] != undefined) {
			this.toggleAdvSearch(this.route.snapshot.queryParams[`${this.__qParamPrefix}adv`] == 'true');	
		} else {
			this.toggleAdvSearch(super.service.cachedUiKey(`${this.__name}.advSearch`, this.advSearch));
		}

	}

	public attachRecordsSocket() {
		this.ws.connectCompanyChannel(this.selectedCompany.id)
		.subscribe((_:ITrolySocketNotification) => {
			this.addFromSocket(_);
		});
	}

	/**
	 * Ensure that any search_params are applied and url is set to matched (if specified in routed_api_filters)
	 * @param value
	 * @param supported_auto_filters
	 * @returns
	 */
	public setQuery(paramsToApply: {}): void {

		if (Object.values(paramsToApply).filter(_ => !!_).length == 0) {

			//
			// we're about to clear this filter, remove from the list
			//
			if (this.all_filters_applied$.value != null) {
				const updatedFilters = this.all_filters_applied$.value.filter(_ => !compareHashMap(_, paramsToApply, true))
				this.all_filters_applied$.next(updatedFilters);
			}

		} else {
			//
			// Remove any other query which directly conflicts (uses the same parameter) with this new one
			//
			if (this.all_filters_applied$.value != null) {
				this.all_filters_applied$.value.map(appliedAlready => {
					if (Object.keys(paramsToApply).filter(k => paramsToApply[k] && appliedAlready[k] && paramsToApply[k] != appliedAlready[k]).length > 0) {
						// we have found an incompatible parameter (trying to set to another value, when an existing value is there already)
						// we need to unset all filters using this parameter/value pair;
						this.resetQuery(appliedAlready);
					}
				})
			}

			//
			// Record the query being applied for future checks and merge/unset operations
			//
			if (!this.queryApplied(paramsToApply)) {
				// when applying a query, IF we receive multiple values for an attribute, we MUST have been indicated the query needs to match ALL values (using suffix [])
				//paramsToApply = Object.keys(paramsToApply).filter(_ => !Array.isArray(paramsToApply[_]) || _.endsWith('[]') ).reduce((acc, curr) => { acc[curr] = paramsToApply[curr]; return acc; }, {});
				//if (Object.keys(paramsToApply).length > 0) {
					this.all_filters_applied$.next((this.all_filters_applied$.value || []).concat(paramsToApply));
				//}
			}
		}

		let updatedParams;

		if (this.routed_api_filters.length > 0) { // arch: this is used to validate parameters, HOWEVER we should support 'non-routed' parameters to be validated.

			updatedParams = Object.assign({}, this.api_filters_applied$.value);
			this.routed_api_filters.forEach(k => {

				if (paramsToApply[k] === undefined) { 	// this is when applying a filter exclusively or not
					//if (!applyMultiple) { delete updatedParams[k]; }

				} else if (paramsToApply[k] === null) { // this is how the UI is telling us, REMOVE THIS FILTER
					delete updatedParams[k];
				} else {

					//if (Array.isArray(paramsToApply[k]) || Array.isArray(updatedParams[k])) {
					// 	updatedParams[k] = paramsToApply[k];
					// } else {
						updatedParams[k] = paramsToApply[k];
					//}
				}
			});

			if (!compareHashMap(updatedParams, this.api_filters_applied$.value || {})) {
				this.api_filters_applied$.next(updatedParams);
				this.service.storeUiKey(`${this.__name}.api_filters_applied$`, updatedParams);
			}
		}

		if (this.routed_ui_filters.length > 0) {

			updatedParams = Object.assign({}, this.ui_filters_applied$.value);
			this.routed_ui_filters.forEach(k => {

				if (paramsToApply[k] === undefined) { 	// this is when applying a filter exclusively or not
					//if (!applyMultiple) { delete updatedParams[k]; }

				} else if (paramsToApply[k] === null) { // this is how the UI is telling us, REMOVE THIS FILTER
					delete updatedParams[k];

				} else {

					// if (Array.isArray(paramsToApply[k]) || Array.isArray(updatedParams[k])) {
					// 	updatedParams[k] = paramsToApply[k];
					// } else {
						updatedParams[k] = paramsToApply[k];
					// }
				}
			});

			if (!compareHashMap(updatedParams, this.ui_filters_applied$.value || {})) {
				this.ui_filters_applied$.next(updatedParams);
				this.service.storeUiKey(`${this.__name}.ui_filters_applied$`, updatedParams);
			}

		}
	}

	/**
	 * Removes specific parameters
	 * @param paramsToReset
	 */
	public resetQuery(paramsToReset: {}|string[]=null, resetForm:boolean=false): void {

		if (!paramsToReset) {
			//
			// RESETTING ALL FILTERS
			//
			(this.all_filters_applied$.value || []).map(_ => this.resetQuery(_))

			if (resetForm && this.form) { this.form.reset() }

			const routingDeletions = Object.keys(this.route.snapshot.queryParams).filter(k => k.startsWith(this.__qParamPrefix)).reduce((acc, cur) => { acc[cur] = null; return acc; }, {});
			this.setRoutingParams(routingDeletions);

			this.service.deleteUiKey(`${this.__name}.api_filters_applied$`);
			this.service.deleteUiKey(`${this.__name}.ui_filters_applied$`);

		} else if (Array.isArray(paramsToReset)) {

			//
			// RESETTING FILTERS CONTAINING ANY OF THE ATTRIBUTES IN THE ARRAY
			//
			paramsToReset.forEach(_ => {
				this.all_filters_applied$.value.filter(f => Object.keys(f).includes(_)).forEach(f => this.resetQuery(f))
			});

		} else {

			//
			// RESETTING A SPECIFIC FILTER
			//
			const clearInstruction = Object.keys(paramsToReset).reduce((acc, curr) => { acc[curr]=null; return acc; }, {});
			this.setQuery(clearInstruction)

		}

	}
	/**
	 *
	 * @param attributes
	 * @param resetForm
	 */
	public resetAllQueryWithAttribute(attributes:string[], resetForm:boolean=false): void {
		
		this.all_filters_applied$.value.map(appliedFilter => {
			if (Object.keys(appliedFilter).find(k => attributes.includes(k)) != null) {
				this.resetQuery(appliedFilter);
			}
		})

		if (resetForm && this.form) { this.form.reset() }

	}

	/**
	 * Toggles the query parameters based on the provided parameters.
	 * If the query parameters are already applied, it resets them.
	 * Otherwise, it sets the query parameters.
	 * 
	 * @param paramsToToggle - The parameters to toggle.
	 */
	public toggleQuery(paramsToToggle: {}): void {
		if (this.queryApplied(paramsToToggle)) {
			this.resetQuery(paramsToToggle);
		} else {
			this.setQuery(paramsToToggle)
		}
	}

	/**
	 * Searches for an applied filter in the list filters currently activated for this list.
	 *
	 * @param paramsToCheck - The parameters to check for filters. It can be an object or an array of strings. When using an array of string EACH attributes must be found
	 * @param mode - The target attributes to filter on. It is an optional parameter and defaults to an empty array.
	 * @returns The matching filter object if found, otherwise null.
	 */
	protected queryApplied(paramsToCheck: {}|string[]|string, mode:'strict'|'any'|'all'='strict', include_restrictions:boolean=false): {}[] | {} | null {

		const appliedFilters = (this.all_filters_applied$.value || []).concat(include_restrictions ? this.api_record_restrictions : [])

		let keysOnly = false;
		if (typeof paramsToCheck == 'string') { keysOnly=true; paramsToCheck = {[paramsToCheck]:true}; }
		if (Array.isArray(paramsToCheck)) { keysOnly=true; paramsToCheck = paramsToCheck.map(_ => _).reduce((acc, curr) => { acc[curr] = true; return acc; }, {}); }

		const attributesToMatch = Object.keys(paramsToCheck);

		if (mode == 'strict') {

			return appliedFilters.find(_ => compareHashMap(_, paramsToCheck, keysOnly));

		} else if (mode == 'any') {

			return appliedFilters.find(appliedFilter => Object.keys(appliedFilter).filter(k => appliedFilter[k] != undefined && attributesToMatch.includes(k)).length > 0)

		} else if (mode == 'all') {

			return appliedFilters.filter(appliedFilter => Object.keys(appliedFilter).filter(k => appliedFilter[k] != undefined && attributesToMatch.includes(k)).length > 0)
		}
	}

	public queriesApplied(paramsToCheck: {}|string[]|string): {}[] {
		return (this.queryApplied(paramsToCheck, 'all') as []);
	}
	public exactQueryApplied(paramsToCheck: {}|string[]|string): {} | null {
		return this.queryApplied(paramsToCheck, 'strict');

	}
	public anyQueryApplied(paramsToCheck: {}|string[]|string): {} | null {
		return this.queryApplied(paramsToCheck, 'any');

	}


	/**
	 *
	 */
	/**
	 * Checks if the provided parameters are correctly applied in the routing query.
	 * 
	 * @param paramsToCheck - The parameters to check against the routing query.
	 * @returns A boolean indicating whether the parameters are correctly applied in the routing query.
	 */
	public queryAppliedFromRouting(paramsToCheck: {}, toggleValues?:{}): boolean {
		
		if (this.queryApplied(paramsToCheck, toggleValues == undefined ? 'strict' : 'any')) { return true; }

		const paramsCorrectlyRouted = Object.keys(paramsToCheck).reduce((acc, curr) => {
			const p = this.route.snapshot.queryParams[`${this.__qParamPrefix}${curr}`];
			if (p == paramsToCheck[curr] || 												// this is an exact match as to what we're looking for and what is in the queryParams
				(Array.isArray(p) && compareArrays(paramsToCheck[curr], p)) ||	// dealing with an array of values to compare with what we're looking for
				(toggleValues != undefined && toggleValues[curr].includes(p)) 									// when a filter set can carry different values, check if the key is be present
			) {
				acc[curr] = p; 
			}

			return acc;
		}, {})

		if (compareHashMap(paramsToCheck, paramsCorrectlyRouted, toggleValues != undefined)) {
			this.setQuery(paramsCorrectlyRouted);
			return true;
		}

		return false;
	}

	addFromSocket(event:ITrolySocketNotification): 'added'|'updated'|'deleted'|null {

		let modelName = (this.service.make() as TrolyObject)._trolyModelName;

		if (event.model == modelName) {

			if (event.operation == 'new') {
				if (this.currentPage == 1) {
					this.pushOrUpdateRecord(this.records, this.service.make(event.data));
					this._notifier$.next(this.records); // required to ensured cached records are updated see `listCacheKey`
					this.socketUpdateNotifier$.next(event);
					return 'added';
				}
			} else {
				const index = this.records?.findIndex((_:T) => _[this.compareAttr] == event.data[this.compareAttr])
				if (index >= 0) {
					if (event.operation == 'update') {
						this.records[index] = this.service.make({...this.records[index], ...event.data});
						this._notifier$.next(this.records); // required to ensured cached records are updated see `listCacheKey`
						this.socketUpdateNotifier$.next(event);
						return 'updated';
					}
					if (event.operation == 'deleted') {
						this.records[index] = this.service.make({...this.records[index], ...event.data});
						this._notifier$.next(this.records); // required to ensured cached records are updated see `listCacheKey`
						this.socketUpdateNotifier$.next(event);
						return 'deleted';
					}
				}
			}
		}
	}

	public socketUpdateNotifier$: BehaviorSubject<ITrolySocketNotification> = new BehaviorSubject<ITrolySocketNotification>(null);

	/**
	 *
	 * @param records Dynamically inserts a record in a list of records
	 * @param record
	 * @returns the index where this record was inserted, if it was inserted
	 */
	protected pushOrUpdateRecord<T extends TrolyObject>(collection: T[], element: T | T[], attr: string = 'id', insertAt:'end'|'start'='end'): T[] {
		const sizeBefore = collection.length
		collection = super.pushOrUpdateRecord(collection, element, this.compareAttr, this.insertAt)

		if (sizeBefore < collection.length) {
			this.totalRecords += 1;
			this.setRecordRange(); // if this is the first record, range was set to 0 from initialization
		}
		if (collection.length > this.pageSize) {
			collection.pop();
		}

		return collection;
	}

	protected _autoLoadAllPages: boolean = false;
	/**
	 * Defines the caching key to use in order to prevent records being reloaded with every page load.
	 * NOTE: the cache key NAME used here is important as some name patterns will be see the associated data flushed
	 * upon certain events -- see admin.layout.ts around L173 ( or search `flushLocalStorage`)
	 * // causes records to be stored to localStorage, and refresh on retrieval -- needs a matching .service for the objects to be correctly recreated
	 * @param key
	 */
	protected enableListCache(key: string, notifier$?: BehaviorSubject<T[]>) {

		this._autoLoadAllPages = (key && key != '');

		if (!this._notifier$) {
			// when setting the caching key -- we want to ensure that the records loaded from cache are assigned immediately -- to override the notifier, set it before.
			this.listNotifier = notifier$ || this.service.records$
		}

		if (this._autoLoadAllPages) {
			this.service.recordsCacheKey = key;
		}
	}

	/**
	 *
	 * @param separator
	 * @returns
	 */
	public setRecordRange() {

		if (this.totalRecords <= 0) { return this.recordRange = "0"; }
		let separator = " - ";

		if (this.currentPage == 0) { this.currentPage = 1; }

		let from = this.currentPage <= 1 ? 1 : ((this.currentPage - 1) * this.pageSize) + 1;
		let to = this.pageSize * this.currentPage;

		if (to > this.totalRecords) { to = this.totalRecords }

		return this.recordRange = `${from}${separator}${to}`;
	}

	/**
	 *
	 * @param value
	 */
	protected setTotalRecords(value: number) {
		this.totalRecords = value;
		this.totalPages = Math.ceil(this.totalRecords / this.pageSize);

		if (this.currentPage > this.totalPages) { this.lastPage(); }
	}

	/**
	 *
	 * @param event
	 */
	public changePageSize(event): boolean {

		if (this.pageSize != event.target.value) {
			this.pageSize = event.target.value;
			this.loadRecords();
			this.service.storeUiKey(`${this.__name}.pageSize`, this.pageSize);

			return true;
		}
		return false;
	}

	/**
	 *
	 */
	public nextPage(): boolean {
		if (this.isLoaded('records')) { // on init
			if (this.currentPage < this.totalPages) {
				this.currentPage = this.currentPage + 1;
				this.service.storeUiKey(`${this.__name}.currentPage`, this.currentPage);
				this.log(`nextPage loading page ${this.currentPage} of ${this.totalPages}`, 'DEEP');
				this.markAsLoading('records');
				this.loadRecords();
				return true;
			} else {
				this.log(`nextPage failed, already at page ${this.currentPage} of ${this.totalPages}`, 'DEEP');
			}
		}
		return false;
	}

	/**
	 *
	 * @param page
	 */
	public gotoPage(page: any) {
		if (this.isLoaded('records')) { // on init
			if (page <= this.totalPages) {
				//if (page != this.currentPage) {
				this.currentPage = page;
				this.service.storeUiKey(`${this.__name}.currentPage`, this.currentPage);
				this.markAsLoading('records');
				this.loadRecords();
				//}
			} else {
				this.log(`gotoPage failed, page ${page} should be between 1 and ${this.totalPages} inclusively`);
			}
		} else {
			this.err(`advancing from ${this.currentPage} to ${page} (total: ${this.totalPages})`);
		}
	}

	public onSort( sortEvent? : SortEvent) {
		if (!sortEvent || sortEvent.column == '' || sortEvent.direction == '') {
			this.resetQuery({order:null});
		} else if (sortEvent?.column && sortEvent?.direction) {
			this.setQuery({order:`${sortEvent.column},${sortEvent.direction}`});
		}

		if (this.loading.records == 'loaded') { this.loadRecords(); }
	}

	/**
	 *
	 */
	public lastPage() {
		if (this.isLoaded('records') && this.currentPage < this.totalPages) { // on init
			this.currentPage = this.totalPages;
			this.markAsLoading('records');
			this.loadRecords();
		}
	}
	public isLastPage() {
		return this.isLoaded('records') && this.currentPage == this.totalPages;
	}
	/**
	 *
	 */
	public prevPage() {
		if (this.isLoaded('records')) { // on init
			if (this.currentPage > 1) {
				this.currentPage = this.currentPage - 1;
				this.markAsLoading('records');
				this.loadRecords();
			} else {
				this.log(`prevPage failed, already at page ${this.totalPages}`);
			}
		}
	}

	/**
	 *
	 */
	public firstPage() {
		if (this.isLoaded('records') && this.currentPage > 1) { // on init
			this.currentPage = 1;
			this.markAsLoading('records');
			this.loadRecords();
		}
	}

	/**
	 *
	 * @param terms
	 * @param params
	 */
	public async loadRecords(): Promise<void> {// Observable<T[]> {

		this.log(`${this.__name}.loadRecords via TrolyListComponent.loadRecords [auto:${this._autoLoadAllPages}]`, 'STACK');

		if (this.loading.records == 'loaded') { this.markAs('records','refresh'); } else { this.markAsLoading('records'); }

		// commenting out function parameters -- never used.
		//if (terms && terms != this.search_terms) { this.search_terms = terms; this.currentPage = 1; }
		//if (params && params != this.api_filters_applied$) { this.api_filters_applied$ = params; this.currentPage = 1; }
		let params = { ...this.api_filters_applied$.value, ...this.api_record_restrictions }
		if (this.api_filters_applied$.value?.['order']) { params['order'] = this.api_filters_applied$.value['order'] } // only the order param can override the api_record_restrictions configured

		params['query'] = this.search_terms

		this.service.list(params, this.currentPage, this.pageSize).pipe(filter(_ => !!_))
		.subscribe({
			next: (_:TrolySearch<T>) => {

				this.log(`${this.__name} via TrolyListComponent.loadRecords: retrieved ${_.results.length} of ${_.meta.count} records (p:${this.currentPage})`, 'STACK');

				if (this._autoLoadAllPages) {

					const startList = this.currentPage == 1 ? [] : this._notifier$.value;

					// then add any new objects to the list
					this._notifier$.next(startList.concat(_.results));
				} else {
					this._notifier$.next(_.results as T[]);
				}

				if (this.totalRecords != _.meta.count) {
					this.setTotalRecords(_.meta.count);
				}

				this.meta = _.meta;
				this.setRecordRange();

				if (this._autoLoadAllPages && this.currentPage < this.totalPages) {
					this.currentPage = this.currentPage + 1;
					this.loadRecords();
				} else if (!this._autoLoadAllPages || this._notifier$.value.length == _.meta.count) {
					this.markAsLoaded('records');
					this.filterRecords();
				}

			},
			error: (err) => {
				this.resetQuery();
				this.markAsLoadingError('records', err);
			},
			complete: () => {
				if (params['order'] && this.sortableColumns) {
					this.sortableColumns.forEach((header) => { header.setSort(params['order'], this.api_record_restrictions['order']) });
				}
			}
		});
	}

	public filterRecords(): void {

	}

	/// subscribe to data notifiers and update forms
	protected loadData(): void {

		super.loadData();

		if (!this._notifier$) {
			// arch: it's possible that it would be better to set a fresh notifier for all lists. given the same service can load different data for diffrent components...?
			// see CustomerInteractionsCard.constructor
			this.listNotifier = this.service.records$
		}

		this.log(`${this.__name} via TrolyListComponent.loadData: loading`, 'STACK');
		this.loadRecords();
	}


	/**
	 * Allows to listen to changes in routing and automatically call setQuery when a parameter that is watched is set/changed
	 * @param watch
	 */
	private queryParamsConfigured: [];
	set queryParamsListener(watchedParams: string[]) {

		if (this.queryParamsConfigured) { console.error(`queryParamsConfigured cannot be configured twice for ${this.__name}.`); }

		// if auto_*_params were not set, we assume all qs params go back to the API
		if (!this.routed_ui_filters) { this.routed_ui_filters = []; }
		if (!this.routed_api_filters) { this.routed_api_filters = watchedParams.filter(p => !this.routed_ui_filters.includes(p)); }

		// we always want to watch for sorting changes
		if (!this.routed_api_filters.includes('order')) { this.routed_api_filters.push('order'); }

		const preRoutedFilters = Object.keys(this.route.snapshot.queryParams)
			// when looking for querystring-based filters we need to remove the applied prefix, and optional strict array indicator
			.filter(k => watchedParams.includes(k.replace(this.__qParamPrefix,'').replace(/\[\]$/,'')))
			// when applying the filters, we remove the prefix (it's automatically added back in setQuery)
			.reduce((acc, cur) => { acc[cur.replace(this.__qParamPrefix, '')] = this.route.snapshot.queryParams[cur]; return acc; }, {});

		this.setQuery(preRoutedFilters);

		this.api_filters_applied$.pipe(filter(_ => !!_),takeUntil(this.observablesDestroy$)).subscribe(params => {

			const prefixedParams = Object.keys(params).reduce((acc, cur) => { acc[this.__qParamPrefix + cur] = params[cur]; return acc; }, {})
			const routingDeletions = Object.keys(this.route.snapshot.queryParams).filter(k => k.startsWith(this.__qParamPrefix)).filter(k => !prefixedParams[k]).reduce((acc, cur) => { acc[cur] = null; return acc; }, {});
			this.setRoutingParams(Object.assign(prefixedParams, routingDeletions));

			this.loadRecords();

			this.searchFilterApplied = Object.keys({...params, ...(this.ui_filters_applied$.value || {})}).length > 0

		});

		if (this.route.snapshot.fragment == 'reset') {
			// using the #reset allows to refrain from loading any stored setting or routed setting
			this.service.storeUiKey<{}>(`${this.__name}.api_filters_applied$`, {});
			this.service.storeUiKey<{}>(`${this.__name}.filter_params`, {});
			this.setRoutingParams({});
		}

		this.api_filters_applied$.next(this.service.cachedUiKey<{}>(`${this.__name}.api_filters_applied$`));

		this.ui_filters_applied$.pipe(filter(_ => !!_),takeUntil(this.observablesDestroy$)).subscribe(params => {

			const prefixedParams = Object.keys(params).reduce((acc, cur) => { acc[this.__qParamPrefix + cur] = params[cur]; return acc; }, {})
			const routingDeletions = Object.keys(this.route.snapshot.queryParams).filter(k => k.startsWith(this.__qParamPrefix)).filter(k => !prefixedParams[k]).reduce((acc, cur) => { acc[cur] = null; return acc; }, {});
			this.setRoutingParams(Object.assign(prefixedParams, routingDeletions));

			this.filterRecords();

			this.searchFilterApplied = Object.keys({...params, ...(this.api_filters_applied$.value || {})}).length > 0
		});

		this.ui_filters_applied$.next(this.service.cachedUiKey<{}>(`${this.__name}.ui_filters_applied$`));

	}


	//  .d8888b.           888                   888    d8b                        888    888                        888 888 d8b
	// d88P  Y88b          888                   888    Y8P                        888    888                        888 888 Y8P
	// Y88b.               888                   888                               888    888                        888 888
	//  "Y888b.    .d88b.  888  .d88b.   .d8888b 888888 888  .d88b.  88888b.       8888888888  8888b.  88888b.   .d88888 888 888 88888b.   .d88b.
	//     "Y88b. d8P  Y8b 888 d8P  Y8b d88P"    888    888 d88""88b 888 "88b      888    888     "88b 888 "88b d88" 888 888 888 888 "88b d88P"88b
	//       "888 88888888 888 88888888 888      888    888 888  888 888  888      888    888 .d888888 888  888 888  888 888 888 888  888 888  888
	// Y88b  d88P Y8b.     888 Y8b.     Y88b.    Y88b.  888 Y88..88P 888  888      888    888 888  888 888  888 Y88b 888 888 888 888  888 Y88b 888
	//  "Y8888P"   "Y8888  888  "Y8888   "Y8888P  "Y888 888  "Y88P"  888  888      888    888 "Y888888 888  888  "Y88888 888 888 888  888  "Y88888
	//                                                                                                                                         888
	//                                                                                                                                    Y8b d88P
	//                                                                                                                                     "Y88P"
	/**
	 * This section contains the functions and logic to allow selecting items in the list of items retrieved
	 * functions to handle the selection and unselection of records
	 */

	/** the list of currently selected ercords */
	public selectedRecords: uuid[] = [];
	/** Select a specific record . if already selected, does nothing */
	public selectRecord(r:TrolyObject, event?, max:number=-1) {

		if (!this.selectingRecords) { throw new Error(`Record selection is not enabled for ${this.__name}`); }

		if (max > 0 && this.selectedRecords.length >= max) { this.selectedRecords=[] }

		if (event?.shiftKey) {
			if (this.selectedRecords.length == 0) {
				// nothing checked before, we just start the selection
				this.selectRecord(r);
			} else if (this.selectedRecords.includes(r.id)) {

				// this box was checked before in the selection, so we uncheck it and all the ones after it -- doesn't handle partial selection, only ranges
				const index = this.selectedRecords.indexOf(r.id);
				this.selectedRecords.splice(index, this.selectedRecords.length - index);

			} else {
				let add:boolean=false
				this.records.map((_r) => {
					// loop through all the records, and select everything between the first and the current one
					if (!add && _r.id == this.selectedRecords[this.selectedRecords.length-1]) { add = true; }
					else if (add) {
						this.selectRecord(_r); if (_r.id == r.id) { add = false; }
					}
				})
			}
		} else if (!this.selectedRecords.includes(r.id)) {
			this.selectedRecords.push(r.id);
		}
	}
	public selectingRecords:boolean = false; // whether or not we are operating in bulk
	toggleSelectRecords(toValue?:boolean): void {
		this.selectedRecords = [];
		if (toValue != undefined) { this.selectingRecords = toValue; }
		else { this.selectingRecords = !this.selectingRecords; }
	}
	toggleRecordSelection(r:TrolyObject, event?, max:number=-1): void {
		if (this.selectedRecords.includes(r.id)) {
			this.selectedRecords = this.selectedRecords.filter((id) => id !== r.id);
		} else {
			this.selectRecord(r, event, max);
		}
	}

}