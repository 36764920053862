<h3>
	 <i *ngIf="number.icon" class="font-size-13 me-1 {{number.icon}}"></i>
	 <span *ngIf="number.unit == 'string'"								>{{ number.value }}<sub>{{ number.unit }}</sub></span>
    <span *ngIf="number.unit == 'currency'"							>{{ number.value | currency: selectedCompany.currency:'symbol-narrow':'1.0-0' }}</span>
	 <span *ngIf="!['currency','string'].includes(number.unit)"	>{{ number.value | number:'1.0-0' }}<sub>{{ number.unit }}</sub></span>
    <span *ngIf="number.variation != 0" class="align-top badge bg-soft ms-1 font-size-10"
        [ngbTooltip]="tooltip"
        [ngClass]="{
            'badge-soft-success': (number.variation > 0 && !number.variation_less_is_best) || (number.variation < 0 && number.variation_less_is_best), 
            'badge-soft-danger': (number.variation < 0 && !number.variation_less_is_best) || (number.variation > 0 && number.variation_less_is_best)
        }">{{ number.variation > 0 ? '+' : ''}}{{number.variation | number: '1.0-0' }}{{ number.variation_unit }}</span>
</h3>
<h6 class="text-secondary text-uppercase font-size-11 mb-0">{{number.legend}}</h6>