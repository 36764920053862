import { Component, Input } from '@angular/core';

@Component({
    selector: 'guru',
    templateUrl: './guru-marker.widget.html',
    styleUrls: ['./guru-marker.widget.scss'],
})
export class GuruMarkerComponent {

    @Input() style:string=''; 

    constructor() { }

}