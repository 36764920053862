import { Injectable } from '@angular/core';

import { Document } from '../../models/troly/document.model';
import { ITrolyService, TrolyService } from './troly.service';

@Injectable({
    providedIn: 'root',
})
export class DocumentService extends TrolyService<Document> implements ITrolyService<Document> {

	/**
	 * The name or identifier of the current class, not otherwise available when running in "production mode". 
	 * It is used to output debugging information on the console, and also attached to translations of labels, product tours, etc
	 */
	public readonly __name: string = 'DocumentService';

	constructor() { super('documents'); }

    public make(payload: {} = {}): Document { return payload instanceof Document ? payload : new Document(payload); }

}
