import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter } from 'rxjs';

import { CompanyService } from 'src/app/core/services/troly/company.service';

@Component({
    selector: 'app-redirect',
    templateUrl: './redirect.component.html',
    styleUrls: ['./redirect.component.scss'],
	 standalone: true,
	 imports: [CommonModule]
})
/**
 * Signup component
 */

export class RedirectComponent implements OnInit {

	public loading:boolean = true;

	/**
	 * 
	 */
	bsCssClassPrefix:string = 'card';

	protected route: ActivatedRoute = inject(ActivatedRoute);
	protected router: Router = inject(Router)
	protected companyService: CompanyService = inject(CompanyService)
	
	public ngOnInit(): void {

		this.route.params.pipe(filter(_ => _['code'])).subscribe({
			next: (_params) => {

				const params = {scan:true}; // this will ask the API to properly record this as a public scan
				
				this.companyService.searchTokens(_params['code'], params).subscribe({
					next: (response) => {
						let url = response?.redirect
						if (url) { window.document.location = url; }
						else { this.loading=false;}
					}
				});

			}
		});
	}

}
