import { Directive, HostListener, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs';
import { TrolyListComponent } from 'src/app/core/components/list.component';
import { TrolyObject } from 'src/app/core/models/troly_object';


@Directive({
    selector: 'button[listFilter]',
	 host: {
		"[class.selected]": "this.filterIsApplied",
  	}
})
export class ListFilterDirective implements OnInit, OnChanges {
	
	@Input() listFilterComponent!: TrolyListComponent<TrolyObject>
	
	/**
	 * Defines the various attributes+values to set and unset (toggle) when this button is clicked.
	 */
	@Input('listFilter') defaultFilter: {} = null;

	/**
	 * Specifies which (if any) of the attributes need to see their value changed to a different value, instead just toggled on/off.
	 */
	@Input() listFilterToggleValues: {};

	// convenience instead of calling every time we need these
	protected listFilterToggleKeys: string[];

	// stores the currently applied values for each attribute -- 
	protected listFilterToggleApplied: {};
	
	protected allFilterKeys: string[]=[];
	protected filterIsApplied:boolean=false;
	
	protected route:ActivatedRoute = inject(ActivatedRoute)
	
	ngOnInit(): void {
		
		this.listFilterComponent.all_filters_applied$
			.pipe(filter(_ => !!_))
			.subscribe(queryParams => {

				if (this.listFilterToggleKeys?.length > 0) {
					this.filterIsApplied = this.listFilterComponent.anyQueryApplied(this.listFilterToggleKeys) != null
				} else {
					this.filterIsApplied = this.listFilterComponent.exactQueryApplied(this.listFilterToggleApplied || this.defaultFilter) != null
				}

				if (this.filterIsApplied && !this.listFilterToggleApplied) {
					this.listFilterToggleApplied = Object.assign({}, this.defaultFilter);
				}
			}
		)

		// check this filter against the currently routed values
		this.listFilterComponent.queryAppliedFromRouting(this.defaultFilter, this.listFilterToggleValues);
	}

	ngOnChanges(changes: SimpleChanges): void {
		
		if (changes.listFilterToggleValues) {
			if (changes.listFilterToggleValues.currentValue) {
				this.listFilterToggleKeys = Object.keys(this.listFilterToggleValues);
			} else {
				this.listFilterToggleKeys = null
			}
		}

		if (changes.listFilter?.currentValue) {
			//console.warn('listFilter', this.listFilter)
			this.allFilterKeys = Object.keys(this.defaultFilter);
		} else if (changes.listFilter) {
			console.error('listFilter', this.defaultFilter)
		}

	}

	@HostListener('click') onClick() {
		
		if (this.listFilterToggleKeys?.length > 0) {

			// we have one one many attributes that have a value to toggle to 'the next one'

			if (!this.listFilterToggleApplied) {

				// first time around we always set the expected **Applied filter to be the initial/default one
				this.listFilterToggleApplied = Object.assign({}, this.defaultFilter);

			} else {

				// we have already applied the (default/initial) listFilter, now we increment the values set

				this.listFilterToggleKeys.forEach(key => {
					
					const currentValueIndex = this.listFilterToggleValues[key].indexOf(this.listFilterToggleApplied[key]);

					// given we know where the current value is indexed, reset to the first one at the end of the chain or increment to the next one if possible
					if (currentValueIndex + 1 == this.listFilterToggleValues[key].length) {
						// trigger setQuery to unset
						this.listFilterToggleApplied[key] = undefined; 
					} else {
						// toggle to next value
						this.listFilterToggleApplied[key] = this.listFilterToggleValues[key][currentValueIndex + 1]; 
					}
				});

			}

			// apply the new filter or clear it if we have reached the end of the chain
			this.listFilterComponent.setQuery(this.listFilterToggleApplied);

			if (Object.values(this.listFilterToggleApplied).filter(_ => !!_).length == 0) {
				this.listFilterToggleApplied = null;
			}

		} else {
			this.listFilterComponent.toggleQuery(this.defaultFilter);
		}
	}

}
