import { HttpClient } from '@angular/common/http';
import { isDevMode } from '@angular/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader } from '@ngx-translate/core';
import { BehaviorSubject, Observable, catchError, combineLatest, map, of, tap } from 'rxjs';
import { mergeDeep } from 'src/app/shared/utils';

export class I18nLoader extends TranslateLoader {

	private http: HttpClient;
	prefix: string;
	suffix: string;

	/**
	 * We are loading the translations via http requests so the browser will do minimal caching.
	 */
	public _cache$: BehaviorSubject<Object> = new BehaviorSubject<Object>(null);


	private currentLang: string;
	
	constructor(http: HttpClient, prefix: string, suffix: string) {
		super();
		this.http = http;
		this.prefix = prefix;
		this.suffix = suffix;
	}
	
	getTranslation(lang: string, path:string='', nodeName:string=undefined): Observable<Object> {

		this.currentLang = lang;
		
		if (!this._cache$.value || !this._cache$.value[nodeName]) {

			if (path && !path.match(/\/i18n\/$/)) { path = path + '/i18n/';}

			return this.localisedLoad(lang, path).pipe(
				map((data) => {
					const result = nodeName ? { [nodeName]: data } : data
					return mergeDeep(this._cache$.value || {}, result);
				}),
				tap((data) => this._cache$.next(data))
			);

		} 
		return this._cache$;
	}

	/**
	 * 
	 * @param path 
	 * @returns 
	 */
	localisedLoad(lang:string, path:string=''): Observable<Object> {
		
		if (lang.match('-')) {
			// when loading a localised language -- fr-FR or es-AR or else... 
			// we split, make 2 calls, and merge the results.
			const arr = lang.split('-');
			return combineLatest([
						this.getJson(path + arr[0]),
						this.getJson(path + lang)])
						.pipe(
							map(([global, local]) => mergeDeep(global, local))
						);
		} else {
			// loading a straight 'en' language, not localised.
			return this.getJson(path + this.currentLang)
		}
	}

	/**
	 * 
	 * @param uri 
	 * @returns 
	 */
	getJson(path: string): Observable<Object> {
		return this.http.get(this.prefix + path + this.suffix).pipe(catchError((error) => { return of({}) }));
	}
}


// AoT requires an exported function for factories
export function createI18nLoader(http: HttpClient) {
	return new I18nLoader(http, '/assets/i18n/', '.json');
}

export class I18nMissingTranslationHandler implements MissingTranslationHandler {

	handle(params: MissingTranslationHandlerParams): string {
		if (isDevMode()) {  return `>>> ${params.key} <<<`; }
		else { return ''; }
	}
}