import { Injectable } from '@angular/core';

import { User } from '../../models/troly/user.model';
import { ITrolyService, TrolyService } from './troly.service';

import { environment } from 'src/environment/environment';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Company } from '../../models/troly/company.model';

@Injectable({
	providedIn: 'root',
})

/*
  This class is in charge of all loading and unloading of a user's profile.

  There can only ever be a single profile loaded for a given app instance.

  i.e. if you load an order, it will load and set the current user to the user in question
*/
export class UserService extends TrolyService<User> implements ITrolyService<User> {

	/**
	 * The name or identifier of the current class, not otherwise available when running in "production mode". 
	 * It is used to output debugging information on the console, and also attached to translations of labels, product tours, etc
	 */
	public readonly __name: string = 'UserService';

	constructor() { 
		super('users'); 

		const u = this.storedUser()
		if (u?.id) { this.record$.next(u) }
	}

	/**
	 * RECORD Notified: the UserService notifier is attached to the currently authenticated user
	 */


	public _next(obj: User, flush_attr?: string|string[], force:boolean=false): User {
		if (force || this.storedUser().id == obj.id) { this.storedUser(obj, 'login'); }
		return super._next(obj, flush_attr, force);
	}

	public make(payload: {} = {}): User { return payload instanceof User ? payload : new User(payload); }


// Read: https://github.com/heartcombo/devise/blob/main/app/controllers/devise/passwords_controller.rb

//     new_user_password GET                          /users/password/new(.:format)                                                                     devise/passwords#new
// 	edit_user_password GET                          /users/password/edit(.:format)                                                                    devise/passwords#edit
// 		  user_password PATCH                        /users/password(.:format)                                                                         devise/passwords#update
// 							 PUT                          /users/password(.:format)                                                                         devise/passwords#update
// 							 POST                         /users/password(.:format)                                                                         devise/passwords#create

// Read: https://github.com/heartcombo/devise/blob/main/app/controllers/devise/confirmations_controller.rb

// new_user_confirmation GET                          /users/confirmation/new(.:format)                                                                 confirmations#new
// 	 user_confirmation GET                          /users/confirmation(.:format)                                                                     confirmations#show
// 							 POST                         /users/confirmation(.:format)                                                                     confirmations#create

// Read: https://github.com/heartcombo/devise/blob/main/app/controllers/devise/unlocks_controller.rb

// 		new_user_unlock GET                          /users/unlock/new(.:format)                                                                       devise/unlocks#new
// 			 user_unlock GET                          /users/unlock(.:format)                                                                           devise/unlocks#show
// 							 POST                         /users/unlock(.:format)                                                                           devise/unlocks#create

// Read: https://github.com/heartcombo/devise/blob/main/app/controllers/devise/sessions_controller.rb

// 	  new_user_session GET                          /users/sign_in(.:format)                                                                          sessions#new
// 			user_session POST                         /users/sign_in(.:format)                                                                          sessions#create
//  destroy_user_session DELETE                       /users/sign_out(.:format)                                                                         sessions#destroy

	/**
	 * Handles the deletion of a user, which really means logging from the API. (record deletion from the API goes through "archival" process)
	 * @param payload 
	 * @param method 
	 * @returns 
	 */
	public logout(params?: {}): Observable<User> {
		return this.delete(null, 'sign_out').pipe(
			map(_ => this.make(_.body)) // the API response (Devise session) returns a {} object, not a {user:User} object
		);
	}

	public login(payload:User, params?: {}): Observable<User> {

		// Add this point, we know a user is trying to login, 
		// we add their browser timezone offset to save if we didn't have one already
		// this is important in order to retrieve records from the correct timeframe (the API is based on UTC+0)
		// also, see TrolyObject.constructor -- all dates from the API are converted to local time for display.
		payload.pref_utc_offset = (new Date().getTimezoneOffset() * -60) // getTimezoneOffset is number of minutes to UTC, we're converting to seconds FROM utc
		payload.pref_locale = navigator.language

		delete payload['id']; // we should never post a /users/2/sign_in url..

		params ||= {}
		params['with_companies']=true

		return this.create(payload, params, 'sign_in')
	}

	public sessionGET(method: string, params?: {}): Observable<User> {
		return this.get(null, method, params).pipe(map(_ => this.make(_.body[this.singular_node])));
	}

	public sessionPOST(method: string, obj:User, params?: {}): Observable<User> {
		return this.create(obj, params, method)
	}

	public sessionPUT(method: string, obj:User, params?: {}): Observable<User> {
		return this.save(obj, params, method)
	}

	public sendToDataLayerGTM(u:User, c:Company) {
		if (!u.companies || u.companies.length == 0) { 
			console.warn('User has no companies, skipping dataLayer');
			return;
		}
		if ((<any>window).dataLayer) {
			(<any>window).dataLayer.push({
				'user_id': u.id,
				'email': u.email,
				'fname': u.fname,
				'lname': u.lname,
				'language': u.language,
				'role': u.primaryRole(c.id),
				'locale': u.pref_locale,
				'expertise_level': u.expertise_level,
				'created_at': u.created_at,
				'anonimo': u.anonimo,
				'business_name': c.business_name,
				'company_id': c.id,
				'company_ids': u.companies.map(_ => _.id),
				'company_created_at': c.created_at,
				'user_internal': environment.production ? (u.email.match('@troly.(co|org)') ? 'internal' : undefined) : 'developer' // when running in a non-production mode, we assume its developer traffic, for debugging tags.
			});
		}
	}
}