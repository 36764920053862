<div class="row status-{{ status }}">
    <div class="col-12">
        <div class="status text-end" [ngClass]="{
            'text-secondary' : status != 'error',
            'text-danger' : status == 'error'
        }">&nbsp;
            <span class="pe-1" *ngIf="status == 'submitted'">{{ "Sockets.Progress.status.submitted" | translate }}</span>
            <span class="pe-1" *ngIf="status == 'pending'">{{ "Sockets.Progress.status.pending" | translate }}</span>
            <span class="pe-1" *ngIf="status == 'completed' || status == 'running' || status == 'error' ">{{
                'Sockets.Progress.messages.'+socketStatus | translate : { progress:(latestOk+latestFail), ok:latestOk, err:latestFail, total:latestTotal, name:latestRecord } }}</span>
        </div>

        <div class="progress">
            <div class="meter text-white text-end {{ status == 'error' ?  'bg-danger' : 'bg-primary' }}"
                [style.width]="progress"></div>
            <div class="{{ status }}" [ngClass]="{ 'intederminate': status != 'submitted'}"></div>
        </div>
    </div>
</div>