import { Directive, Input, inject } from "@angular/core";
import { SweetAlertOptions } from "sweetalert2";
import { UserService } from "../services/troly/user.service";
import { AlertService } from "../services/utils/alert.service";

@Directive({
	selector: '[linkWarning]',
	host: {
		"(click)": "onClick($event)"
	},
	standalone: true,
})
export class linkWarningDirective {

	protected userService: UserService = inject(UserService);
	protected alertService: AlertService = inject(AlertService);
	
	private context: string;
	private link: string;

	@Input() set linkWarning(linkWarning: string) {
		const config = (linkWarning || '').split('.')
		if (config.length >= 1) { this.context = config[0];}
		if (config.length >= 2) { this.link = config[1];}
		if (!this.link || !this.context) { 
			console.error("Invalid linkWarning directive configuration. Expecteded format: 'context.link'");
		}
	}

	public onClick(e) {

		e.preventDefault();
		let element: HTMLElement = e.target;
		if (element.nodeName !== 'A') { element = element.closest('a'); }

		if (element.nodeName === 'A') {

			let clicksRecorded = this.userService.cachedUiKey(`${this.context}.linkWarning`, {})

			clicksRecorded[this.link] ||= 0;
			clicksRecorded[this.link] = clicksRecorded[this.link] + 1;

			if (clicksRecorded[this.link] <= 3) {
				let options: SweetAlertOptions = this.alertService.alertOptions('error', 'continue', 'cancel');
				options.title = `Continue to the world wild web?`;
				options.html = "You are about to visit a softare platform outside of Troly. Your credentials to access this platform are <small>(hopefully!)</small> different to those used with Troly.<br/><br/>For your security <strong>do not share</strong> <small>(ever)</small> credentials with staff or members of the community.";

				this.alertService.checkConfirmation(options).then(
					(yes) => { if (yes) { this.userService.storeUiKey(`${this.context}.linkWarning`, clicksRecorded); this.go(element['href'], element['target']); }}
				);


			} else {
				this.userService.storeUiKey(`${this.context}.linkWarning`, clicksRecorded);
				this.go(element['href'], element['target']);
			}
		}
	}

	/**
	 * 
	 * @param url 
	 * @param target 
	 */
	public go(url: string, target?:string) {
		window.open(url, target || '_blank');
	}

}