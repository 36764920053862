<ul ngbNav class="nav-pills" orientation="vertical" [(activeId)]="focusOn">
	<li ngbNavItem *ngFor="let menu of menus" [disabled]="menu.disabled" [ngbNavItem]="menu.value" [ngClass]="{'d-none':menu.visible === false}">
		<a ngbNavLink>
			<i *ngIf="menu.icon" [class]="menu.icon" [ngClass]="{ 
				'bx' : menu.icon.match('bx-') || menu.icon.match('bxs-'),
				'mdi' : menu.icon.match('mdi-')
			}"></i>
			<span>{{ menu.label }}</span>
			<i class="ms-2 bx {{ menu.statusIcon }}" *ngIf="menu.statusIcon"></i>
	  	</a>
	</li>
</ul>