import { Component, Input, inject } from '@angular/core';
import { BulkModal } from 'src/app/core/components/bulk.modal';
import { Customer } from 'src/app/core/models/troly/customer.model';
import { TrolyObject } from 'src/app/core/models/troly_object';
import { CustomerService } from 'src/app/core/services/troly/customer.service';
import { TrolyAngularModule } from 'src/app/shared/angular.module';
import { TrolyMaterialModule } from 'src/app/shared/material.module';
import { TrolySharedModule } from 'src/app/shared/shared.module';

/**
 * Component for creating and sending SMS messages.
 * Utilizes a modal interface for inputting the message content and sending it to selected customers.
 * Inherits functionalities from BulkModal to handle bulk operations and modal behaviors.
 */
@Component({
	selector: 'create-sms-modal',
	templateUrl: './create-sms.modal.html',
	styleUrls: ['./create-sms.modal.scss'],
	standalone: true,
	imports: [TrolyAngularModule, TrolyMaterialModule, TrolySharedModule],
})
export class CreateSmsModal extends BulkModal {
	/**
	 * The name or identifier of the current class, not otherwise available when running in "production mode".
	 * @see TrolyComponent.__name (Override)
	 */
	override readonly __name: string = 'CreateSmsModal';

	@Input('record') record: Customer = new Customer();

	_formFields = ['body'];

	_formDefaults = {};

	/**
	 * Additional services used this this component.
	 * ? Keeping in mind CompanyService and UserService are already available in TrolyComponent.
	 */
	protected customerService: CustomerService = inject(CustomerService);

	constructor() {
		super();

		this.service = this.customerService;
		this.operation = 'message'; // used by BulkModal to post `this.service`
		this.initForm();

		this.loadTranslation('modules/orders/summary-page/tabs/processing-tab/bulk-modals', 'SharedI18n.BulkModals') // required to access translations for the company templates
	}

/**
	 * Submits the sms form data to the backend.
	 * This method gathers the input from the form fields, constructs a payload with the message details,
	 * and invokes the parent class's onSubmit method to handle the actual submission process.
	 * The payload includes the sms's body, and template information. If the 'body' field is filled,
	 * The method relies on the parent's implementation to manage response handling, form state updates, and user feedback.
	 */

	onSubmit() {
		
		let payload = { subject: this.form.get('subject').value, body: this.form.get('body').value, channel: 'sms' };

		let obj = new TrolyObject('', payload)

		super.onSubmit(obj);	
	}
}
