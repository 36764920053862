import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Input, SimpleChanges, ViewChild, inject } from '@angular/core';
import { filter } from 'rxjs';

import { TrolyModal } from 'src/app/core/components/troly.modal';
import { Order } from 'src/app/core/models/troly/order.model';
import { OrderTag, Tag } from 'src/app/core/models/troly/tag.model';
import { TrolyFormGroup } from 'src/app/core/models/troly_form';
import { OrderService } from 'src/app/core/services/troly/order.service';
import { TrolyValidationError } from 'src/app/core/services/troly/troly.interceptor';
import { TrolyAngularModule } from 'src/app/shared/angular.module';
import { TrolyMaterialModule } from 'src/app/shared/material.module';
import { TrolySharedModule } from 'src/app/shared/shared.module';
import { SweetAlertOptions } from 'sweetalert2';

@Component({
	selector: 'edit-order-modal',
	templateUrl: './order-edit.modal.html',
	styleUrls: ['./order-edit.modal.scss'],
	standalone: true,
	imports: [TrolyAngularModule, TrolyMaterialModule, TrolySharedModule]
})
export class OrderEditModal extends TrolyModal<Order> {

	override readonly __name:string = 'OrderEditModal'

	@Input("record") record?: Order = new Order()

	@Input("focusOn") focusOn: string /*'billing'|'fulfilment'|'notes'*/ = 'billing';

	_formFields = ['billing_name2','payment_notes','staff_notes','product_notes','packaging_notes','personal_message', 'customer_notes', 'is_gift_order', 'business_name','warehouse_id', 'membership_id',
					'billing_name','billing_phone','billing_email','billing_address','billing_suburb','billing_state', 'billing_postcode','billing_country', 'send_changed_notification',
					'track_source','track_channel','track_partner', 'track_campaign', 'created_by_id', 'confirmed_by_id', 'paid_by_id', 'packed_by_id', 'labelled_by_id', 'completed_by_id', 'shipment.quoted_by_id','shipment.completed_by_id',
					{ shipment:['id', 'delivery_name', 'delivery_name2', 'delivery_line1','delivery_line2','delivery_suburb','delivery_state','delivery_postcode','delivery_country','delivery_instructions','delivery_email','delivery_phone','selected_carrier','selected_service','signature_option','insurance_option'] }];
	
	_formDefaults = { membership_id:'' }; // ensures the default membership_id is not null (and default radio is selected)

	public tagsForm: TrolyFormGroup;
	_tagsFormFields = ['id', 'tags', 'search', 'searchResults', 'totalResults'];
	_tagsFormDefaults = { tags:[], searchResults: [], totalResults: -1 };

	/**
	 * Additional services used this this component. 
	 * ? Keeping in mind CompanyService and UserService are already available in TrolyComponent. 
	 */
	protected orderService: OrderService = inject(OrderService);

	constructor() {
		super();
			
		// setting the default service for forms + obtaining/saving data in this component.
		this.service = this.orderService;

		this.initForm() // loads the normal order form

		// inits 
		this.tagsForm = (new Order()).mergeIfUndefined(this._tagsFormDefaults).toFormGroup(this._tagsFormFields, undefined, 'change');
		this.tagsForm = this.orderService.taggableModule.initToggleTag(this.tagsForm, 'tags')

		this.toggleTrackingDetails(false);

		this.markAsLoaded(this.tagsForm)
	}

	protected receiveInputParams(changes: SimpleChanges): void {
		if (changes.record?.currentValue) {

			if (this.record.id) {
				const params = { with_customer:true }
				/** loads the customer + recipient records which includes memberships  */
				this.orderService.find(this.record.id, params).subscribe((_) => {
					this.record = _;
					this.updateForm();
				});
			}
		}

		if (changes.focusOn?.currentValue) {
			if (this.selectedCompany.guest_customer_id == this.record.customer_id || this.selectedCompany.guest_customer_id == this.record.recipient_id) {
				this.focusOn = ['notes','discounts'].includes(this.focusOn) ? this.focusOn : 'notes';
			}
		}
		super.receiveInputParams(changes);
	}

	// The best place to attach data loading (subscribes) and assign to forms.
	protected loadData() {

		super.loadData();

		this.companyService.record$.pipe(filter(_ => !!_)).subscribe((_) => {
			this.initRecordAttributeOnce('integrations', this.companyService.loadIntegrations(), _, this.companyService); // safe within subscribeRecord
			this.initRecordAttributeOnce('warehouses', this.companyService.loadWarehouses(), _, this.companyService).subscribe((_) => {
				if (_.length == 0) { this.form.get('warehouse_id').disable(); } 
				else { this.form.get('warehouse_id').enable(); }
			}); // safe within subscribeRecord
		})

		this.form.valueChanges.subscribe((_) => {
			this.changesToMonitor.forEach((key) => {
				if (this.form.get(key)?.dirty) {
					if (!this.changesReceived.includes(key)) {
						this.changesReceived.push(key)
					}
				} else {
					this.changesReceived = this.changesReceived.filter(_ => _ != key);
				}
			})
		})
	}


	public searchArea(target:string = 'billing') {
		return this.form ? [this.form.get(`${target}_suburb`).value, this.form.get(`${target}_state`).value +' '+ this.form.get(`${target}_postcode`).value, this.form.get(`${target}_country`).value].filter(_ => _).join(', ').trim() : ''
	}

	public allocatedCartons(): string {
		return this.record.allocated_cartons.map(_ => _.name).join(', ')
	}

	
	changesToMonitor:string[] = ['warehouse_id','shipment.selected_carrier','shipment.signature_option','shipment.insurance_option','shipment.delivery_instructions', 'billing_email','billing_phone','shipment.delivery_email','shipment.delivery_phone','product_notes','payment_notes','customer_notes'];
	changesReceived:string[] = [];
	approvedChanges:string[] = []

	togglePermanentChange(key:string) {
		if (this.approvedChanges.includes(key)) {
			this.approvedChanges = this.approvedChanges.filter(_ => _ != key)
		} else {
			this.approvedChanges.push(key);
		}
	}

	dismissPersist(e?) {
		this.changesToMonitor = [];
	}

	onSubmit(): void {

		this.markAsLoading(this.form);
		let changes = this.form.getChanges();

		this.form.disable();

		if (!changes) {

			this.form.resetCodes({ error: 'NOCHANGE' });
			this.markAsLoaded(this.form);
			this.form.enable()

		} else {

			this.service.saveOrCreate(new Order(changes)).subscribe({
				next: (_: Order) => {

					this.form.resetCodes({ success: this.record.id ? 'SAVED' : 'CREATED' });
					
					this.record = _;
					this.updateForm();

					this.form._timer = setTimeout(() => {
						this.resolveModal('close');
					}, this.form.countdown());

					this.markAsLoaded(this.form);
				},
				error: (err: HttpErrorResponse) => {

					if (err instanceof TrolyValidationError) {
						this.form.assignValidationErrors(err.error);
						this.markAsLoaded(this.form);
					} else {
						this.unknownApiError('9866-across-redolent-FLACON')
					}
				}
			});
		}
	}

	// https://github.com/angular/components/issues/10968
	@ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;
	async addTag(tag:Tag) {

		this.service.status('Adding tag');

		const added = new OrderTag({ tag_id: tag.id, tag: tag, order_id:this.record.id })
		// assume success, and add to order_tags to render	immmediately
		this.record.order_tags.push(added)
		
		tag = new Tag(tag); // the toString() needs a proper tag instance -- no just a plain hash object (such as localStorage-cached values)
		
		this.orderService.taggableModule.toggleTag<OrderTag>(this.form, 'tags', tag, added, 'add').subscribe((_) => {
			this.service.snack(`${tag} was added`, 'success')
		}, (err) => {
			this.service.snack(`Error adding ${tag}`, 'error')
			this.record.order_tags = this.record.order_tags.filter((_: OrderTag) => { return _.tag_id != tag.id })
		},
		() => { this.service.status(); });

		// reset search
		this.form.patchValue({
			searchResults: [],
			totalResults: -1,
			search: '' // doesn't work, hence the following line
		});

		this.searchInput.nativeElement.value = ''; // https://github.com/angular/components/issues/10968
	}

	public removeTag(tag, modalTitle, modalBody) {

		let options: SweetAlertOptions = this.alertService.alertOptions('warning', 'continue', 'cancel');
		options.title = modalTitle;
		options.html = modalBody;
		//options.footer = this.crispHelpdeskLink('Learn about scheduled membership orders','hkzlue');

		this.alertService.confirm(options).then((_) => {

			const removed = this.record.order_tags.find(_ => _.tag_id == tag.id)
			this.service.status('Removing tag');
			this.orderService.taggableModule.toggleTag<OrderTag>(this.form, 'tags', tag, removed, 'remove').subscribe((_) => {
				this.service.snack(`${tag} was removed`, 'success')
			}, (err) => {
				this.service.snack(`Error removing ${tag}`, 'error')
			},
			() => { this.service.status(); });
		});
	}

	public toggleTrackingDetails(value:boolean) {
		this.form.toggleFieldStatus(['track_source', 'track_channel', 'track_partner', 'track_campaign','created_by_id','confirmed_by_id','paid_by_id','packed_by_id','labelled_by_id','completed_by_id'], value);
	}
}
