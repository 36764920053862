<div class="card {{ loading.record }}" [ngClass]="{ 'is-modal': bsCssClassPrefix == 'modal' }">
	<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown)="submitOnEnter($event)" class="{{ form.cssClass }}"
		[ngClass]="{ 'success': form.successCode && !form.hideFormStatusMessage }">

		<div class="{{ bsCssClassPrefix }}-header">
			<h4 class="{{ bsCssClassPrefix }}-title"><span>Order {{record.number}}: <em>{{ form.get('billing_name').value }}</em></span></h4>
			<button type="button" class="btn-close" [disabled]="form.seconds > 0"
				(click)="resolveModal('x')"></button>
		</div>
		<div class="{{ form.loading }} {{ bsCssClassPrefix }}-body">

			<div class="row">
				<div class="col-3">
					<mini-nav-widget [(selected)]="focusOn" [menus]="[
						{ 
							value:'billing',
							label: 'Billing',
							disabled: record.customer_id == selectedCompany.guest_customer_id,
							icon: (focusOn == 'billing' ? 'bxs-error-circle' : 'bx-error-circle')
						},
						{
							value:'packaging',
							label: 'Packaging',
							icon: (focusOn == 'packaging' ? 'bxs-package' : 'bx-package')
						},
						{
							value:'fulfilment',
							label: 'Shipping',
							disabled: record.recipient_id == selectedCompany.guest_customer_id,
							icon: (focusOn == 'fulfilment' ? 'bxs-truck' : 'bxs-truck')
						},
						{
							value:'notes',
							label: 'Notes',
							icon: (focusOn == 'notes' ? 'bxs-pencil' : 'bx-pencil')
						},
						{
							value:'tracking',
							label: 'Tracking',
							icon: (focusOn == 'tracking' ? 'bxs-cog' : 'bx-cog')
						}
					]"></mini-nav-widget>
				</div>
				<div class="col-9">

					<form-status [formGroup]="form" successAction="redirect" (dismissCallback)="resolveModal($event)"
						[record_label]="form.get('billing_name').value">
					</form-status>

					<div class="row">

						<ng-container *ngIf="focusOn == 'billing'">
							
							<div class="col-6">
								<mat-form-field>
									<mat-label>Billing Name</mat-label>
									<input matInput formControlName="billing_name" type="text" />
								</mat-form-field>
							</div>
							<div class="col-6">
								<mat-form-field>
									<mat-label *ngIf="record.trade_account_enabled">Attention of</mat-label>
									<mat-label *ngIf="!record.trade_account_enabled">Company</mat-label>
									<input matInput formControlName="billing_name2" type="text" />
								</mat-form-field>
							</div>
							<div class="col-6">
								<mat-form-field>
									<mat-label>Phone</mat-label>
									<input matInput formControlName="billing_phone" type="text" />
								</mat-form-field>
							</div>
							<div class="col-6">
								<mat-form-field>
									<mat-label>Email</mat-label>
									<input matInput formControlName="billing_email" type="text" />
								</mat-form-field>
							</div>
		
							<div class="col-10 offset-1" *ngIf="!record?.captureAddressInParts(form.get('billing_address').value)">
								<geo-search-component [fieldName]="'Models.Address.Attributes.search' | translate"
									[line1]="form.get('billing_address').value"
                        	[searchArea]="searchArea('billing')" [disabled]="form.disabled"
                        	(selectCallback)="form.patchDirty($event)">
								</geo-search-component>
							</div>
		
							<ng-container *ngIf="record?.captureAddressInParts(form.get('billing_address').value)">
								<div class="col-12 mt-1 mb-3">
									<div class="d-flex">
										<div class="avatar-xs me-2"><span
												class="avatar-title rounded-circle font-size-20 bg-soft bg-warning"><i
													class="bx bxs-error-circle text-warning"></i></span></div>
										<h6 class="font-size-10 mt-1 ms-2"><strong>PO Box detected</strong><br>Please
											enter the following details carefully to avoid billing errors.</h6>
									</div>
								</div>
								<div class="col-6">
									<mat-form-field>
										<mat-label>
											{{ 'Models.Address.Attributes.suburb' | translate }}
										</mat-label>
										<input matInput formControlName="billing_suburb" type="text" />
									</mat-form-field>
								</div>
								<div class="col-3">
									<mat-form-field>
										<mat-label>
											{{ 'Models.Address.Attributes.state' | translate }}
										</mat-label>
										<input matInput formControlName="billing_state" type="text" />
									</mat-form-field>
								</div>
								<div class="col-3"></div>
								<div class="col-4">
									<mat-form-field>
										<mat-label> {{ 'Models.Address.Attributes.postcode' | translate }} </mat-label>
										<input matInput formControlName="billing_postcode" type="text" />
									</mat-form-field>
								</div>
								<div class="col-5">
									<mat-form-field>
										<mat-label>
											{{ 'Models.Address.Attributes.country' | translate }}
										</mat-label>
										<input matInput formControlName="billing_country" type="text" />
									</mat-form-field>
								</div>
								<div class="col-3"></div>
							</ng-container>

							<ng-container *ngIf="record.customer?.memberships">
								<div class="col-12" [ngClass]="{'disabled':form.disabled}"><h5>Assigned Membership <small class="text-danger">*</small></h5></div>
								
								<div class="col-12 ps-3 pb-2">
									<mat-radio-group formControlName="membership_id">
										<mat-radio-button class="d-block" value="">Not a membership order</mat-radio-button>
										<mat-radio-button class="d-block" [value]="m.id" *ngFor="let m of record.customer.memberships">
											<div>{{ m.name }} 
												<span *ngIf="m.id == record.membership_id && !record.membership_purchase_sequence">(Not a Member Pack <i class="bx bx-chevron-down"></i>)</span>
												<span *ngIf="m.id == record.membership_id && record.membership_purchase_sequence"> (Pack #{{ record.membership_purchase_sequence }} <i class="bx bx-chevron-down"></i>)</span>
											</div>
										</mat-radio-button>
									</mat-radio-group>
								</div>
							</ng-container>

							<div class="col-12"><h5>Additional Discounts</h5></div>

							<div class="col-8">
								<mat-form-field>
									<mat-label>Description</mat-label>
									<input matInput formControlName="" type="text" />
								</mat-form-field>
							</div>
							<div class="col-4">
								<mat-form-field>
									<mat-label>Value</mat-label>
									<input matInput formControlName="" type="text" />
								</mat-form-field>
							</div>

							<div class="col-12">
								<h5>Order Notifications</h5>
								<mat-checkbox formControlName="send_changed_notification">
									Send an email notification
								</mat-checkbox>
								<p class="mb-2 font-size-11">When the content, billing or value of this order changes.</p>
							</div>


						</ng-container>

						<ng-container *ngIf="focusOn == 'packaging'">
							<div class="row">
								<div class="col-6">
									<mat-form-field>
										<mat-label>Carton Preference</mat-label>
										<mat-select>
											<mat-option value=""></mat-option>
											<mat-option *ngFor="let s of selectedCompany.packaging" [value]="s.id">{{ s.name }}</mat-option>
										</mat-select>
										<mat-hint>Overrides automatic packaging selection</mat-hint>
									</mat-form-field>
								</div>
								<div class="col-6">
									<mat-form-field>
										<mat-label>Warehouse</mat-label>
										<mat-select formControlName="warehouse_id" [disabled]="selectedCompany.warehouses.length == 0">
											<mat-option *ngFor="let warehouse of selectedCompany?.warehouses" [value]="warehouse.id">{{ warehouse.business_name }}</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-12">
									<mat-form-field>
										<mat-label>Carton Breakdown</mat-label>
										<textarea matInput readonly="true">{{ allocatedCartons() }}</textarea>
									</mat-form-field>
								</div>
								<div class="col-12">
									<mat-form-field>
										<mat-label>Packaging Notes</mat-label>
										<textarea matInput formControlName="packaging_notes"></textarea>
									</mat-form-field>
								</div>
								<div class="col-12" style="margin-top:-0.5rem;">
									<mat-checkbox #packagingCheck [checked]="form.get('packaging_notes').value" (change)="form.patchDirty({packaging_notes: $event.checked ? 'Gift wrap requested' : ''})" color="primary">
										Order should be wrapped
									</mat-checkbox>
								</div>
							</div>
						</ng-container>
						
						<ng-container *ngIf="focusOn == 'fulfilment'">
							<div class="row">
								<div class="col-10">
									<mat-checkbox matCheckbox (click)="form.patchDirtyCheckbox('shipment', !form.get('shipment').value )" formControlName="shipment">Ship this order
										<i *ngIf="form.isSaving('trade_account_enabled')" class="bx bx-spin bx-loader-alt"></i>
										<i *ngIf="form.isSaved('trade_account_enabled')" class="bx bxs-check-circle bx-fadeout text-success "></i>
									</mat-checkbox>
								</div>
								<ng-container *ngIf="record.shipment" formGroupName="shipment">
									<div class="col-6">
										<mat-form-field>
											<mat-label>Ship to</mat-label>
											<input matInput formControlName="delivery_name" type="text" />
										</mat-form-field>
									</div>
									<div class="col-6">
										<mat-form-field>
											<mat-label>Company Name</mat-label>
											<input matInput formControlName="delivery_name2" type="text" />
										</mat-form-field>
									</div>
									<div class="col-6">
										<mat-form-field>
											<mat-label>Phone</mat-label>
											<input matInput formControlName="delivery_phone" type="text" />
										</mat-form-field>
									</div>
									<div class="col-6">
										<mat-form-field>
											<mat-label>Email</mat-label>
											<input matInput formControlName="delivery_email" type="text" />
										</mat-form-field>
									</div>
									<div class="col-8">
										<mat-form-field>
											<mat-label>Delivery Address</mat-label>
											<input matInput formControlName="delivery_line1" type="text" />
										</mat-form-field>
									</div>
									<div class="col-8"
										*ngIf="!record.captureAddressInParts(form.get('shipment.delivery_line1').value)">
											<geo-search-component [fieldName]="'Models.Address.Attributes.search' | translate"
												[line1]="form.get('shipment.delivery_line1').value"
                        				[searchArea]="searchArea('shipment.delivery')" [disabled]="form.disabled"
                        				(selectCallback)="form.patchDirty($event)">
											</geo-search-component>
									</div>
									<ng-container *ngIf="record.captureAddressInParts(form.get('shipment.delivery_line1').value)">
										<div class="col-12 mt-1 mb-3">
											<div class="d-flex">
												<div class="avatar-xs me-2"><span
														class="avatar-title rounded-circle font-size-20 bg-soft bg-warning"><i
															class="bx bxs-error-circle text-warning"></i></span></div>
												<h6 class="font-size-10 mt-1 ms-2"><strong>PO Box detected</strong><br>Please enter
													the following details carefully to avoid delivery errors.</h6>
											</div>
										</div>
										<div class="col-6">
											<mat-form-field>
												<mat-label> {{ 'Models.Address.Attributes.suburb' | translate }} </mat-label>
												<input matInput formControlName="delivery_suburb" type="text" />
											</mat-form-field>
										</div>
										<div class="col-3">
											<mat-form-field>
												<mat-label> {{ 'Models.Address.Attributes.state' | translate }} </mat-label>
												<input matInput formControlName="delivery_state" type="text" />
											</mat-form-field>
										</div>
										<div class="col-3"></div>
										<div class="col-4">
											<mat-form-field>
												<mat-label> {{ 'Models.Address.Attributes.postcode' | translate }} </mat-label>
												<input matInput formControlName="delivery_postcode" type="text" />
											</mat-form-field>
										</div>
										<div class="col-5">
											<mat-form-field>
												<mat-label> {{ 'Models.Address.Attributes.country' | translate }} </mat-label>
												<input matInput formControlName="delivery_country" type="text" />
											</mat-form-field>
										</div>
										<div class="col-3"></div>
									</ng-container>		
							
									<div class="col-8">
										<mat-form-field>
											<mat-label>Delivery Instruction</mat-label>
											<input matInput formControlName="delivery_instructions" type="text" />
										</mat-form-field>
									</div>
							
									<div class="col-12"><h5>Carrier Settings</h5></div>
									<div class="col-6">
										<mat-form-field>
											<mat-label>Preferred Carrier</mat-label>
											<mat-select formControlName="shipping_integration_id">
												<mat-option *ngFor="let i of selectedCompany.shippingIntegrations()" value="{{ i.provider }}" [disabled]="i.status != 'ready'">
													{{ 'Models.Integration.Values.provider.' + i.provider | translate }}
													<span class="badge rounded-pill badge-soft-dark" *ngIf="i.status != 'ready' && i.status != 'error'"> disabled </span>
													<span class="badge rounded-pill badge-soft-danger text-danger" *ngIf="i.status != 'ready' && i.status == 'error'">error</span>
											  </mat-option>
		  
											  <mat-option value="cheapest">Cheapest</mat-option>
											  <mat-option value="fastest" [disabled]="true">Fastest</mat-option>
											  <mat-option value="most_reliable" [disabled]="true">Most Reliable</mat-option>
											  <mat-option value="best_insurance" [disabled]="true">Best Insurance Coverage</mat-option>
				
											</mat-select>
										</mat-form-field>
									</div>
									<div class="col-6">
										<mat-form-field>
											<mat-label>Shipping Service</mat-label>
											<mat-select formControlName="selected_service">
												<ng-container *ngIf="record.shipment.shipping_quotes">
													<mat-option *ngFor="let s of record.shipment.shipping_quotes" [value]="s">
														{{ s }}
													</mat-option>
												</ng-container>
												<ng-container *ngIf="!record.shipment.shipping_quotes">
													<mat-option *ngFor="let s of selectedCompany.getShippingServicesForProvider(form.get('shipment.selected_carrier').value)" [value]="s">
														{{ s }}
													</mat-option>
												</ng-container>
											</mat-select>
										</mat-form-field>
									</div>
									<div class="col-6">
										<mat-form-field>
											<mat-label>Signature Requirement</mat-label>
											<mat-select formControlName="signature_option">
												<mat-option
														*ngFor="let s of ['carrier_default','signature_required','authority_to_leave']"
														[value]="s"
												>
														{{ 'Models.Company.Values.shipping_signature_pref.' + s | translate : { company: selectedCompany.business_name } }}
												</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
									<div class="col-6">
										<mat-form-field>
											<mat-label>Insurance</mat-label>
											<mat-select formControlName="insurance_option">
												<mat-option
														*ngFor="let s of ['carrier_default','insurance_no','insurance_yes','insurance_extra']"
														[value]="s"
												>
														{{ 'Models.Company.Values.shipping_insurance_pref.' + s | translate : { company: selectedCompany.business_name } }}
												</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
									<div class="col-12" *ngIf="record.shipment">
										<mat-form-field>
											<mat-label >Tracking Numbers</mat-label>
											<input matInput readonly="true">
										</mat-form-field>
									</div>
								</ng-container>
							</div>
						</ng-container>
						<ng-container *ngIf="focusOn == 'notes'">

							<div class="col-12">
								<h5>Order Processing Notes</h5>
								<p>The following notes are used to help staff process (or not) orders in bulk or automatically. Entering notes will <strong>prevent</strong> some automated processing steps.</p>
							</div>
							<div class="col-12">
								<mat-form-field>
									<mat-label>Customer Notes</mat-label>
									<textarea matInput formControlName="customer_notes"></textarea>
									<mat-hint>Notes entered here prevent this order from being selected in ANY bulk operation.</mat-hint>
								</mat-form-field>
							</div>
							<div class="col-12">
								<mat-form-field>
									<mat-label *ngIf="record?.membership_id == null">Product Preferences</mat-label>
									<mat-label *ngIf="record?.membership_id != null">Membership or Product Preferences</mat-label>
									<textarea matInput formControlName="product_notes"></textarea>
									<mat-hint>Notes entered here prevent this order from being confirmed in bulk, allowing staff to review order content first.</mat-hint>
								</mat-form-field>
							</div>
							<div class="col-12">
								<mat-form-field>
									<mat-label>Payment Notes</mat-label>
									<textarea matInput formControlName="payment_notes"></textarea>
									<mat-hint>Payment notes prevent this order from being paid automatically or in bulk.</mat-hint>
								</mat-form-field>
							</div>
							<div class="col-12" style="margin-top:-0.5rem;">
								<mat-checkbox #packagingCheck [checked]="form.get('packaging_notes').value" (change)="form.patchDirty({packaging_notes: $event.checked ? '...' : ''})" color="primary">
									Order should be wrapped
								</mat-checkbox>
								<mat-form-field *ngIf="packagingCheck.checked" class="mt-1">
									<mat-label>Packaging Notes</mat-label>
									<textarea matInput formControlName="packaging_notes"></textarea>
									<mat-hint>These notes prevent this order from being processed in bulk for packaging.</mat-hint>
								</mat-form-field>
							</div>
							<div class="col-12">
								<h5>Customer Excellence</h5>
								<p>These notes help provide a better customer experience by ensuring special requests are attended to. These however do not impact order processing.</p>
							</div>
							<div class="col-12">
								<mat-checkbox formControlName="is_gift_order" color="primary">
									This order is a gift
								</mat-checkbox>
								<mat-form-field class="mt-1">
									<mat-label *ngIf="form.get('is_gift_order').value">Gift Message</mat-label>
									<mat-label *ngIf="!form.get('is_gift_order').value">Personal Message</mat-label>
									<textarea matInput formControlName="personal_message"></textarea>
									<mat-hint>This messages are printed with the packing details to be inserted in the package.</mat-hint>
								</mat-form-field>
							</div>
							<div class="col-12">
								<mat-form-field>
									<mat-label>Staff Notes</mat-label>
									<textarea matInput formControlName="staff_notes"></textarea>
									<mat-hint>These notes are for internal use only and do not affect how this order is processed.</mat-hint>
								</mat-form-field>
							</div>

						</ng-container>

						<ng-container *ngIf="focusOn == 'tracking'">
							
							<div class="col-4">
								<mat-form-field>
									<mat-label>Created By</mat-label>
									<mat-select formControlName="created_by_id">
										<mat-option *ngFor="let user of selectedCompany.confirmedUsers" [value]="user.id" class="font-size-12">{{ user.toString() }}</mat-option>
									</mat-select>
									<mat-hint>On {{ moment(record.created_at).format(this.datetimeFull)}}</mat-hint>
								</mat-form-field>
							</div>
							<div class="col-4">
							<mat-form-field>
								<mat-label>Content Confirmed</mat-label>
								<mat-select formControlName="confirmed_by_id">
									<mat-option *ngFor="let user of selectedCompany.confirmedUsers" [value]="user.id" class="font-size-12">{{ user.toString() }}</mat-option>
								</mat-select>
								<mat-hint *ngIf="record.confirmed_at">On {{ moment(record.confirmed_at).format(this.datetimeFull)}}</mat-hint>
							</mat-form-field>
							</div>
							<div class="col-4">
							<mat-form-field>
								<mat-label>Payment Captured</mat-label>
								<mat-select formControlName="paid_by_id">
									<mat-option *ngFor="let user of selectedCompany.confirmedUsers" [value]="user.id" class="font-size-12">{{ user.toString() }}</mat-option>
								</mat-select>
								<mat-hint *ngIf="record.paid_at">On {{ moment(record.paid_at).format(this.datetimeFull)}}</mat-hint>
							</mat-form-field>
							</div>
							<div class="col-4" *ngIf="record.shipment">
							<mat-form-field>
								<mat-label>Shipping Quote</mat-label>
								<mat-select formControlName="shipment.quoted_by_id">
									<mat-option *ngFor="let user of selectedCompany.confirmedUsers" [value]="user.id" class="font-size-12">{{ user.toString() }}</mat-option>
								</mat-select>
								<mat-hint *ngIf="record.shipment.quoted_at">On {{ moment(record.shipment.quoted_at).format(this.datetimeFull)}}</mat-hint>
							</mat-form-field>
							</div>
							<div class="col-4">
							<mat-form-field>
								<mat-label>Packed</mat-label>
								<mat-select formControlName="packed_by_id">
									<mat-option *ngFor="let user of selectedCompany.confirmedUsers" [value]="user.id" class="font-size-12">{{ user.toString() }}</mat-option>
								</mat-select>
								<mat-hint *ngIf="record.packed_at">On {{ moment(record.packed_at).format(this.datetimeFull)}}</mat-hint>
							</mat-form-field>
							</div>
							<div class="col-4">
							<mat-form-field>
								<mat-label *ngIf="!record.shipment">Identification Label</mat-label>
								<mat-label *ngIf="record.shipment">Shipping Label</mat-label>
								<mat-select formControlName="labelled_by_id">
									<mat-option *ngFor="let user of selectedCompany.confirmedUsers" [value]="user.id" class="font-size-12">{{ user.toString() }}</mat-option>
								</mat-select>
								<mat-hint *ngIf="record.labelled_at">On {{ moment(record.labelled_at).format(this.datetimeFull)}}</mat-hint>
							</mat-form-field>
							</div>
							<div class="col-4">
							<mat-form-field>
								<mat-label *ngIf="!record.shipment">Completed By</mat-label>
								<mat-label *ngIf="record.shipment">Shipped By</mat-label>
								<mat-select formControlName="completed_by_id">
									<mat-option *ngFor="let user of selectedCompany.confirmedUsers" [value]="user.id" class="font-size-12">{{ user.toString() }}</mat-option>
								</mat-select>
								<mat-hint *ngIf="record.completed_at">On {{ moment(record.completed_at).format(this.datetimeFull)}}</mat-hint>
							</mat-form-field>
							</div>
							<div class="col-4" *ngIf="record.shipment">
							<mat-form-field>
								<mat-label>Marked as Delivered</mat-label>
								<mat-select formControlName="shipment.completed_by_id">
									<mat-option *ngFor="let user of selectedCompany.currentOrPreviousUsers(record.shipment.usersInvolved)" [value]="user.id" class="font-size-12">{{ user.toString() }}</mat-option>
								</mat-select>
								<mat-hint *ngIf="record.shipment.delivered_at">On {{ moment(record.shipment.delivered_at).format(this.datetimeFull)}}</mat-hint>
							</mat-form-field>
							</div>
							 
							<div class="col-12"><h5>Tagging</h5></div>
							<mat-form-field [class]="tagsForm.loading" [floatLabel]="tagsForm.get('tags').value.length ? 'always' : 'auto'">
								<mat-label>Assigned Tags</mat-label>
								<mat-chip-grid #tagChipList multiple>
								 <ng-container *ngFor="let tag of tagsForm.get('tags').value">
									 <mat-chip-option *ngVar="record.tagAsAssigned(tag) as ctag"
											 [ngClass]="{'chip-selected-customer':ctag.tagged_by_customer > 0}"
											 [selectable]="false"
											 [removable]="ctag.tagged_by_customer <= 0"
											 (removed)="removeTag(tag,'Remove segmentation tag?','Removing this tag ('+tag.toString()+') will immediately affect customer tracking and reporting. Information will be lost permanently.')">
										 <span matChipAvatar><i [class]="tag.getIcon()"></i></span>
										 {{ tag.toString() }}
										 <span matChipRemove *ngIf="ctag.tagged_by_customer <= 0">
											 <i matChipTrailingIcon *ngIf="!tagsForm._dirty['tags']?.includes(tag.id)" class="bx bxs-x-circle"></i>
											 <i matChipTrailingIcon *ngIf="tagsForm._dirty['tags']?.includes(tag.id)" class="bx bx-loader-alt"></i>
										 </span>
									 </mat-chip-option>
								 </ng-container>
							 </mat-chip-grid>
									 <input class="mt-1 mb-2" matInput #searchInput placeholder="Search Tags.." formControlName="search"
										  [matAutocomplete]="searchResult" [matChipInputFor]="tagChipList"
										  [matChipInputSeparatorKeyCodes]="[13, 188]" />
								<mat-autocomplete #searchResult="matAutocomplete" 
									 (optionSelected)="addTag($event.option.value)"
									 class="search rounded-dropdown {{ tagsForm.loading }}">
 
									 <div *ngIf="tagsForm.get('totalResults').value < 0 && ['loading','loaded'].includes(tagsForm.loading)" class="search-intro">
										 <div class="d-flex justify-content-center">
											 <div *ngFor="let scope of ['calendar','account','cart']" class="avatar-title h5 bg-secondary bg-soft rounded-circle">
												 <i class="mdi mdi-{{ scope }}"></i>
											 </div>
										 </div>
										 <h6>{{ 'SharedI18n.Widgets.SearchDropdownComponent.Scope.customer-tags' | translate }}</h6>
									 </div>
 
									 <div *ngIf="tagsForm.loading == 'loaded' && tagsForm.get('totalResults').value == 0" class="search-intro text-danger">
										 <div class="d-flex justify-content-center">
											 <div class="avatar-title h5 rounded-circle bg-danger bg-soft text-dark">
												 <i class="bx bx-x" style="font-size:24px;"></i>
											 </div>
										 </div>
										 <h6>No results found, try again.</h6>
									 </div>
 
									 <mat-option *ngFor="let tag of tagsForm.get('searchResults').value" [value]="tag" class="search-result icon-vertical-center">
										 <div class="avatar-xs text-center my-auto">
											 <i class="{{ tag.getIcon() }} {{ tag.getAccentColour('text') }}"></i>
										 </div>
										 <div>
											 <h6>{{ tag.toString() }} <span *ngIf="tag.category == 'custom'" class="badge bg-soft {{tag.getAccentColour(['bg','text'])}}">custom</span></h6>
											 <p>{{ 'Models.Tag.Values.category.' + tag.category + '_' + tag.usage | translate }}</p>
										 </div>
									 </mat-option>
 
									 <div class="{{ bsCssClassPrefix }}-footer">
 
										 <p *ngIf="tagsForm.loading == 'loaded' && tagsForm.get('totalResults').value <= 0"> Start typing to search.. </p>
										 <p *ngIf="tagsForm.loading == 'loaded' && tagsForm.get('totalResults').value > 0"> Showing {{ tagsForm.get('searchResults').value.length }} of {{ tagsForm.get('totalResults').value }} results</p>
										 <p *ngIf="tagsForm.loading == 'loading'"> Searching.. </p>
						 
									 </div>
 
									 <mat-option class="d-none"><!-- without an option present, the panel doesn't show up (instructions and footer are not clickable options) --></mat-option>
								</mat-autocomplete>
								<mat-hint>These tags may be synchronised with your chosen marketing platform and internal sales channels such as e-commerce & POS</mat-hint>
							</mat-form-field>

							<div class="col-12"><h5>Marketing Tracking</h5></div>
							<div class="col-6">
							<mat-form-field>
									<mat-label>Source</mat-label>
									<mat-select formControlName="track_source" sortable>
										<mat-option *ngFor="let source of [
											'point-of-sale',
											'manual-entry',
											'your-website',
											'email-marketing',
											'bulk-import',
											'referral', 'club-run',
											'third-party']" [value]="source">
											{{ 'Models.Trackable.Values.track_source.' + source | translate }}
										</mat-option>
									</mat-select>
									<mat-hint>Where this order comes from</mat-hint>
							</mat-form-field>
							</div>
							<div class="col-6">
								<mat-form-field>
										<mat-label>Channel</mat-label>
										<mat-select formControlName="track_channel">
											<mat-option *ngFor="let channel of [
												'onsite',
												'onsite-events',
												'offsite-events',
												'subscription',
												'ecommerce',
												'your-marketing',
												'their-marketing',
												'troly','api']" [value]="channel">
												{{ 'Models.Trackable.Values.track_channel.' + channel | translate }}
											</mat-option>
										</mat-select>
										<mat-hint>How was this purchase decision was triggered</mat-hint>
								</mat-form-field>
							</div>
							<div class="col-6">
								<mat-form-field>
									 <mat-label>Partner</mat-label>
									 <input matInput type="text" formControlName="track_partner" />
									 <mat-hint>Software or ecommerce platform that created this order</mat-hint>
								</mat-form-field>
							</div>
							<div class="col-6">
								<mat-form-field>
									 <mat-label>Marketing Campaign</mat-label>
									 <input matInput type="text" formControlName="track_campaign"/>
									 <mat-hint>Identifier captured via email or click-tracking</mat-hint>
								</mat-form-field>
							</div>

							<div class="col-12">
								<p>You do not require entering any values below as they are managed for you automatically.</p>
								<mat-checkbox (change)="toggleTrackingDetails($event.checked)" color="primary">
									Unlock tracking details
								</mat-checkbox>
							</div>

						</ng-container>

					</div>
					<div class="row" *ngIf="changesToMonitor.length > 0 && changesReceived.length > 0">
						<div class="col-12 pe-4">
							<ngb-alert type="info" [dismissible]="approvedChanges.length == 0" (closed)="dismissPersist($event)" class="mt-2 me-1">
								<h6>Persist changes made?</h6>
								<p class="mb-2">Changes made here only apply to the current order. Would you like for the following changes to also be applied to the customer record for future orders?</p>
								<ng-container *ngFor="let key of changesToMonitor">
									<mat-checkbox *ngIf="changesReceived.includes(key)" [checked]="approvedChanges.includes(key)" (click)="togglePermanentChange(key)">{{key}}: {{ form.get(key).value }}</mat-checkbox>
								</ng-container>
								<p class="mt-2" *ngIf="approvedChanges.length">Changes selected ({{approvedChanges.length}}) will be applied to this customer record immediately.</p>
							</ngb-alert>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="{{ bsCssClassPrefix }}-footer">
			<button type="submit" class="btn btn-primary {{ form.loading }}"
				[disabled]="form.loading != 'loaded' || form.invalid">
				Save
			</button>
		</div>
	</form>
</div>