import { ValidatorFn, Validators } from "@angular/forms";
import { TopbarNotification } from "../topbar.models";
import { CdnMountedImage, TrolyObject } from "../troly_object";
import { uuid } from "../utils.models";
import { Company } from "./company.model";
import { Document } from "./document.model";
import { StockStat } from "./stats.model";
import { ProductTag, Tag } from "./tag.model";
import { Warehouse } from "./warehouse.model";

export class Product extends TrolyObject {

	override _trolyPropertyArray = { prices: Array, awards: Array, product_warehouses: Array, subproducts: Array, variety_attributes: Object, bundle_content: Array }

	declare company_id: uuid;
	declare company?: Company;

	/**
	 * Product details
	 */
	declare name: string;
	_name: ValidatorFn[] = [Validators.required]
	declare short_name: string;
	declare variety: string;
	_variety: ValidatorFn[] = [Validators.required]
	declare tagline: string;
	declare description: string;
	declare category: string;
	declare category_public: string;

	declare number: string;

	declare barcode_ean: string;
	_barcode_ean: ValidatorFn[] = [Validators.pattern('^[0-9]{13,13}$')]
	declare barcode_upc: string;
	_barcode_upc: ValidatorFn[] = [Validators.pattern('^[0-9]{8}([0-9]{4})?$')]

	declare shop_qr_code: CdnMountedImage;
	declare shop_url_qr: string
	declare feedback_qr_code: CdnMountedImage;
	declare feedback_url_qr: string

	declare elabel_url_qr: string
	declare infosheet_url_qr: string;
	
	/**
	 * A list of generated documents for this product
	 */
	declare documents: Document[];
	
	/**
	 * This record will be 'trashed' on deletion, appear in the trash, and can be restored, or will forcefully be deleted after a period of time, or manually.
	 */
	declare trashed_at: Date
	declare trashed_by_id: uuid
	declare trashed_reason: string
	declare readonly:boolean;

	declare hero_video: string;

	declare batch: string;
	declare units_produced: number

	declare production_cost_unit: string;
	declare production_cost_batch: string;
	
	declare packaged_on: Date;

	declare hero_img: CdnMountedImage;
	declare hero_img_url: string;

	declare best_at: Date;
	declare available_after: Date;
	declare available_until: Date;

	/**
	 * Various  manually added
	 */
	declare notes_food_match: string;
	declare notes_tasting: string;
	declare notes_producer: string;
	declare notes_sourcing: string;
	declare notes_production: string;
	declare notes_preparation: string;
	declare notes_packaging: string;

	/**
	 * Redirections
	 */
	declare redirection_urls: { [name: string]: string }
	declare encapsulated_urls: { [name: string]: string|{} }

	declare feedback_url:string;
	declare feedback_url_override:string;
	declare shop_url:string;
	declare shop_url_override:string;
	declare elabel_url:string;
	declare elabel_url_override:string;
	declare infosheet_url:string;
	declare infosheet_url_override:string;

	/** 
	 * Pricing details
	 */
	declare price: number;
	_price: ValidatorFn[] = [Validators.required, Validators.min(0)]
	declare price_6pk: number;
	declare price_12pk: number;

	declare prices: ProductPrice[];

	declare bundle_qty:number // only every populated when part of bundle_details
	declare bundle_content:Product[]

	/**
	 * Shippping details
	 */
	declare weight: number;
	_weight: ValidatorFn[] = [Validators.required, Validators.min(0)]
	declare height: number;
	_height: ValidatorFn[] = [Validators.min(0)]
	declare depth: number;
	_depth: ValidatorFn[] = [Validators.min(0)]
	declare width: number;
	_width: ValidatorFn[] = [Validators.min(0)]

	/**
	 * Advanced variety-details
	 */
	declare variety_attributes: {
		final_ph: string,
		final_alcohol: string,
		final_sugar: string,
		final_acidity: string,
		brix: string
	}
	
	declare do_not_pack: string;	
	declare shipping_soft_product: boolean;
	
	declare fallback_id: uuid;
	declare fallback?: Product;

	declare subproducts: SubProduct[];
	declare tags?: Tag[];
	declare product_tags?: ProductTag[]; // excludes any category == product-config (used internally)

	declare product_warehouses?: ProductWarehouse[];
	declare stat?: StockStat;
	
	declare stats?: StockStat[];

	constructor(values?: Object) {
		super('product', values);

		if (values) {
			let k:string='product_warehouses'
			if (values[k] && values[k].length) {
				//this[k] = []
				values[k].map((obj,i) => { this[k][i] = new ProductWarehouse(obj) });
			}
			
			k='product_tags'
			if (values[k] && values[k].length) {
				values[k].map((obj,i) => { this[k][i] = new ProductTag(obj) });
			}
			k='tags'
			if (values[k] && values[k].length) {
				values[k].map((obj,i) => { this[k][i] = new Tag(obj) });
			}

			k='prices'
			if (values[k] && values[k].length) {
				values[k].map((obj,i) => { this[k][i] = new ProductPrice(obj) });
			}

			k='documents'
			if (values[k] && values[k].length) {
				values[k].map((obj,i) => { this[k][i] = new Document(obj) });
			}

			k='company'
			if (values[k]) { this[k] = new Company(values[k]); }

			if (values['hero_img_url'] && (!values['hero_img'] || !values['hero_img']['url'])) {
				this.hero_img = { url: values['hero_img_url'] }
			}
		}
			
	}

	public beforeSave(originalObject:Product): boolean {
		
		if (this.prices) {
			this.prices.forEach((p,i) => {
				// ensure that we are saving the full price object, not just a single property, as required by the API (these are virtual fields)
				this.prices[i] = Object.assign(originalObject.prices?.find(_ => _.id == p.id) || {}, p);
			})
		}
		return super.beforeSave(originalObject);
	}

	public toString() : string {
		return this.name;
	}

	public toNotification(notification?: Partial<TopbarNotification>): TopbarNotification {
		return super.toNotification(Object.assign({
			link: '/products/' + this.id,
			badge: this.variety
		}, notification)) as TopbarNotification
	}

	public tagBySlug(tag: string): Tag | null {
		return this.tags?.find(_ => _.slug == tag) || null
	}
	public tagAsAssigned(tag: Tag): ProductTag {
		return this.product_tags?.find(pt => pt.tag_id == tag.id) || {} as ProductTag;
	}

	/** used to filter which tags are displayed on the first / important row, versus the other ones */
	public tagsForRow(no: number): Tag[] {

		const row0 = ['custom','marketing']

		if (!this.tags && this.product_tags) {
			this.tags = this.product_tags?.map(_ => _.tag)
		}

		const tags = this.tags?.filter(_ => _.category != 'config') || []

		if (no == 0) {
			return tags.filter(_ => row0.includes(_.category))
		} else if (no == 1) {
			return tags.filter(_ => !row0.includes(_.category) && ['products-wine', 'products-beer', 'products'].includes(_.usage));
		} else if (no == 2) {
			return tags.filter(_ => !row0.includes(_.category) && ['customers', 'orders', 'companies'].includes(_.usage));
		} else {
			return []
		}
	}

	/**
	 * 
	 * @returns 
	 */
	public isReadOnly(): boolean {
		return this.trashed_at != null && this.trashed_at < new Date()
	}

	public getIcon(variety?): string | null {
		variety ||= this.variety
		switch (variety) {
			case 'wine': return 'mdi mdi-bottle-wine';
			case 'beer': return 'mdi mdi-glass-mug-variant';
			case 'drink_alc': return 'mdi mdi-cup';
			case 'drink': return 'mdi mdi-glass-cocktail-off';
			case 'art': return 'mdi mdi-image-frame';
			case 'event': return 'mdi mdi-ticket';
			case 'apparel': return 'mdi mdi-tshirt-crew';
			case 'spirit': return 'mdi mdi-glass-cocktail';
			case 'produce': return 'mdi mdi-food-apple';
			case 'beauty': return 'mdi mdi-lipstick';
			case 'meat': return 'mdi mdi-sheep';
			case 'meat_alt': return 'mdi mdi-sprout';
			case 'food': return 'mdi mdi-food-fork-drink';
			case 'goods': return 'mdi mdi-lamp';
			case 'virtual': return 'mdi mdi-cloud-download-outline';
			case 'bundle': return 'mdi mdi-package-variant-closed';
		}
	}

	get cheapestPrice(): number {
		let prices:number[] = [this.price, this.price_6pk, this.price_12pk]
		if (this.prices) {
			Object.keys(this.prices).forEach(p => prices += this.prices[p].price)
		}
		return Math.min(...prices)
	}

	public relativeDiscount(compareTo: number, decimals:number=0):number {
		const discount = (this.price - compareTo) / this.price * 100
		return Number(Number(discount).toFixed(decimals)) * -1
	}
	

	getStockForWarehouse(warehouse_id): ProductWarehouse {
		return (this.product_warehouses ? this.product_warehouses.find(_ => _.warehouse_id == warehouse_id) || {} : {}) as ProductWarehouse
	}

	getTotalStockAvailability(warehouse_id?): number {
		return (this.getTotalUnitsAvailable(warehouse_id) || 0) / (this.units_produced || 1) * 100;
	}

	_getTotalUnitsAvailable = {};
	getTotalUnitsAvailable(warehouse_id?: uuid): number {
		warehouse_id ||= 'all'
		if (this._getTotalUnitsAvailable[warehouse_id.toString()]) { return this._getTotalUnitsAvailable[warehouse_id.toString()]; }

		let units_available = -1;
		this.product_warehouses?.map((_) => {
			if (warehouse_id == 'all' || warehouse_id == _.warehouse_id) {
				units_available += _.units_available;
			}
		});

		if (units_available > 0) {
			this._getTotalUnitsAvailable[warehouse_id.toString()] = units_available + 1;
		}
		return units_available;
	}

	public isPrePackaged(): boolean | null {
		return this.variety != 'bundle' ? null : parseFloat(this.units_produced?.toString()) != 0
	}

	public priceForClub(club_id:uuid, qty:number): ProductPrice {
		return this.prices.filter(_ => _.id.toString() == club_id.toString()).find(_ => (qty == 1 && _.min_qty == null && _.max_qty == null) || (_.min_qty == qty && _.max_qty == qty))
	}

	get infosheet_doc(): Document { return this.getDocumentByIdent('infosheet_doc') }
	get infosheet_qr_code(): Document { return this.getDocumentByIdent('infosheet_qr_code') }
	get elabel_qr_code(): Document { return this.getDocumentByIdent('elabel_qr_code') }
	get feedback_doc(): Document { return this.getDocumentByIdent('feedback_doc') }

	protected getDocumentByIdent(ident:string): Document { return this.documents?.find(_ => _.ident == ident); }

}

export interface SubProduct extends TrolyObject {
	product_id:uuid;
	parent_id:uuid;
	quantity:number;
	product:Product;
}

export class ProductPrice extends TrolyObject {
	declare club_id:uuid
	declare value: number
	declare min_qty: number
	declare max_qty: number
	declare name: string
	declare internal_notes: string

	constructor(values?:{}) {
		super('product_price', values)
	}
}

export class ProductWarehouse extends TrolyObject {

	declare company_id:uuid;
	declare warehouse_id:uuid;
	declare product_id:uuid;
	declare units_available:number;
	declare units_stocked:number;
	declare units_reserved:number;
	declare units_in_transit:number;

	declare warehouses:Warehouse[];
	declare product_warehouses:ProductWarehouse[];

	constructor(values?: Object) {
		super('product', values);

		if (this.units_available) {
			this.units_available = Number(Number(this.units_available).toFixed(2)) // removes the last trailing decimal 0 if meaningless (566.0 => 566, and 599.44 => 599.44)
		}

		if (values) {
			let k = 'product_warehouses'

			if (values[k] && values[k].length) {
				values[k].map((obj,i) => { this[k][i] = new ProductWarehouse(obj) });
			}
		}
	}
}