import { inject } from "@angular/core";
import { Observable, catchError, filter, map, of, tap } from "rxjs";
import { TrolySearch } from "src/app/core/models/form_objects";
import { CompanyTag, CustomerTag, OrderTag, ProductTag, Tag } from "src/app/core/models/troly/tag.model";
import { TrolyFormGroup } from "src/app/core/models/troly_form";
import { TrolyObject } from "src/app/core/models/troly_object";
import { uuid } from "src/app/core/models/utils.models";
import { TagService } from "../tag.service";
import { TrolyService } from "../troly.service";

export class TaggableModule {

	/**
	 * The name or identifier of the current class, not otherwise available when running in "production mode". 
	 * It is used to output debugging information on the console, and also attached to translations of labels, product tours, etc
	 */
	public readonly __name: string = 'TaggableModule';

	service: ITaggableService<CompanyTag|OrderTag|CustomerTag|ProductTag>;
	protected tagService = inject(TagService)

	constructor(service: ITaggableService<CompanyTag|OrderTag|CustomerTag|ProductTag>) {
		this.service = service;
	}

	public searchTags(term, obj?:TrolyObject, params?: {}): Observable<TrolySearch<Tag>> {
		
		params ||= {}
		params['page'] ||= 1
		params['limit'] ||= 15
		params['search'] = term;

		return this.service.searchTags(params, obj)
		
	}
  
	/**
	 * Used internally to remove a record from a collection -- might need refactoring to TrolyService, or GenericService?
	 * @param collection 
	 * @param element 
	 * @returns 
	 */
	protected removeRecord(collection: any[], element: any): any {

		collection = collection || []
		if (element) {
			let index: number = collection.indexOf(element)
			if (index >= 0) {
				return collection.splice(index, 1)[0]; // removing 1, returning the only one removed.
			}
		}
		return null;

	}

	/**
	 * Initialises a form to handle adding/removing tags, including tracking of dirty tags.
	 * ! This code really should be in a Directive or some reusable UI-orientated component. do not use as example to replicate throughout the codebase. See `toggleTag` below.
	 * @param form
	 * @param attr
	 * @returns 
	 */
	public initToggleTag(form: TrolyFormGroup, attr: string): TrolyFormGroup {

		if (!form._assigned[attr]) { form._assigned[attr] = []; }

		// prepare the form with a _dirty container
		if (!form._dirty[attr]) { form._dirty[attr] = []; }

		form.get(attr).valueChanges.pipe(filter(_ => _)).subscribe((_tags: Tag[]) => {
			// maintain a list of assigned IDs
			form._assigned[attr] = _tags.map(_ => _.id);
		});

		let initAttr = {}; initAttr[attr] = [];
		form.patchValue(initAttr);

		return form;
	}

	/**
	 * Allows to assign/unassign/toggle a tag relationship with any of Customer|Product|Company|Order.
	 * @param service 
	 * @param form 
	 * @param attr 
	 * @param tag 
	 * @param action 
	 */
	public toggleTag<U>(form: TrolyFormGroup, attr: string, tag: Tag, record?:CompanyTag|OrderTag|CustomerTag|ProductTag, action?: 'add'|'remove'): Observable<CompanyTag|OrderTag|CustomerTag|ProductTag> {

		let res:Observable<CompanyTag|OrderTag|CustomerTag|ProductTag>;

		// Starting - this tag is 'dirty' until success or error
		form._dirty[attr].push(tag.id);

		// find out whether we have the tag currently in the list
		let position: number = (form.get(attr).value).map(_ => _.valueOf()).indexOf(tag.valueOf()) 						// looking for number in a list of numbers
		
		if (!action) action = position < 0 ? 'add' : 'remove'

		// if the presence and action match, then add // remove as required
		if (action == 'add' && position < 0) {
			let patch = form.get(attr).value || [];
			patch.push(tag); // doesn't matter if tag is numeric or object
			form.patchValue(this.service.make({ [attr]: patch })); // given a new object is made, virtual attributes are hereby updated too.. like 'editable tags'
		} else if (action == 'remove' && position >= 0) {
			let patch = form.get(attr).value || [];
			patch.splice(position, 1);
			form.patchValue(this.service.make({ [attr]: patch }));
		}
		
		// let's push to the api if needed
		if (action == 'add' && position < 0 || action == 'remove' && position >= 0) {

			let payload: TrolyObject = this.service.make({ id: form.get('id').value });

			let verb = (action == 'add' ? 'post' : 'delete')
			
			form._dirty[attr].push(tag.id);

			res = this.service.updateTag(verb, tag.id, record).pipe(
				tap((_result) => { form._dirty[attr] = form._dirty[attr].filter(_ => _ != tag.id) }),
				map((_result) => { 
					this.removeRecord(form._dirty[attr], _result.tag_id);
					return _result; 
				}),
				catchError((error, observable) => {
					
					// in case of server error, revert the operation
					let patch = form.get(attr).value || [];
					if (action == 'remove') { patch.push(tag); }
					else { patch.splice(position, 1); }

					form.savingError(attr);
					//form.patchValue(this.service.make({ [attr]: patch }));
					form.reset();
					this.removeRecord(form._dirty[attr], tag.id);
					return of(null)
				})
			);

		} else {
			// This is much more of a "be nice" to developers given that 
			// the UI should never toggle-add a tag that already exists, and likewise, remove.
			res = new Observable((observer) => { observer.complete(); });
		}

		return res;
	}

	public toggle<U>(form: TrolyFormGroup, attr: string, tag: Tag, params?:{}){
		
		params['tag_id'] = tag.id;

		this.toggleTag<U>(form, attr, tag, this.service.makeObjectTag(params)).subscribe();
	}
	
}


/**
 * 
 */
export interface ITaggableService<T extends TrolyObject> extends TrolyService<any> {
	/**
	 * 
	 */
	updateTag(verb, id:uuid, obj?:T, record?:TrolyObject): Observable<T>


	searchTags(params?: {}, record?: TrolyObject): Observable<TrolySearch<Tag>>

	makeObjectTag(params: {}): T
}