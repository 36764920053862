
<div class="row">
	<div class="col-6 text-end pe-4" [style.color]="'#' + this.happy_fg">
		<img 
			(click)="toggleValue('happy')"
			[class]="{'selected': _value == 'happy', 'unselected': _value == 'unhappy' }"
			[src]="'https://res.cloudinary.com/troly/image/upload/cdn/app/static/widgets/happy-or-not/happy.svg' | cloudinary : happyTransformations"
			[style.background-color]="'#' + this.happy_bg + '66'"
			[style.width]="this.size + 'px'"
		/>
	</div>
	<div class="col-6 ps-4">
		<img 
			(click)="toggleValue('unhappy')"
			[class]="{'selected': _value == 'unhappy', 'unselected': _value == 'happy' }"
			[src]="'https://res.cloudinary.com/troly/image/upload/cdn/app/static/widgets/happy-or-not/not-happy.svg' | cloudinary : unhappyTransformations"
			[style.background-color]="'#' + this.unhappy_bg + '66'"
			[style.width]="this.size + 'px'"
		/>
	</div>
</div>