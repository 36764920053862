import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Injectable()
// @Injectable({
//     providedIn: 'root',
// })

// This file allows for single state management of the sidebar
// that contains the help iFrame and chat widget
export class SidenavService {
    private sidenav: MatSidenav;
    public status:string = 'opened';

    public setSidenav(sidenav: MatSidenav) {
        this.sidenav = sidenav;
    }

    public open() {
        this.status = 'opened';
        return this.sidenav.open();
    }

    public close() {
        this.status = 'closed';
        return this.sidenav.close();
    }

    public toggle(): void {
        this.status = (this.status == 'closed' ? 'opened' : 'closed');
        this.sidenav.toggle();
    }
}
