import { ChangeDetectorRef, Directive, EventEmitter, HostBinding, HostListener, Input, Output, inject } from '@angular/core';

export type SortColumn = string;
export type SortDirection = 'asc' | 'desc' | '';

const rotate: { [key: string]: SortDirection } = { '': 'asc', asc: 'desc', desc: '' };

export interface SortEvent {
    column: SortColumn;
    direction: SortDirection;
}

@Directive({
    selector: 'th[sortable]'
})
export class SortableDirective {
    
	@Input() sortable: SortColumn = '';
	isDefaultSortColumn: boolean = false;

	@Output() sort = new EventEmitter<SortEvent>();

	@HostBinding('class.asc') sortAsc :boolean = false
	@HostBinding('class.desc') sortDesc :boolean = false 
	
	@HostListener('click') onClick() {
		const direction = this.sortAsc ? 'desc' : (this.sortDesc && !this.isDefaultSortColumn ? '' : 'asc')
		this.sort.emit({ column: this.sortable, direction: direction } as SortEvent);
	}

	protected cd: ChangeDetectorRef = inject(ChangeDetectorRef);

	public setSort(sort:string, defaultSort:string = '') {
		const [column, direction] = sort.split(',');
		this.sortAsc = this.sortable == column.trim() && direction.trim() == 'asc';
		this.sortDesc = this.sortable == column.trim() && direction.trim() == 'desc';
		this.cd.detectChanges();

		if (defaultSort) {
			const [defaultColumn, defaultDirection] = defaultSort.split(',');
			this.isDefaultSortColumn = this.sortable == defaultColumn.trim();
		}
	}

}
