import { Component, OnInit, inject } from '@angular/core';
import { filter, takeUntil } from 'rxjs';
import { RootComponent } from '../core/components/root.component';
import { CompanyService } from '../core/services/troly/company.service';
import { UserService } from '../core/services/troly/user.service';
// Even though this is called 'Component' we declare as a directive
// as that is the requirement for base class components in Angular 9+
@Component({
	template: ''
})

// Base component that enables a single method of destroying observable
// subscriptions when a component is destroyed
export abstract class BaseLayout extends RootComponent implements OnInit {


	protected userService: UserService = inject(UserService);
	protected companyService: CompanyService = inject(CompanyService);

	constructor() {
		super();

		const companyUpdate = this.companyService.record$.pipe(takeUntil(this.observablesDestroy$),filter(_ => !this.selectedCompany?.id || _.sameAs(this.selectedCompany)))
		const userUpdate = this.userService.record$.pipe(takeUntil(this.observablesDestroy$),filter(_ => !this.authenticatedUser?.id || _.sameAs(this.authenticatedUser)))

		companyUpdate.subscribe((_) => { this.selectedCompany = _; });
		userUpdate.subscribe((_) => { this.authenticatedUser = _; });
		
	}

	ngOnInit() {

		// this serves to apply the actual darkmode attribute to the body
		this.userService.uiNotifier$.subscribe((_db) => {
			if (_db[this.CACHE_KEYS.DARK_MODE] !== undefined) {
				if (_db[this.CACHE_KEYS.DARK_MODE] == 'auto') {
					let event = window.matchMedia('(prefers-color-scheme: dark)')
					document.body.setAttribute('data-sidebar', event.matches ? 'dark' : 'light');
				} else {
					document.body.setAttribute('data-sidebar', _db[this.CACHE_KEYS.DARK_MODE] == 'on' ? 'dark' : 'light');
				}
			}
		})

		
		//protected zone: NgZone = inject(NgZone);
		//this.zone.runOutsideAngular(() => {

		// listen to system darkmode setting being applied and "apply it" ourselves if needed
		window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
			if (this.userService.cachedUiKey<'on' | 'off' | 'auto'>(this.CACHE_KEYS.DARK_MODE, 'auto') == 'auto') {
				this.darkMode = 'auto'; // we specify to "stay" in auto mode, so that the value gets rewrite, and the nofifier causes the logic (subscribed -- above) to be retriggered
			}
		});

		//})

		this.darkMode = this.userService.cachedUiKey<'on' | 'off' | 'auto'>(this.CACHE_KEYS.DARK_MODE, 'auto');
	}

	/**
 	* Set/toggles the darkmode to the next status, or to a defined value -- called externally too // from the top bar navigation menu.
 	* @param value 
 	*/
	public set darkMode(value: null | 'on' | 'off' | 'auto') {
		value = value || (this.darkMode == 'auto' ? 'on' : (this.darkMode == 'on' ? 'off' : 'auto'))
		this.userService?.storeUiKey<'on' | 'off' | 'auto'>(this.CACHE_KEYS.DARK_MODE, value);
	}
}