import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { TrolyObject } from 'src/app/core/models/troly_object';
import { TrolyApi } from './troly_api.service';
import { WindowService } from './window.service';

/**
 * 
 */
@Injectable()
export abstract class GenericService<T extends TrolyObject> extends TrolyApi {

	/**
	 * Loaded debug level required for the current service. 
	 * Stored in localStorage, this allows to enable/disable additional console.logging for the purpose of troubleshooting
	 */
	protected _debug: string[] = [];

	/**
	 * The name or identifier of the current class, not otherwise available when running in "production mode". 
	 * It is used to output debugging information on the console, and also attached to translations of labels, product tours, etc
	 */
	public readonly __name:string = 'GenericService';

	/**
	 * Name of the Troly API endpoint this service connects to.
	 */
	public readonly endpoint: string;

	/**
	 * Observable used to store a record loaded using the `._find` method. 
	 * When using `.find()` a record is loaded whereas using `._find()` the record is loaded AND subscribers notified.
	 * Only a single record can be loaded and notifed at once. Calling the `._next()` function ensures ONLY the loaded record is updated/notified
	 */
	public record$: BehaviorSubject<T> = new BehaviorSubject<T>(null);

	/**
	 * Observable used to store multiple records loaded using the `._list()` method
	 */
	public records$: BehaviorSubject<T[]> = new BehaviorSubject<T[]>(null);

	/**
	 * Internal connection quality indicator. This is used to track how quickly each API call is executed.
	 * Used for debugging purposes. 
	 * Given many companies (in remote areas) have average internet bandwith, this could be used in the future to show users
	 * their experience in Troly maybe degraded.
	 */
	private responseTime = {
		count: 0,
		average: 0,
		total: 0
	};

	/**
	 * Shortcut to settings for not emitting event notifications to reactive forms
	 */
	protected readonly NO_EMIT = { emitEvent: false };

	/**
	 * 
	 */
	public windowService: WindowService = inject(WindowService);

	/**
	 * 
	 * @param http 
	 * @param windowService 
	 */
	constructor(endpoint:string) {
		super();
		this.endpoint = endpoint;
		this._debug = window.localStorage.getItem('de')?.split('|').filter(_ => _) || [];
	}

	/**
	 * Interfaces with the UI (topbar) to provide user status messages regarding changes being saved.
	 * @param message {string} test
	 */
	public status(message?: string): void {
		if (!message) {
			this.windowService.saved();
		} else {
			this.windowService.saving(message);

		}
	}

	/**
	 * 
	 * @param message 
	 * @param status 
	 */
	public snack(message?: string, status?: 'success'|'error'|'warning'|'none') {
		status = status || 'success'
		if (message && message != '') {
			this.windowService.openSnackBar(message, status);
		}
	}

	/**
	 * 
	 * @param message 
	 */
	public log(message: string, level: string = '-') {
		if (this._debug.includes(level) || this._debug.includes('ALL')) {
			//this.log(`(${this.endpoint}) ${message}`);
		}
	}

	/**
	 * 
	 * @param operation 
	 * @param result 
	 * @returns 
	 */
	protected serviceError<T>(error: HttpErrorResponse, caught: Observable<any>): Observable<HttpResponse<T>> {

		console.log('serviceError', error.status);
		// TODO: send the error to remote logging infrastructure
		//console.error(error.message); // log to console instead
		//console.error(error.error); // log to console instead

		// TODO: better job of transforming error for user consumption
		//this.log(`${operation} failed: ${error.message} [${result}]`);

		// Let the app keep running by returning an empty result.
		//return of(error as T);

		// throw the error so that the individual services can handle them
		throw error;
	}

	/**
	 * 
	 */
	public trolyConnection: 'unknown' | 'none' | 'bad' | 'good' | 'great' = 'unknown';

	/**
	 * 
	 * @param delta 
	 */
	protected checkConnectionQuality(delta) {

		this.responseTime.count += 1;
		this.responseTime.total += delta;
		this.responseTime.average = this.responseTime.total / this.responseTime.count;

		delta = this.responseTime.average;

		if (delta < 1000) { this.trolyConnection = 'great'; }
		else if (delta < 2000) { this.trolyConnection = 'good'; }
		else if (delta < 5000) { this.trolyConnection = 'bad'; }
		else { this.trolyConnection = 'none'; }

		this.log(`average response time over ${this.responseTime.count} requests: ${this.responseTime.average} (${this.trolyConnection})`);
	}

	/**
	 * 
	 * @param id 
	 * @param filter 
	 * @param headers
	 * @returns An observable of the HttpResponse<T>
	 */
	protected get(payload?: TrolyObject, method?:string, params?: {}, headers?: {}): Observable<HttpResponse<T>> {
	//protected get(id: uuid, filter?: {}, headers?: {}): Observable<HttpResponse<T>> {
		let start = performance.now();
		return this.call<T>('get', payload, method, params, headers).pipe(
			tap((_) => {
				this.log(`get id=${payload?.id}`);
				this.checkConnectionQuality(performance.now() - start);
			}),
			catchError(this.serviceError<T>)
		);
	}

	/**
	 * 
	 * @param name 
	 */
	public removeRecordAttributeForRefresh(name:string) {
		let record = this.record$.value
		delete record[name]
		this.record$.next(record)
	}

	/**
	 * 
	 * @param fncName 
	 * @param attr 
	 * @param params 
	 * @param force 
	 * @returns 
	 */
	protected loadAttribute<U extends TrolyObject>(fncName: any, attr: string, params?: {}, force?: boolean): Observable<U[]> {
		if (!this.record$ || this.record$.value == null) { throw "load Function called outside of subscribeRecord" }
		let obj = {}
		obj[attr] = this.record$.value.id
		return fncName(obj, params, force)
	}

	/**
	 * 
	 * @param payload 
	 * @returns 
	 */
	protected post(payload: TrolyObject, method?:string, params?: {}, headers?: {}): Observable<HttpResponse<T>> {

		return this.call<T>('post', payload, method, params, headers).pipe(
			tap((_) => {
				this.log(`> post ${payload}`);
			}),
			catchError(this.serviceError<T>)
		);
	}

	/**
	 * 
	 * @param payload 
	 * @returns 
	 */
	protected put(payload: TrolyObject, method?:string, params?: {}, headers?: {}): Observable<HttpResponse<TrolyObject>> {

		this.windowService.saving();
		return this.call<T>('put', payload, method, params, headers).pipe(
			tap((_) => {
				this.log(`> put ${payload}`);
				this.windowService.saved()
			}),
			catchError(this.serviceError<T>)
		);
	}

	/**
	 * 
	 * @param payload 
	 * @returns 
	 */
	protected delete(payload: TrolyObject, method?:string, params?: {}, headers?: {}): Observable<HttpResponse<TrolyObject>> {

		this.windowService.saving();
		return this.call<T>('delete', payload, method, params, headers).pipe(
			tap((_) => {
				this.log(`> delete ${payload}`);
				this.windowService.saved()
			}),
			catchError(this.serviceError<T>)
		);
	}

	/**
	 * Makes a 'almost raw' http call. In most cases, calling the service
	 * @param verb 
	 * @param obj 
	 * @param method 
	 * @param endpoint 
	 * @param params 
	 * @returns 
	 */
	protected call<U>(verb: string, obj?: TrolyObject, method?: string, params: {}={}, headers: {}={}): Observable<HttpResponse<U>> {

		let url = `/${this.endpoint}`;
		if ((verb != 'post' || method) && obj?._trolyModelName == this.make()._trolyModelName) { // note:when a method is present this means we are POSTING /customers/123/method
			// making sure we are building the correct url for /endpoint/id/method, the id could be located 
			// in either the object itself, or may have already been "rails-prepared" and be in a `{ user: {id:123} }` format
			// note -- the rails-preparation normally happens in toHttpBody if it hasn't been done before calling.
			url += (obj.id != undefined ? `/${obj.id}` : (obj._trolyModelName && Object.keys(obj).includes(obj._trolyModelName) && obj[obj._trolyModelName].id ? `/${obj[obj._trolyModelName].id}` : ''));
			// if the ID was previously set, it's now been used for url and doesn't need to be sent.
			// this avoids the double-ups such as /companies/3.json?id=3
			if (obj.id != null) { delete obj.id; }
			if (obj._trolyModelName && obj[obj._trolyModelName]?.id) { delete obj[obj._trolyModelName].id }
		}

		url += (method ? `${method.startsWith('/') ? '' : '/'}${method}` : '') + '.json';

		let body = {};

		if (obj != null) {
			if (verb != 'delete') { // body and url parameters only supported on POST/PUT/GET requests.
				if (verb != 'get') {
					body = obj.toHttpBody();
				}
				if (!params) {
					params = obj.toHttpParams(verb);
				}
			}
		}

		// the API supports receiving arrays of values by using the `<name>[]` syntax as parameter, and including multiple
		// if the value received is an array, we enforce the attr name to be using that syntax, if not already
		Object.keys(params).filter(k => Array.isArray(params[k]) && k.indexOf('[]') < 0).forEach((key) => { 
			params[key+'[]'] = params[key];
			delete params[key];
		}); 
		return super.call<U>(verb, url, params, body, headers).pipe(
			tap((_) => { this.log(`${url} retrieved ${obj}`)}),
			//catchError(this.serviceError<U>)
		);
	}

	/**
	 * Helper method helping to abstract the object type being handled by the Service. Given `someFunction<T>  ()`, this is a workaround to the angular limitation where `new T()` cannot be called.
	 * 
	 * This function is normally overriden by the Service class that `extends TrolyService`
	 * @param payload the object attributes
	 * @returns T as a TrolyObject, and as further defined by extending classes.
	 */
	public make(payload: {} = {}): T {  throw `${this.__name}.make not implemented (and must be!)` }

	/**
	 * Enables retrieving of records from localStorage, and update data stored as changes are retrieved. 
	 * @param key localStorage storage key: should be unique based on any filters applied (eg. `pos-products-list-active`)
	 * @returns 
	 */
	public set recordsCacheKey(key: string) {

		this._recordsCacheKey = key;
		if (this.cachedDbKey(key)) {
			// loading the initial records from cache, if any.
			this.records$.next(this.cachedDbKey<T[]>(key, []).map((_) => this.make(_)));
		}

		this.records$.pipe(filter(_ => !!_)).subscribe((_) => {
			this.storeDbKey(key, _, 72);
		});
	}
	protected _recordsCacheKey: string

	/**
	 * initObservers allows us having multiple calls to the same initWhatever method, and yet, make a single call to the api, caching the result, 
	 * and when appropriate, make a new/second call to the API.
	 */
	//protected initObservers: { [key: string]: Subject<any> } = {}; // key-indexed list of Subjects for the init<Methods> calls.
	public initObservers: { [key: string]: BehaviorSubject<any> } = {}; // key-indexed list of BehaviorSubjects for the init<Methods> calls.

	/**
	 * Initializes an observer for a unique request, allowing to subscribe and resubscribe to the same observer from various locations.
	 * @param key unique key for this observer
	 * @param force always require a new observer to be created iresspective of previous existence
	 * @returns whether or not an observer was initialised for this key (false if already existed)
	 */
	protected onInitOrForce(key: string, force: boolean=false): boolean {

		if (this.initObservers[key] == null) {
			//this.initObservers[key] = new Subject<any>();
			// We cannot cache call using Subjects becahse BehaviourSubject returns previous values (retrieved) on subscription
			// whereas Subjects do not. This allows multiple page parts to be calling on the same information without multiple calls being made.
			// This requires however for the data to "expire" at some stage 
			this.initObservers[key] = new BehaviorSubject<any>(null);

			this.initObservers[key].subscribe({
				error: (err) => { delete this.initObservers[key]; },
				complete: () => { delete this.initObservers[key]; },
			})

			return true;
		} else if (this.initObservers[key]['__reset_please']) {
			this.initObservers[key]['__reset_please'] = false
			return true
		}

		return force === true;
	}


	public get recordDistinct$(): Observable<T> {
		return this.record$.pipe(
			filter(_ => !!_),
			distinctUntilChanged((a:T, b:T) => a.sameAs(b))		// Only received different records (ie, not the same as the previous one)
		);
	}
}
