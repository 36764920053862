import { Injectable } from '@angular/core';
import { Cloudinary } from '@cloudinary/url-gen';
import { thumbnail } from '@cloudinary/url-gen/actions/resize';
import { FileUploaderOptions } from 'ng2-file-upload';

import * as CryptoJS from "crypto-js";
import * as moment from 'moment-timezone';

import { environment } from 'src/environment/environment';

@Injectable({
	providedIn: 'root'
})
export class CloudinaryService {


	/**
	 * The name or identifier of the current class, not otherwise available when running in "production mode". 
	 * It is used to output debugging information on the console, and also attached to translations of labels, product tours, etc
	 */
	public readonly __name: string = 'CloudinaryService';

	cloudName: string = environment.CLOUDINARY_URL.match(/:\/\/(.*):(.*?)@(.*)/)[3];
	cld = new Cloudinary({
		cloud: {
			cloudName: this.cloudName,
			apiKey: environment.CLOUDINARY_URL.match(/\/\/(\d*):/)[1],
			apiSecret: environment.CLOUDINARY_URL.match(/:\/\/(.*):(.*?)@(.*)/)[2]
		}
	});

	constructor() { }

	getUploaderOptions(allowedMimeTypes: string): FileUploaderOptions {

		return {
			url: `https://api.cloudinary.com/v1_1/${this.cloudName}/upload`,
			allowedMimeType: allowedMimeTypes.split(','),
			autoUpload: true,
			isHTML5: true,
			removeAfterUpload: true,
			headers: [
				{
					name: 'X-Requested-With',
					value: 'XMLHttpRequest',
				},
			],
		}
	};

	/**
	 * Remove file extension to get image publicId
	 * @param path - Image path in Cloudinary with appended file extension
	 * @returns 
	 */
	getImagePublicIdFromPath(path: string):string {

		path = path.replace(/https?:\/\/res.cloudinary.com\/(.*)\/image\/upload\//, "")
		return path.split('.')[0]

	}

	/**
	 * 
	 * @param imagePath Image path of Temp image Blob being uploaded
	 * @param width Thumbnail width
	 * @param fallbackImage Id of the fallback image 
	 * 		app/companies/000000002/vgeoncuuzj4sclq1xltg.jpg
	 * 		cdn/assets/000000-0
	 * @returns 
	 */
	transformImage(imagePath?: any, width: number = 200, height: number = 200): string {

		//, fallbackImage = "cdn/assets/000000-0"

		if (typeof imagePath == 'string') { 

			// normal cloudinary urls are http://res.cloudinary.com/subscribility-d/image/upload/v1671611126/app/companies/000000002/xmmoj1prdvxsojvk8t9i.png
			// transformations are http://res.cloudinary.com/subscribility-d/image/upload/w_100/app/companies/000000002/xmmoj1prdvxsojvk8t9i.png

			imagePath = this.getImagePublicIdFromPath(imagePath);

			//'c_fill,g_auto,w_400,ar_6:4'
			return this.cld.image(imagePath).resize(thumbnail().width(width).height(height).aspectRatio("6:4")).toURL();
		} else {
			return imagePath;
		}
	}

	// as per https://cloudinary.com/documentation/authentication_signatures
	// it seems only backend frameworks have a util to generate the signature?
	generateSignature(params: {}): { hash:string, apiKey: string } {
		
		params['timestamp'] ||= Date.now()
		
		const nowGMT = moment.tz(params['timestamp'], 'GMT')
		params['tags'] ||= ['troly-app']
		params['tags'] = params['tags'].concat( nowGMT.format('dddd'), nowGMT.format('MMMM'), 'Week_'+nowGMT.week(), 'Hour_'+nowGMT.format('HH') );

		const sortedParams = Object.entries(params).sort().map(([key, value]) => `${key}=${value}`).join("&");
		
		const message = sortedParams + this.cld.getConfig().cloud.apiSecret;
		
		return  { hash: CryptoJS.SHA256(message).toString(), apiKey: this.cld.getConfig().cloud.apiKey };
	}
			
}
