import { AfterContentInit, Component, ElementRef, Input, OnInit } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { TrolyComponent } from 'src/app/core/components/troly.component';
import { IntrojsService } from 'src/app/core/services/introjs.service';

@Component({
    selector: 'help',
    templateUrl: './help-pellet.component.html',
    styleUrls: ['./help-pellet.component.scss'],
})
export class HelpPelletComponent extends TrolyComponent implements OnInit, AfterContentInit {
    
    @Input() collection!:string;
    @Input() position:string = "left";
    @Input() pellet:string;

    public hint:string;
    public __toggle:boolean = false;
    protected parentElement;
    
    constructor(
        private introjsService: IntrojsService,
        private elRef: ElementRef,
        ) {
            super();
    }

    ngOnInit(): void {
        this.parentElement = this.elRef.nativeElement.parentElement;
    }

    ngAfterContentInit() {
        if (this.parentElement.id || this.pellet) {
            this.introjsService.loadPage(this.collection).pipe(filter(_ => !!_), take(1)).subscribe((_data) => {
                
                if (_data[this.collection]?.help) {
                    if (this.pellet && _data[this.collection].help[this.pellet]) {
                        this.hint = _data[this.collection].help[this.pellet];
                    } else if (_data[this.collection].help[this.parentElement.id]) {
                        this.hint = _data[this.collection].help[this.parentElement.id];
                    }
                }
            });
        }
    }

}
