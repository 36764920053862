import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { TrolyObject } from '../models/troly_object';
import { TrolyService } from '../services/troly/troly.service';
import { RootComponent } from './root.component';

// Even though this is called 'Component' we declare as a directive
// as that is the requirement for base class components in Angular 9+
@Component({
	selector: 'base-component',
	template: '',
})

// Base component that enables a single method of destroying observable
// subscriptions when a component is destroyed
export abstract class BaseComponent extends RootComponent {

	/**
	 * The name or identifier of the current class, not otherwise available when running in "production mode". 
	 * It is used to output debugging information on the console, and also attached to translations of labels, product tours, etc
	 * @see TrolyComponent.log
	 * @see IntrojsService.loadPage
	 */
	public readonly __name:string = 'BaseComponent';
	public readonly __path:string;

	// when subscribing to an observable the child component
	// will use this in a `takeUntil()` to ensure the
	// subscription is destroyed when the child component is
	//
	// See: https://medium.com/@benlesh/rxjs-dont-unsubscribe-6753ed4fda87 (creator of RxJS)
	public observablesDestroy$ = new Subject();

	// This is where the magic of unsubscribing all observables happens
	// if the child component ever needs to override `ngOnDestroy()`
	// it MUST MUST call `super.ngOnDestroy()` or else this BaseComponent
	// ngOnDestroy will not run and any observables will stay subscribed
	// causing memory leaks every single time that component is initialised
	// as more and more subscriptions will pile up that never get destroyed
	ngOnDestroy() {
		// unsubscribe when component is destroyed to avoid memory leaks
		//this.observablesDestroy$.complete();
		this.observablesDestroy$.next(true);
		this.observablesDestroy$.complete();
	}

	protected _debug: string[] = [];
	public log(message: string, level: string = '-') {
		if (this._debug.includes(level) || this._debug.includes('ALL')) {
			console.log(`[${level.toUpperCase()}] ${message}`);
		}
	}
	
	/**
	 * 
	 */
	public set debug(value:string[]) {
		this._debug = value
		window.localStorage.setItem('de', value.join('|'));
	}

	public err(message: string, level: string = '-') {
		if (this._debug.length > 0) {
			console.error(`[${level.toUpperCase()} ERR] ${message}`);
		}
	}

	public readonly COLOURS = {
		GREEN: '#34c38f', /// off-brand, based on the skote green pellets
		GREEN2: '#43a047', 	// Troly Green
		BLUE: '#0288d1', 		// Troly blue
		YELLOW: '#fdca40', 	// Troly Yellow
		ORANGE: '#f57c00', 	// Troly orange
		PURPLE: '#9537C0', 	// Troly Purple
		PINK: '#e91e63', 		// Off-brand, based on the skote red pellets
		RED: '#dd4d4d', 		// Off-brand, based on the skote red pellets
		LIGHT: '#e0e1e1', 	// very light version of the dark accent
		SHADED: '#f8f8f8',
		GREY: '#999999',
		DARK: '#2f363a'
	}

	get getRandomHexColor(): string {
		const colors = Object.values(this.COLOURS);
		const randomIndex = Math.floor(Math.random() * colors.length);
		return colors[randomIndex];
	}

	/**
	* Pushes a new object in an array, update the object if it already is present, locating the object based on an optional attribute
	* @param collection array of objects
	* @param element object to add into the array collection
	* @param attr object attribute to compare two objects
	* @returns the updated collection
	*/
	protected pushOrUpdateRecord<T extends TrolyObject>(collection: T[], element: T | T[], attr: string = 'id', insertAt:'end'|'start'='end'): T[] {

		collection = collection || [];
		if (element instanceof Array) {
			element.forEach((_elem) => {
				collection = this.pushOrUpdateRecord(collection, _elem, attr, insertAt)
			})
		} else if (element && element[attr]) {
			let index=-1;
			
			if (element[attr]['_trolyModelName']) {
				index = collection.map(_ => _[attr] && _._trolyModelName == element._trolyModelName).indexOf(element[attr]);
			} else {
				index = collection.map(_ => _[attr]).indexOf(element[attr]);	
			}

			if (index >= 0) {
				collection[index] = Object.assign(collection[index], element);
			} else {
				if (insertAt == 'end') { collection.push(element); } 
				else { collection.unshift(element) }
			}
			//element['highlight'] = true;
		}
		return collection
	}

	/**
	 * 
	 * @param collection 
	 * @param element 
	 * @param attr 
	 * @returns 
	 */
	protected removeRecord<T>(collection: T[], element: T, attr: string = 'id'): T[] {

		collection = collection || []
		if (element) {
			let index = collection.map(_ => _[attr]).indexOf(element[attr]);
			if (index >= 0) {
				collection.splice(index, 1)[0]; // removing 1, returning the only one removed.
			}
		}
		return collection;
	}

	private _service: TrolyService<any>;
	protected get service(): TrolyService<any> {
		if (!this._service) { console.warn(`[${this.__name}].service is used without first being set.`); }
		return this._service;
	}
	protected set service(value: TrolyService<any>) {
		if (this._service) { console.warn(`[${this.__name}].service was set twice within the same context.`); }
		this._service = value;
	}

	public get darkMode(): 'on' | 'off' | 'auto' {
		return this._service?.cachedUiKey<'on' | 'off' | 'auto'>(this.CACHE_KEYS.DARK_MODE, 'auto');
	}

	/**
 	* Set/toggles the darkmode to the next status, or to a defined value -- called externally too // from the top bar navigation menu.
 	* @param value 
 	*/
	public set darkMode(value: null | 'on' | 'off' | 'auto') {
		value = value || (this.darkMode == 'auto' ? 'on' : (this.darkMode == 'on' ? 'off' : 'auto'))
		this._service?.storeUiKey<'on' | 'off' | 'auto'>(this.CACHE_KEYS.DARK_MODE, value);
	}

	/**
	 * Adds or remove a value from a number, provided either as a string or number, while keeping the same precision
	 * @param num the value to add or remove from
	 * @param delta the value to add or remove from the number received. Use a negative value to substract Defaults to 1
	 * @returns 
	 */
	public addRemoveWithPrecision(num:string|number, delta:number=1):number|string {
		
		// if the number is a float, subtract one from the most meafunction subtractOne(val) {
		let [whole, decimal] = num.toString().split('.');
		
		if (delta == 0) { return num }
		else if (decimal) {
			
			if (decimal.indexOf('.') >= 0) { throw (`addRemoveWithPrecision(${num},${delta}) -- there seems to be more than one decimal point (.) in the value provided`); }

			let newDecimal:number = (parseFloat(`0.${decimal}`)+delta);
			
			if (`${newDecimal}`.split('.')[0] != '0') {
				// if decimal was 0.9 and we have added a value pushing going over the decimal pt (eg 0.9 + 0.2 = 1.1)), 
				// then we need to add that vale to the whole number, and remove from the newDecimal
				whole = (parseInt(whole) + parseInt(newDecimal.toString().split('.')[0])).toString();
				newDecimal = (newDecimal < 0 ? -1 : 1) * parseFloat(`0.${newDecimal.toString().split('.')[1]}`)
			}

			newDecimal = newDecimal + parseInt(whole)

			// trim the floating point precision error -- see https://floating-point-gui.de/
			if (newDecimal.toString().match(/000000\d{1,2}$/)) { 
				newDecimal = parseFloat(`${newDecimal}`.replace(/000000\d{1,2}$/, ''));
			}
			
			// reconstruct the new number and return the same data type.
			return (typeof num == 'string' ? newDecimal.toString() : newDecimal);
			
		} else {
	
			// dealing with a number without decimals -- hence an integer is much easier.
			return (typeof num == 'string' ? (parseFloat(num)+delta).toString() : num+delta);
		}
	}

	protected smallestIncrement(num:number|string,up:boolean=true):number {
		let [whole, decimal] = num.toString().split('.');
		if (decimal) {
			if (decimal.indexOf('.') >= 0) { throw (`smallestIncrement(${num},${up}) -- Yo chico! seems to be more than one decimal point (.) in the value provided`); }
			const zeros = decimal.length;
			decimal = decimal[-1].padStart(zeros-1, '0');
			return parseFloat(`0.${decimal}`) * (up ? 1 : -1)
		} else {
			return up ? 1 : -1;
		}
	}

	/**
	 * Converts a key-value pair to a hash object.
	 * @param key - The key of the pair. if an equal sign is present, it will be split into a key-value pair.
	 * @param value - The value of the pair. Default is an empty string.
	 * @returns The hash object representing the key-value pair.
	 */
	public keyValueToHash(key:string, value?:any): {[key:string]:any} {
		if (key.indexOf('=') >= 0) {
			const [k,v] = key.split('=');
			return {[k]:value || v}
		} else {

		}
		let h = {}; h[key] = value || ''; return h;
	}

}

/**
 * Compares two hash maps and determines if they are equal.
 * 
 * @param obj1 - The first hash map to compare.
 * @param obj2 - The second hash map to compare.
 * @param compareKeysOnly - Optional. Specifies whether to compare only the keys of the hash maps. Defaults to false.
 * @returns True if the hash maps are equal, false otherwise.
 */
export const compareHashMap = (obj1:{}, obj2:{}, compareKeysOnly:boolean=false) => {
	
	let match = true;
	
	const keys1 = Object.keys(obj1), keys2 = Object.keys(obj2);
	if(keys1.length !== keys2.length) return false;
	
	for(const key of keys1) { 

		const key_ArraySafe = key.replace(/\[\]$/,'');
		const strictSearch = key.endsWith('[]');

		// when key ends with [] we want to match the whole array
		// param[]='a'&param[]='b' means we are searching for BOTH a or b (key is [])

		// alternatively, when key doesn't:
		// param='a'&param='b' means we are searching for EITHER a and b
		
		if (compareKeysOnly) {
			match = keys2.includes(key_ArraySafe);
		} else {
			const o1ia = Array.isArray(obj1[key]), o2ia = Array.isArray(obj2[key_ArraySafe]);
			if(o1ia && o2ia) {
				// dealing with two arrays all we need to apply is whether we're looking for ALL values, or ANY value
				match = strictSearch ? compareArrays(obj1[key], obj2[key_ArraySafe]) : obj1[key].find(_ => obj2[key_ArraySafe].include(_) ) != null; ;

			} else if(!o1ia && o2ia) { 
				const _ = obj1[key];
				// when looking for EVERY of several values but only one was received, else we need to match the two arrays;
				match = strictSearch ? compareArrays([_], obj2[key_ArraySafe]) : obj2[key_ArraySafe].include(_);

			} else if(o1ia && !o2ia) {
				
				// when looking for EVERY of several values, but what we are comparing to is a single value,  but only one was received, else we need to match the two arrays;
				if (strictSearch && obj1[key].length >= 1) {
					// impossible to look for several values strictly when only one is applied
					match = false;
				} else {
					match = strictSearch ? obj1[key][0] == obj2[key_ArraySafe] : obj1[key].includes(obj2[key_ArraySafe]);
				}

			} else if(obj1[key] !== obj2[key]) { match = false; }
		}

		if (!match) { break; }
	}

	return match;
}

export const compareArrays = (obj1:any[], obj2:any[], strictOrdering:boolean=false) => {

	const keys1 = Object.keys(obj1), keys2 = Object.keys(obj2);
	if(keys1.length !== keys2.length) return false;

	if (strictOrdering) {
		return obj1.every((val, index) => val === obj2[index]);
	} else {
		return obj1.every((val) => obj2.includes(val));
	}
	
}

/**
 * Checks if a value is unique within an array.
 *
 * @param value - The value to check for uniqueness.
 * @param index - The index of the value in the array.
 * @param array - The array being checked.
 * @returns A boolean indicating whether the value is unique.
 */
export const onlyUnique = (value, index, array) => {
	return array.indexOf(value) === index;
}