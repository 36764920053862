import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'cloudinary'
})
export class CloudinaryPipe implements PipeTransform {

	transform(url: string, transformations: {}): string {

		let transformationsToApply = []
		Object.keys(transformations).forEach((key) => {
			transformationsToApply.push(`${key}_${transformations[key]}`)
		})

		let v = url.match(/(\/(v\d*)\/)/);
		if (v) {
			return url.replace(v[2], transformationsToApply.join(',')) // locate the version and replace with the transformations
		} else if (transformationsToApply.length > 0) {
			return url.replace('/image/upload/', '/image/upload/' + transformationsToApply.join(',') + '/') // no version, so just append the transformations
		}
		return url.replace('/image/upload/', '/image/upload/') // no version, so just append the transformations
	}
}
