<table class="table table-striped table-hover">
	<thead><tr>
		<th style="width: 30px;"></th>
		<th>Card</th>
		<th style="width: 40%">Last Used</th>
		<th *ngIf="mode == 'maintain'"></th>
	</tr></thead>
	<tbody *ngIf="cards?.length > 0">
		<tr *ngFor="let card of cards | sortBy">
			<td class="text-center">

				<i *ngIf="isPreferredCard(card)" placement="right"
					ngbTooltip="Default card to process subscriptions payments"
					class="bx bxs-heart text-pink opacity-25 d-block mb-1 font-size-14"></i>

				<i *ngIf="isBackupCard(card)" placement="right"
					ngbTooltip="Secondary card used when the primary fails"
					class="bx bxs-star text-dark opacity-25 d-block mb-1 font-size-14"></i>

				<ng-container *ngIf="mode == 'select-to-pay'">
					<button type="button" class="btn btn-sm bg-primary bg-soft text-primary">use</button>
				</ng-container>
				
					
			</td>
			<td>
				<h6><a href (click)="!!openModal(addCard, card)">{{ card }}</a>
					<span class="ms-2 badge bg-primary bg-soft text-primary align-top">{{ card.label }}</span>
					<i *ngIf="operations[card.id] == 'SAVING'" class="bx bx-loader-alt bx-spin text-primary" role="status"></i>
					<i *ngIf="operations[card.id] == 'SAVED'" class="bx bxs-check-circle bx-fadeout text-success" role="status"></i>
				</h6>
				<p>{{ card.name }} ― EXP. {{ card.exp_month || '?'}}/{{ card.exp_year || '?'}}</p>
			</td>
			<td style="width: 40%">
				<ng-container *ngIf="card.last_used_at">
					<p><a href (click)="!!toggleFromNow();" class="text-primary">
						<span *ngIf="!__fromNow">{{ moment(card.last_used_at).format(datetimeShort) }}</span>
						<span *ngIf="__fromNow">{{ moment(card.last_used_at).fromNow()  }}</span>
					</a></p>
					<p>{{ card.last_used_amount | currency }}
						<span *ngIf="card.expired" class="badge bg-danger text-white">expired</span>
						<span *ngIf="card.expired && card.weeksToExpiry < 12" class="badge bg-warning text-warning">expires soon</span>
					</p>
				</ng-container>
				<ng-container *ngIf="!card.last_used_at">
					<p>Never used</p>
				</ng-container>
				<p *ngIf="card.tokenProviders.length > 0">Stored at: 
					<span *ngFor="let provider of card.tokenProviders" class="badge bg-light" [ngbTooltip]="card.tokens[provider]['payment_token']">{{ 'Models.Integration.Values.provider.' + provider | translate }}</span>&nbsp;
				</p>
			</td>
			<td class="text-center" *ngIf="mode == 'maintain'">

				<span ngbDropdown>
					<button ngbDropdownToggle>
						<i class="bx bx-dots-vertical-rounded"></i>
					</button>
					<div ngbDropdownMenu>
						<button ngbDropdownItem (click)="openModal(addCard, card)">
							<i class="bx bx-pencil"></i>
							<span>Edit Card</span>
						</button>
						<div class="dropdown-divider"></div>
						<button ngbDropdownItem [disabled]="isPreferredCard(card) || isBackupCard(card)" (click)="setPreferredCard(card,'primary_card_id')">
							<i class="bx bxs-star"></i>
							<span>Set as Preferred</span>
						</button>
						<button ngbDropdownItem [disabled]="isPreferredCard(card) || isBackupCard(card)" (click)="setPreferredCard(card,'backup_card_id')">
							<i class="bx bx-star"></i>
							<span>Set as Backup</span>
						</button>
						<div class="dropdown-divider"></div>
						<button ngbDropdownItem [disabled]="isPreferredCard(card) || isBackupCard(card)" class="text-danger"
							(click)="confirmRemove(card, 'Permanently delete this card?', 'Changes take effect immediately, payments details are forever unrecoverable and this impacts active orders.')"
						>
							<i class="bx bx-trash-alt"></i>
							<span>Delete</span>
						</button>
					</div>
				</span>
		  </td>
		</tr>
	</tbody>
	<caption class="p-4" *ngIf="!cards || cards.length == 0">
		<status-hint  
			 icon="bx-tachometer"
			 [loading]="false"
			 title=""
			 class="text-secondary"
			 size="small"
		>
		<p class="font-size-12">No cards are currently saved on file.</p>
	</status-hint>
  </caption>
</table>

<ng-template #addCard>
	<create-credit-card-modal [record]="selectedRecord" [customer_id]="customer_id" (recordSaved)="publishCard($event)"></create-credit-card-modal>
</ng-template>