import { TrolyObject } from "../troly_object";
import { uuid } from "../utils.models";

export class Stat extends TrolyObject {

	declare starts_at: Date;
	declare ends_at: Date;
	declare range: 'hour'|'day'|'week'|'month'|'quarter'|'year'|'alltime';

	private today = new Date();
	protected randInt(min,max) { return Math.floor(Math.random() * (max - min + 1) + min)  /** exclude 0 from possible results because this is used in several divisions */}
	protected probability(probability, value) { return value * ((this.randInt(0,10)/10) < probability ? 1 : -1 ) }
	protected prevMonth(count=1) { return new Date(this.today.getFullYear(), this.today.getMonth()-count, 1) }

}

export class StockStat extends Stat {

	constructor(values?:Object) { super('stock_stat', values) }

	declare draft_orders_total: number
	declare draft_orders_value: number
	declare draft_orders_units: number
	declare orders_to_pay_value: number;
	declare orders_to_pay_units: number;
	declare orders_to_pay_total: number;
	declare orders_to_pack_value: number;
	declare orders_to_pack_units: number;
	declare orders_to_pack_total: number;
	declare orders_to_label_value: number;
	declare orders_to_label_units: number;
	declare orders_to_label_total: number;
	declare orders_to_pickup_value: number;
	declare orders_to_pickup_units: number;
	declare orders_to_pickup_total: number;
	declare shipments_to_dispatch_value: number;
	declare shipments_to_dispatch_units: number;
	declare shipments_to_dispatch_total: number;
	declare shipments_intransit_total: number;
	declare shipments_intransit_units: number;
	declare shipments_intransit_value: number;
	
	declare minutes_to_process_avg;
	declare shipped_total: number;
	declare collected_total: number;
	
	declare stock_products_available: number;
	declare stock_level_end: number;

	declare stock_units_available: number;
	declare stock_units_stocked: number;
	declare stock_locked_by_product: JSON;

	public ordersInProgress(format:string):number|null {
		let result:number;
		if (format == 'value') {
			result = 	this.orders_to_pack_value + this.orders_to_label_value + this.orders_to_pickup_value + this.shipments_to_dispatch_value
		} else if (format == 'units') {
			result = this.orders_to_pack_units + this.orders_to_label_units + this.orders_to_pickup_units + this.shipments_to_dispatch_units
		} else {
			result = this.orders_to_pack_total + this.orders_to_label_total + this.orders_to_pickup_total + this.shipments_to_dispatch_total
		}
		return result;
	}

	public totalStock(product_id?:uuid): number {
		if (!product_id) {
			return Object.keys(this.stock_locked_by_product).map((a) => this.stock_locked_by_product[a]).reduce((a,b) => a+b)
		} else {
			return this.stock_locked_by_product[product_id.toString()] || 0
		}
	}
}

export class StockStatSample extends StockStat {

	constructor(seq, baseline, min, max) {
		super();

		this.orders_to_pack_total = this.randInt(1,50)
		this.orders_to_label_total = this.randInt(1,50)
		this.orders_to_pickup_total = this.randInt(1,50)
		this.shipments_to_dispatch_total = this.randInt(1,50)

		this.orders_to_pack_units = this.orders_to_pack_total * this.randInt(4,18)
		this.orders_to_label_units = this.orders_to_label_total * this.randInt(4,18)
		this.orders_to_pickup_units = this.orders_to_pickup_total * this.randInt(4,18)
		this.shipments_to_dispatch_units = this.shipments_to_dispatch_total * this.randInt(4,18)
		
		this.orders_to_pack_value = this.orders_to_pack_total * 140
		this.orders_to_label_value = this.orders_to_label_total * 140
		this.orders_to_pickup_value = this.orders_to_pickup_total * 140
		this.shipments_to_dispatch_value = this.shipments_to_dispatch_total * 140
	}
}

export class DemographicStat extends Stat {

	constructor(values?:Object) { super('demographic_stat', values) }

	declare ltm_collected_sales_value:number
	declare ltm_shipped_sales_value:number
	declare ltm_sales_percentile:number
	declare ltm_membership_sales_value:number

	/** The number of purchases made */
	declare sales_count:number;
	/** The total number of products purchased */
	declare sales_units:number
	/** The overall dollar value for all purchases */
	declare sales_value:number


	declare high_value_interactions:number
	declare mid_value_interactions:number
	declare low_value_interactions:number

}

export class SystemStat extends Stat {

	constructor(values?:Object) { super('system_stat', values) }

	has_guest_customer:boolean
}

export class PipelineStat extends Stat {

	constructor(values?:Object) { super('pipeline_stat', values) }

	/** number of active members */
	declare members_count:number;
	declare members_lost:number;
	declare members_new_per_segment: { [key: string]: number }
	declare members_lost_per_segment: { [key: string]: number }
	declare members_count_per_segment: { [key: string]: number }

	declare members_new:number;
	declare contacts_new:number;
	declare customers_new:number;
	/** the number of repeat orders placed, per repeat count */
	declare repeat_orders_per_repeats:{[key:number]:number};
	declare repeat_customers_per_repeats:{[key:number]:number};

	declare contacts_count:number;
	declare contacts_interactions_by_direction:{[key:string]:number};
	declare customers_count:number;
	declare customers_interactions_by_direction:{[key:string]:number};


	/** average value per club member and non club member */
	
	/** number of days a member remains a member (first membership creation to last membership cancellation) */
	declare members_loyalty_avg_days:number;
	
	/** MEMBER revenue generated over the last 12 months */
	declare members_last_12mths_revenue:number;
	/** NON-MEMBER revenue generated over the last 12 months */
	declare nmem_last_12mths_revenue:number;

	public totalRepeatCustomers(repeats?): number {
		if (this.repeat_customers_per_repeats) {
			if (!repeats) {
				return Object.keys(this.repeat_customers_per_repeats).filter((a) => a != '0').map((a) => this.repeat_customers_per_repeats[a]).reduce((a,b) => a+b)
			} else {
				return this.repeat_customers_per_repeats[repeats] || 0
			}
		} 
		return -1;
	}

	public totalRepeatOrders(repeats?): number {
		if (this.repeat_orders_per_repeats) {
			if (!repeats) {
				return Object.keys(this.repeat_orders_per_repeats).filter((a) => a != '0').map((a) => this.repeat_orders_per_repeats[a]).reduce((a,b) => a+b, 0)
			} else {
				return this.repeat_orders_per_repeats[repeats] || 0
			}
		}
		return -1;
	}
}
export class PipelineStatSample extends PipelineStat {

	constructor(seq, baseline, min, max) {
		super()
		const c=this.randInt(1,50)/100
		const m=this.randInt(1,40)/100

		this['starts_at'] = this.prevMonth(seq)
		this.make('contacts', 	seq, baseline,   min, 	max)
		this.make('customers', 	seq, baseline*c, min*c, max*c)
		this.make('members', 	seq, baseline*m, min*m, max*m)

		this['members_lost'] = this['members_new'] + this.probability(0.6, this['members_new']/this.randInt(2,5)); // 60% probability of increasing between 20-50%.
		this['members_loyalty_avg_days'] = this.randInt(400,700)
	}

	private make(attr, seq, baseline, min, max) {
		this[`${attr}_new`] = baseline
		this[`${attr}_count`] = parseInt((baseline * (this.randInt(1,min)/min)).toString())
		this[`${attr}_repeat`] = parseInt((baseline * this.randInt(1,max)/max).toString())
		this[`${attr}_interactions_by_direction`] = {
			'none': this.randInt(1,min) * baseline,
			'sent': this.randInt(1,max) * baseline,
			'received': this.randInt(1,min) * baseline
		}
		this[`${attr}_inbound_interactions_avg_30days`] = min/2
		this[`${attr}_outbound_interactions_avg_30days`] = max-min/2
	}

}

export class SalesStat extends Stat {

	constructor(values?:Object) { super('sales_stat', values) }
	

	declare collected_avg_value:         number;
	declare collected_sales:             number;
	declare collected_units:             number;
	declare collected_value:             number;
	declare completed_avg_value:         number;
	declare completed_sales:             number;
	declare completed_units:             number;
	declare completed_value:             number;
	declare costs_per_product:           {};
	declare costs_per_product_category:  {};
	declare count_per_age:               {};
	declare count_per_channel:           {};
	declare count_per_partner:           {};
	declare count_per_product:           {};
	declare count_per_product_category:  {};
	declare count_per_revenue_type:      {};
	declare count_per_source:            {};
	declare count_per_tag:               {};
	declare count_per_user:              {};
	declare customers_ltm_avg_sales:     number;
	declare customers_ltm_avg_units:     number;
	declare customers_ltm_avg_value:     number;
	declare customers_ltm_sales:         number;
	declare customers_ltm_units:         number;
	declare customers_ltm_value:         number;
	declare customers_ltv_avg_sales:     number;
	declare customers_ltv_avg_units:     number;
	declare customers_ltv_avg_value:     number;
	declare customers_sales_count:             number;
	declare customers_sales_units:             number;
	declare customers_sales_value:             number;
	declare ends_at:                     Date;
	declare members_ltm_avg_sales:       number;
	declare members_ltm_avg_units:       number;
	declare members_ltm_avg_value:       number;
	declare members_ltm_sales:           number;
	declare members_ltm_units:           number;
	declare members_ltm_value:           number;
	declare members_ltv_avg_sales:       number;
	declare members_ltv_avg_units:       number;
	declare members_ltv_avg_value:       number;
	declare members_sales:               number;
	declare members_units:               number;
	declare members_value:               number;
	declare orders_by_day_of_week:       {};
	declare orders_by_time_of_day:       {};
	declare orders_direct_avg_value:     number;
	declare orders_direct_sales:         number;
	declare orders_direct_units:         number;
	declare orders_direct_value:         number;
	declare orders_indirect_avg_value:   number;
	declare orders_indirect_sales:       number;
	declare orders_indirect_units:       number;
	declare orders_indirect_value:       number;
	declare orders_offline_avg_value:    number;
	declare orders_offline_sales:        number;
	declare orders_offline_units:        number;
	declare orders_offline_value:        number;
	declare orders_online_avg_value:     number;
	declare orders_online_sales:         number;
	declare orders_online_units:         number;
	declare orders_online_value:         number;
	declare orders_value_by_day_of_week: {};
	declare orders_value_by_time_of_day: {};
	declare paid_avg_value:              {};
	declare paid_sales:                  {};
	declare paid_units:                  {};
	declare paid_value:                  {};
	declare remittances:                 {};
	declare sequence:                    number;
	declare shipped_avg_value:           number;
	declare shipped_sales:               number;
	declare shipped_units:               number;
	declare shipped_value:               number;
	declare starts_at:                   Date;
	declare units_per_age:               {};
	declare units_per_channel:           {};
	declare units_per_partner:           {};
	declare units_per_payment_method:    {};
	declare units_per_product:           {};
	declare units_per_product_category:  {};
	declare units_per_revenue_type:      {};
	declare units_per_source:            {};
	declare units_per_tag:               {};
	declare units_per_user:              {};
	declare value_per_age:               {};
	declare value_per_channel:           {};
	declare value_per_partner:           {};
	declare value_per_product:           {};
	declare value_per_product_category:  {};
	declare value_per_revenue_type:      {};
	declare value_per_source:            {};
	declare value_per_tag:               {};
	declare value_per_user:              {};

}