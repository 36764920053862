import { Component, Input, SimpleChanges } from '@angular/core';
import { ApexAxisChartSeries, ApexXAxis } from 'ng-apexcharts';
import { TChartComponent } from 'src/app/core/components/chart.component';
import { Company } from 'src/app/core/models/troly/company.model';
import { uuid } from 'src/app/core/models/utils.models';

@Component({
    selector: 'snapshot-chart-widget',
    templateUrl: './snapshot-chart.widget.html',
    styleUrls: ['./snapshot-chart.widget.scss'],
})
export class SnapshotChartWidget extends TChartComponent {

	@Input() series : ApexAxisChartSeries;
	@Input() xaxis : ApexXAxis;
	@Input() height : number;

	@Input() company_id: uuid;

	public snapshotData = {}

	constructor() { 

			super('area');

			this.chartOptions.series = []
	}

	protected receiveInputParams(changes: SimpleChanges): void {

		this.companyService.getSnapshot({id:this.company_id} as Company).subscribe({
			next: (snapshot) => {
				console.log('snapshot', snapshot)
				this.snapshotData = snapshot;
			}
		});

		if (changes.series && changes.series.currentValue ) {
			this.chartOptions.series = changes.series.currentValue
		}
		if (changes.xaxis && changes.xaxis.currentValue ) {
			this.chartOptions.xaxis = changes.xaxis.currentValue
		}
		if (changes.height && changes.height.currentValue ) {
			this.chartOptions.chart.height = changes.height.currentValue
		}
	}
}
