import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'removeAndStrip'
})
export class RemoveAndStripPipe implements PipeTransform {

	transform(value: string, transformations: {}): string {

		if (transformations['remove']) {
			if (!Array.isArray(transformations['remove'])) { transformations['remove'] = [transformations['remove']] }
			transformations['remove'].forEach(element => {
				value = value.replace(element,'')
			});
		}
		return value.replace(/\s{2,}/, ' ').trim()
	}
}
