import { inject, Injectable } from '@angular/core';

import { BillingPeriod, Company, IReferral } from '../../models/troly/company.model';
import { ITrolyService, TrolyService } from './troly.service';

import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { Club } from 'src/app/core/models/troly/club.model';
import { Document } from 'src/app/core/models/troly/document.model';
import { TrolySearch } from '../../models/form_objects';
import { TrolyIndexedObject, TrolyTokenSearch } from '../../models/search_objects';
import { Address } from '../../models/troly/address.model';
import { Carton } from '../../models/troly/carton.model';
import { CompanyTemplate } from '../../models/troly/company_template.model';
import { buildCorrectIntegrationObject, Integration } from '../../models/troly/integration.model';
import { PaymentCard } from '../../models/troly/payment_card.model';
import { ShippingRule } from '../../models/troly/shipping_rule.model';
import { PipelineStat } from '../../models/troly/stats.model';
import { CompanyTag, Tag } from '../../models/troly/tag.model';
import { Task } from '../../models/troly/task.model';
import { User } from '../../models/troly/user.model';
import { Warehouse } from '../../models/troly/warehouse.model';
import { TrolyObject } from '../../models/troly_object';
import { uuid } from '../../models/utils.models';
import { ITaggableService, TaggableModule } from './modules/taggable.module';

@Injectable({
	providedIn: 'root',
})

/*
  This class is in charge of all loading and unloading of a company's profile.

  There can only ever be a single profile loaded for a given app instance.

  i.e. if you load an order, it will load and set the current company to the company in question
*/
export class CompanyService extends TrolyService<Company> implements ITaggableService<CompanyTag>, ITrolyService<Company> {

	/**
	 * The name or identifier of the current class, not otherwise available when running in "production mode". 
	 * It is used to output debugging information on the console, and also attached to translations of labels, product tours, etc
	 */
	public readonly __name: string = 'CompanyService';

	protected translateService: TranslateService = inject(TranslateService);
	
	/**
	 * And provides additional (reused) functions for dealing with tags within the context of a Customer
 	 */
	public taggableModule: TaggableModule = new TaggableModule(this);

	constructor() { 
		super('companies'); 

		const c = this.storedCompany();
		if (c?.id) { this.record$.next(c) }
	}

	public searchIndex<U>(obj?: TrolyObject, params?: {}): Observable<TrolySearch<TrolyIndexedObject>> {
		return this.call<U>('get', obj, 'search', params).pipe(map(_ => {
			let search = new TrolySearch<TrolyIndexedObject>(_.body, 'results');
			search.results = search.results.map(_ => new TrolyIndexedObject(_));
			return search;
		}))
	}

	public searchTokens<U>(obj?: TrolyObject, params?: {}): Observable<TrolyTokenSearch> {
		return this.call<U>('get', null, `tokens/${obj}`, params).pipe(map(_ => { return new TrolyTokenSearch(_.body) }), catchError(_ => { return of(null) }));
	}

	/**
	 * 
	 * @param id 
	 * @param filter 
	 * @returns 
	 */
	public find(id: uuid, params?: {}, method?: string): Observable<Company> {
		// All API calls are sent a "active company" header, which is used to determine which company to load.
		// when retrieving a company, we force this flag to be the newly desired company, not the "currently loaded" company.
		// @see `TrolyApi.apiHeaders`
		const headers = { 'X-Active-Company': id }
		return this.get(id ? this.make({id:id}) : null, method, params, headers).pipe(map(_ => this.make(_.body[this.singular_node])));
	}

	/**
	 * Attempts to notify all subscribers of an updated company record retrieved, if applicable (record must match)
	 * @param obj newly retrieved or internally updated company record
	 * @param preserve_attr attributes to be preserved / not reloaded from the old to the new object
	 * @param flush USE CAREFULLY - only for debugging purposes, this is already handled via the changeActiveCompany method.
	 * @returns the updated/merged Company record OR the original, which ever is currently in use.
	 */
	public _next(obj: Company, flush_attr?: string|string[], force:boolean=false): Company {
		if (force || this.storedCompany().id == obj.id) { this.storedCompany(obj, 'login'); }
		return super._next(obj, flush_attr, force);
	}

	public createCarton(payload: Carton, record?:Company): Observable<Carton> {
		return this.post(payload, `${this.record$.value.id}/cartons`).pipe(map(_ => new Carton(_.body['carton'])));
	}
	public removeCarton(id: uuid, record?:Company): Observable<Carton> {
		return this.delete(new Company({ id: record?.id || this.record$.value.id }), `/cards/${id}`).pipe(map(_ => new Carton(_.body['carton'])))
	}

	public createCard(payload: PaymentCard, record?:Company): Observable<PaymentCard> {
		return this.post(payload, `${record?.id ||  this.record$.value.id}/cards`).pipe(map(_ => new PaymentCard(_.body['payment_card'])));
	}
	public removeCard(id: uuid, record?:Company): Observable<PaymentCard> {
		return this.delete(new Company({ id: record?.id || this.record$.value.id }), `/addresses/${id}`).pipe(map(_ => new PaymentCard(_.body['payment_card'])))
	}

	public createAddress(payload: Address, record?:Company): Observable<Address> {
		return this.post(payload, `${record?.id || this.record$.value.id}/addresses`).pipe(map(_ => new Address(_.body['address'])));
	}
	public removeAddress(id: uuid, record?:Company): Observable<Address> {
		return this.delete(new Company({ id: record?.id || this.record$.value.id }), `/addresses/${id}`).pipe(map(_ => new Address(_.body['address'])))
	}

	// !! the only reason why we are creating cards/address/cartons here is because they are not directly available in the API through their respective /addrss, /cards etc endpoints.

	/**
	 * Is responsible for updating a tag attached to this a Company record.
	 * @param verb 
	 * @param id 
	 * @param obj 
	 * @param record 
	 * @returns 
	 */
	public updateTag(verb, id:uuid, obj?:CompanyTag, record?:Company): Observable<CompanyTag> {
		// this uses a call (instead of create/save/delete) to more easily handle every operations in one call. Also must handles a raw api HttpResponse 
		return this.call<CompanyTag>(verb, obj, `${record?.id || this.record$.value.id}/tags/${id}`).pipe(map(_ => new CompanyTag(_.body['company_tag'])))
	}

	public searchTags(params?: {}, record?: Company): Observable<TrolySearch<Tag>> {

		let obj = this.make({id: params['company_id'] || record?.id })
		delete params['company_id']

		return this.call<TrolySearch<Tag>>('get', obj, 'tags/search', params).pipe(map(_ => {
			let search = new TrolySearch<Tag>(_.body, 'tags');
			search.results = search.results.map(_ => new Tag(_));
			return search;
		 }))
	}

	public makeObjectTag(payload: {}): CompanyTag { return payload instanceof CompanyTag ? payload : new CompanyTag(payload); }

	public make(payload: {} = {}): Company { return payload instanceof Company ? payload : new Company(payload); }

	public loadClubs(params?: {}, record?: Company): Observable<Club[]> {

		params ||= {}
		record ||= this.record$.value

		let obj = this.make({id: params['company_id'] || record?.id })
		delete params['company_id']

		const force = record && record.isStale('clubs')

		if (!force && record.clubs) { return of(record.clubs) }

		return this.getList<Club>(obj, 'clubs', params, force).pipe(filter(_ => !!_), map(list => list.map(o => new Club(o))))
	}

	public loadDocuments(params?: {}, record?: Company): Observable<Document[]> {

		params ||= {}
		record ||= this.record$.value

		let obj = this.make({id: params['company_id'] || record?.id })
		delete params['company_id']

		const force = record && record.isStale('documents')

		if (!force && record.documents) { return of(record.documents) }

		return this.getList<Document>(obj, 'documents', params, force).pipe(filter(_ => !!_), map(list => list.map(o => new Document(o))))
	}

	/**
	 * 
	 * @param params 
	 * @param force 
	 * @returns 
	 */
	public loadCompanyTags(params?: {}, record?: Company): Observable<CompanyTag[]> {

		params ||= {}
		record ||= this.record$.value

		let obj = this.make({id: params['company_id'] || record.id })
		delete params['company_id']

		const force = record && record.isStale('company_tags')

		if (!force && record.company_tags) { return of(record.company_tags) }

		return this.getList<CompanyTag>(obj, 'tags', params, force, 'company_tags').pipe(filter(_ => !!_), map(list => list.map(o => new CompanyTag(o))))
	}

	/**
	 * 
	 * @param params 
	 * @param force 
	 * @returns 
	 */
	public loadWarehouses(params?: {}, record?: Company): Observable<Warehouse[]> {

		params ||= {}
		record ||= this.record$.value
		
		let obj = this.make({id: params['company_id'] || record.id })
		delete params['company_id']

		const force = record && record.isStale('warehouses')

		if (!force && record.warehouses) { return of(record.warehouses) }

		const result = this.getList<CompanyTag>(obj, 'warehouses', params, force).pipe(filter(_ => !!_), map(list => list.map(o => new Warehouse(o))))
		if (record.id == this.record$.value.id) { result.pipe(tap(_ => this._next(Object.assign(this.record$.value, { warehouses:result })))) }
		return result
	}

	/**
	 * 
	 * @param params 
	 * @param force 
	 * @returns 
	 */
	public loadUsers(params?: {}, record?: Company): Observable<User[]> {

		params ||= {}
		record ||= this.record$.value

		let obj = this.make({id: params['company_id'] || record.id })
		delete params['company_id']

		const force = record && record.isStale('users')

		if (!force && record.users) { return of(record.users) }

		return this.getList<User>(obj, 'users', params, force).pipe(filter(_ => !!_), map(list => list.map(o => new User(o))))
	}

	/**
	 * 
	 * @param params 
	 * @param force 
	 * @returns 
	 */
	public loadIntegrations(params?: {}, record?: Company): Observable<Integration[]> {

		params ||= {}
		record ||= this.record$.value

		let obj = this.make({id: params['company_id'] || record.id })
		delete params['company_id']

		params['limit'] = 150;

		const force = record && record.isStale('integrations')

		if (!force && record.integrations) { return of(record.integrations) }

		return this.getList<Integration>(obj, 'integrations', params, force).pipe(filter(_ => !!_), map(list => list.map(o => buildCorrectIntegrationObject(o, this.translateService.currentLang))))
	}

	/**
	 * 
	 * @param service 
	 * @returns 
	 */
	public loadOpportunities(params?: {}, record?: Company): Observable<Task[]> {

		params ||= {}
		record ||= this.record$.value

		let obj = this.make({id: params['company_id'] || record.id })
		delete params['company_id']

		const force = record && record.isStale('opportunities')

		if (!force && record.opportunities) { return of(record.opportunities) }

		return this.getList<Task>(obj, 'opportunities', params, force, 'tasks').pipe(filter(_ => !!_), map(list => list.map(o => new Task(o))))
	}

	/**
	 * 
	 * @param service 
	 * @returns 
	 */
	public loadShippingRules(params?: {}, record?: Company): Observable<ShippingRule[]> {

		params ||= {}
		record ||= this.record$.value

		let obj = this.make({id: params['company_id'] || record.id })
		delete params['company_id']

		const force = record && record.isStale('shipping_rules')

		if (!force && record.shipping_rules) { return of(record.shipping_rules) }

		return this.getList<ShippingRule>(obj, 'shipping', params, force, 'shipping_rules').pipe(filter(_ => !!_), map(list => list.map(o => new ShippingRule(o))))
	}

	/**
	 * 
	 * @param params 
	 * @param force 
	 * @returns 
	 */
	public loadTemplates(params?: {}, record?: Company): Observable<CompanyTemplate[]> {

		params ||= {}
		record ||= this.record$.value

		let obj = this.make({id: params['company_id'] || record.id })
		delete params['company_id']

		const force = record && record.isStale('templates')

		if (!force && record.templates) { return of(record.templates) }

		// the default is 100, and there are about 130 templates per company, 
		// this doesn't change based on user behaviour so might as well make it easy on ourselves and load them all at once.,
		// alternative is to handle pagination -- the ListComponent already does this, but it's not really necessary here. 
		params['limit'] = 150; 
		return this.getList<CompanyTag>(obj, 'templates', params, force, 'company_templates').pipe(filter(_ => !!_), map(list => list.map(o => new CompanyTemplate(o))))
	}

	/**
	 * 
	 * @param params 
	 * @param force 
	 * @returns 
	 */
	public loadPipelineStat(params?: {}, record?: Company): Observable<PipelineStat> {

		params ||= {}
		record ||= this.record$.value

		let obj = this.make({id: params['company_id'] || record.id })
		delete params['company_id']

		const force = record && record.isStale('stat')

		if (!force && record.stat) { return of(record.stat) }

		return this.getSingleObject<PipelineStat>(obj, params, 'stat', force, 'pipeline_stat').pipe(filter(_ => !!_), map(o => new PipelineStat(o)))
	}

	/**
	 * 
	 * @param params 
	 * @param force 
	 * @returns 
	 */
	public loadPlatformMembership(params?: {}, record?: Company): Observable<BillingPeriod> {

		params ||= {}
		record ||= this.record$.value

		let id = params['company_id'] || record.id;
		delete params['company_id']

		const force = record && record.isStale('billing_period')

		if (!force && record.billing_period) { return of(record.billing_period) }

		params['with_billing_period'] = true
		params['with_platform_membership'] = true

		return new Observable<BillingPeriod>((obs) => {
			// This is a little bit different compared to other load functions because we are loading more than the billing period (also plans, and plan selected)
			// we also call ._find so that IF we are loading details for the current company, we notify.
			// we don't (currently?) support a company editing an other company anyway.
			this._find(id, params).pipe(tap(o => obs.next(o.billing_period)));
		})
		
	}

	public loadPipelineStats(params?: {}, record?: Company): Observable<PipelineStat[]> {

		params ||= {}
		record ||= this.record$.value
		
		params['limit'] = params['limit'] || 2
		params['range'] = params['range'] || 'month'

		let obj = this.make({id: params['company_id']  || record?.id })  // in order to load different warehouses or products, or else, we must explicitly set in params
		delete params['company_id']

		const force = record?.isStale('stats')

		return this.getList<PipelineStat>(obj, 'stats', params, force, 'pipeline_stats').pipe(filter(_ => !!_)).pipe(map(list => list.map(o => new PipelineStat(o))))
	}

	/**
	 * 
	 * @param params must contain a 'invites' node with an array of new invites, w/ fname + email, or mobile
	 * @param record 
	 * @returns 
	 */
	public saveInvites(invites:IReferral[], record?: Company): Observable<Company> {

		record ||= this.record$.value

		let obj = this.make({id: invites[0]['company_id'] || record.id })

		obj['invites'] = invites

		return this.put(obj, 'invites').pipe(
			map(_ => this.make(_.body[this.singular_node]))
		);
	}

	public getSnapshot(record?: Company): Observable<JSON> {

		record ||= this.record$.value

		let obj = this.make({id: record['id'] || record.id })

		return this.getSingleObject<JSON>(obj, {}, 'snapshot', false, 'x').pipe(filter(_ => !!_))
	}
}

export type ReferralCodeStatus = 'VALID'|'EXHAUSTED'|'EXPIRED'|'INVALID';
