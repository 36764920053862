<div class="card">
	<div class="{{ bsCssClassPrefix }}-header">
		<h4 class="{{ bsCssClassPrefix }}-title">Snapshot</h4>
		<p class="{{ bsCssClassPrefix }}-subtitle">Sales & Distribution</p>
	</div>
	<div class="{{ bsCssClassPrefix }}-body">
		<div class="row">
			<div class="col-12">
				<pre>{{ snapshotData | json }}</pre>
				<apx-chart
					class="apex-charts"
					dir="ltr"
					[series]="chartOptions.series"
					[legend]="chartOptions.legend"
					[stroke]="chartOptions.stroke"
					[xaxis]="chartOptions.xaxis"
					[yaxis]="chartOptions.yaxis"
					[chart]="chartOptions.chart"
					[colors]="chartOptions.colors"
					[plotOptions]="chartOptions.plotOptions"
					[dataLabels]="chartOptions.dataLabels"
					[grid]="chartOptions.grid"
				>
				</apx-chart>
			</div>
		</div>
	</div>
</div>
