import { ChangeDetectorRef, Component, EventEmitter, inject, Input, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { take } from "rxjs";
import { I18nLoader } from "src/app/core/i18n_loader";
import { IJobStatusDetail } from "src/app/core/models/troly/job.model";

@Component({
	selector: 'socket-progress-widget',
	templateUrl: './socket-progress.widget.html',
	styleUrls: ['./socket-progress.widget.scss'],
})
export class SocketProgressWidget {

	protected readonly __name: string = 'SocketProgressWidget';

	status: 'none' | 'submitted' | 'pending' | 'running' | 'completed' | 'error' | 'disconnected' = 'none';
	
	socketStatus: string;

	progress: string;

	latestStep: number = 0;
	latestTotal: number = 0;
	latestOk: number = 0;
	latestFail: number = 0; // number of records which have failed
	latestRecord: string = '';

	@Input() mode: 'full' | 'mini' = 'mini'; // both are the same, one is smaller
	@Output() onProcessingError = new EventEmitter<string>();
	@Output() onProcessingResult = new EventEmitter<string>();

	constructor(private translateService: TranslateService) {

		(this.translateService.currentLoader as I18nLoader).getTranslation(this.translateService.currentLang, 'shared/widgets/sockets', 'Sockets').pipe(take(1)).subscribe(_ => {
			this.translateService.setTranslation(this.translateService.currentLang, _);
		});
		
	}

	/*protected jobService:JobService = inject(JobService)
	private _jobCackeKey:string;
	
	public setCacheKey(value:string): Observable<uuid> {

		const cachedJobId = this.jobService.cachedUiKey<uuid>(value)

		if (cachedJobId) {
			this.jobService.find(cachedJobId).pipe(
				tap((_:Job) => {
					this._jobCackeKey = value
					this.jobService.cachedUiKey(this._jobCackeKey, _.id);
				}),
				catchError(_ => { 
					this.jobService.deleteUiKey(this._jobCackeKey);
					return of(null); 
				})
			);

		} else {
			return of(null)
		}
	}
	public get jobsCacheKey():string { return this._jobCackeKey; }*/

	cd: ChangeDetectorRef = inject(ChangeDetectorRef);

	receiveSocketMessage(msg: IJobStatusDetail): void {

		if (msg.type == 'end' || msg.type == 'error' || msg.type == 'pulse') {

			if (msg.step && this.latestStep != msg.step) {
				this.latestStep
			}

			if (msg.ok) { this.latestOk = msg.ok }
			if (msg.fail) { this.latestFail = msg.fail }
			if (msg.total && this.latestTotal != msg.total) { this.latestTotal = msg.total }

		}

		if (msg.message) {
			console.log('socket message to translate', msg.message);
		}

		if (msg.type == 'pulse') {

			if (msg.last_record_processed) { this.latestRecord = msg.last_record_processed; }

		}

		switch (msg.type) {

			case 'start':
				this.status = 'running';
				break;

			case 'pulse':

				this.status = 'running'; // when the page is reloaded, and the widget has not received the 'start' message
				this.socketStatus = msg.message;
				this.updateProgress(msg);
				break;

			case 'result':

				this.onProcessingResult.emit(msg.result);
				break;

			case 'update':

				this.updateProgress(msg);
				this.socketStatus = msg.message;

				break;

			case 'error':

				this.onProcessingError.emit(this.translateMessage(msg));
				this.status = 'error';
				break;

			case 'end':

				this.updateProgress(msg);
				this.socketStatus = msg.message;

				this.status = msg.ok == 0 ? 'error' : 'completed';
				break;
		}

		this.cd.detectChanges();
	}

	updateProgress(msg : IJobStatusDetail | IJobStatusDetail) {
		if (msg.type == 'end') { this.progress = '100%'; }
		else if (msg.completion == 0) { this.progress = '0%' }
		else {
			this.progress = Math.floor(msg.progress * 100 / (msg.completion)) + '%';
		}
	}

	translateMessage(msg) {

		let updatedMessage = msg.message;

		if (updatedMessage && !updatedMessage.match(/ /)) {
			// messages with no spaces are sent over for translation.
			let params = {
				ok: msg.ok || '?', err: msg.fail || '?',
				total: (msg.total || (msg.ok || 0 + msg.fail || 0))
			}
			updatedMessage = this.translateService.instant(updatedMessage, params)
		}

		return updatedMessage;

	}
}