import { DecimalPipe } from '@angular/common';
import { Directive, ElementRef, forwardRef, HostListener, Input, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * Make use of DecimalPipe to format 
 * 
 * @param digitsInfo - Sets digit and decimal representation {minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}
 * 
 * @see https://coryrylan.com/blog/using-html5-date-input-with-date-objects-and-angular
 */
@Directive({
    selector: 'input[localizedNumericInput]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LocalizedNumericInputDirective),
            multi: true,
        },
        DecimalPipe,
    ],
})
export class LocalizedNumericInputDirective implements ControlValueAccessor {
    @HostListener('blur', []) onTouched: any = () => {};
    @HostListener('input', ['$event']) onChange: any = () => {};;
   
	 /**
     * Same as DecimalPipe
     * {minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}
     * 
     * @see https://angular.io/api/common/DecimalPipe#digitsinfo
     */
    @Input() digitsInfo = '1.2-2';

    constructor(private renderer: Renderer2, private elementRef: ElementRef, private decimalPipe: DecimalPipe) {
    }

    registerOnTouched(fn: () => void) {
        this.onTouched = fn;
    }

    registerOnChange(fn: (value: any) => void) {
        this.onChange = (event: any) => fn(event.target['value']);
    }

    writeValue(value: any) {
	    if (isNaN(parseFloat(value))) {
			  // if a string is entered, set the value to 0
		     this.renderer.setProperty(this.elementRef.nativeElement, 'value', 0);
		 } else {
  	        this.renderer.setProperty(this.elementRef.nativeElement, 'value', this.decimalPipe.transform(value, this.digitsInfo));
		 }		 
    }
}
