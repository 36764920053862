import { Component, Input } from '@angular/core';

@Component({
    selector: 'card-badge-widget',
    templateUrl: './card-badge.widget.html',
    styleUrls: ['./card-badge.widget.scss'],
})
export class CardBadgeWidget {

    @Input('loading') loading:string; 

    constructor() { }

}