import { AfterContentInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, QueryList, SimpleChanges } from '@angular/core';


import { ContentChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs';
import { I18nLoader } from 'src/app/core/i18n_loader';
import { TrolyFormGroup } from 'src/app/core/models/troly_form';


@Component({
	selector: 'form-status',
	templateUrl: 'form-status.component.html',
	styleUrls: ['form-status.component.scss'],
})

export class FormStatusComponent implements AfterContentInit, OnChanges {

	readonly __name: string = "FormStatusComponent";

	allMessages: {} = {};            // stores all messages to display in a key-value hash
	allActions: {} = {};             // stores all action associated with each message

	@Input('formGroup') form!: TrolyFormGroup; //

	@Input() record_label?: string; //

	@Input() successAction?: string = '';
	@Input() classes?: string = "";

	@Output() dismissCallback: EventEmitter<any> = new EventEmitter();

	@ContentChildren("status") childMessages = {} as QueryList<ElementRef>;

	constructor(private translateService: TranslateService) {

		(this.translateService.currentLoader as I18nLoader).getTranslation(this.translateService.currentLang, 'shared/widgets', 'SharedI18n.Widgets').pipe(take(1)).subscribe(_ => {
			this.translateService.setTranslation(this.translateService.currentLang, _);
		});

	}



	public ngAfterContentInit() {
		// QueryList is initialized only before the ngAfterContentInit: https://netbasal.com/understanding-viewchildren-contentchildren-and-querylist-in-angular-896b0c689f6e
		// so contentChildren is set
		this.updateMessages();
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.updateMessages();
	}

	public updateMessages() {
		if (this.childMessages.length) {

			this.allMessages = [];
			this.childMessages.map((_message: ElementRef) => {
				let code = _message.nativeElement.attributes.getNamedItem("code");
				if (code) {
					this.allMessages[code.value] = _message.nativeElement.innerHTML.replaceAll(':label:', this.record_label);

					let action = _message.nativeElement.attributes.getNamedItem("action");
					if (action) { this.allActions[code.value] = action.value; }
				}
			});
		}
	}

	// enables passing of a dismiss message to the form containing this status
	public resolveModal(from?: string): boolean {
		this.dismissCallback.next(from);
		return false; // always returns false, allowing for usage as click: `href (click)="!!resolveModal()"`
	}
}