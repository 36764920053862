<footer>
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6 d-none">
                    {{year}} &copy; Troly<br/>
                    Designed by Local Producers.<br/>
            </div>
            <div class="col-sm-6">
                
            </div>
        </div>
    </div>
</footer>
