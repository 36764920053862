import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 'home',
        icon: 'bx-home-circle',
        // example of how to show a badge with number on the menu item
        //badge: {
        //    variant: 'info',
        //    text: '03',
        //    link: '/'
        //    linkParams= {}
        //},
        link: '/dashboard',
    },
    {
        id: 'orders-all',
        icon: 'mdi-cart-outline',
        link: '/orders/dashboard',
    },
    /*{
        id: 'sales-reports',
        icon: 'bx-file',
        link: '/orders/reports',
    },*/
    {
        id: 'orders-add',
        icon: 'mdi-plus-circle',
        link: '/orders/new'
    },
	 {
		id: 'support',
		defaultExpanded: true,
		subItems: [
			{
				id: 'support-start',
				icon: 'bx-home-smile',
				link: '/support/dashboard',
		  },
			{
				id: 'support-documentation',
				icon: 'bx-book',
				link: '/support/documentation',
			},
			{
				id: 'support-portal',
				icon: 'bx-chat',
				link: '/support/portal',
			},
		]
	},
    {
        id: 'customers',
        defaultExpanded: true,
        subItems: [
            {
                id: 'customers-list',
                icon: 'bx-group',
                link: '/customers/dashboard'
            },
            {
                id: 'customer-profile',
                icon: 'bx-dots-horizontal-xxxx-doesnt-exist',
                hidden: true,
                link: '^\/customers\/\\\d*'
            },
            {
                id: 'customers-segment',
                icon: 'bxs-doughnut-chart',
                link: '/customers/segmentation'
            },
            {
                id: 'customers-clubs',
                icon: 'bxs-badge-dollar',
                link: '/customers/sales'
            },
            {
                id: 'customers-add',
                icon: 'bx-plus-circle',
                action: { createCustomer:true },
            },
        ],
    },
    {
        id: 'products',
        defaultExpanded: true,
        subItems: [
            {
                id: 'products-list',
                icon: 'bx-shopping-bag',
                link: '/products/dashboard'
            },
            {
                id: 'product-details',
                icon: 'bx-dots-horizontal',
                hidden: true,
                link: '^\/products\/\\\d*'
            },
            {
                id: 'products-stock',
                icon: 'bx-package',
                link: '/products/shipping',
            },
            /*{
                id: 'products-channels',
                icon: 'bx-target-lock',
                link: '/products/channels',
                badge: {
                    text:'new',
                    variant: 'success',
                },
            },*/
            {
                id: 'products-add',
                icon: 'bx-plus-circle',
                action: { createProduct: true }
            },
        ],
    },
	 {
		id: 'integrations',
		defaultExpanded: false,
		subItems: [
			{
			 id: 'integrations-marketplace',
			 icon: 'bx-customize',
			 link: '/apps/dashboard',
			},
			{
			 id: 'integrations-live',
			 icon: 'bx-history',
			 link: '/apps/tracking'
		 },
	 ],
 	},
    {
        id: 'company',
        defaultExpanded: true,
        subItems: [
            {
                id: 'company-messages',
                icon: 'bx-home-heart',
                link: '/settings/brand',
            },
            {
                id: 'company-details',
                icon: 'bx-list-ol',
                link: '/settings/business',
            },
				{
					id: 'company-subscriptions',
					icon: 'bx-briefcase',
					link: '/settings/billing',
			   },
            {
                id: 'company-security',
                icon: 'bx-key',
                link: '/settings/security',
            },
        ],
    },
    /*{
        id: 'reports',
        subItems: [
            {
                id: 'reports-sales',
                icon: 'mdi-currency-usd-circle-outline',
                link: `/print/end-of-period/#!${reportCurrentDayFilter}`,
            },
            {
                id: 'reports-clubs',
                icon: 'bx-money',
                link: `/print/membership-profitability/#!${reportCurrentDayFilter}`,
            },
            {
                id: 'reports-transactions',
                icon: 'mdi-bank-transfer',
                link: '/orders/reports',
                linkFragment: 'transactions',
                //link: '/o/transactions',
                //linkQueryParams: {
                //    start_date: `${startOfMonthDate.getDate()}/${startOfMonthDate.getMonth() + 1}/${startOfMonthDate.getFullYear()}`,
                //    end_date: `${endOfMonthDate.getDate()}/${endOfMonthDate.getMonth() + 1}/${endOfMonthDate.getFullYear()}`,
                //},
            },
            {
                id: 'reports-commitment',
                icon: 'bxs-user-detail',
                link: `/print/commitment-report/#!${reportCurrentDayFilter}`,
            },
        ],
    },
    {
        id: 'integrations',
        subItems: [
            {
                id: 'integrations-marketplace',
                icon: 'bx-store-alt',
                link: '/a',
            },
            {
                id: 'integrations-live',
                icon: 'bx-history',
                link: '/a/activity',
            },
            {
                id: 'integrations-live',
                icon: 'bx-history',
                link: '/settings/advanced',
                linkFragment: 'integrations'
            }
        ],
    },*/

];
