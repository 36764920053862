<header id="page-topbar" [ngClass]="{ 'is_test_mode':selectedCompany.is_test_mode }">

  <div class="navbar-header">

    <div class="d-flex">

      <button type="button" class="btn btn-sm px-4 font-size-16 header-item nav-collapse e84c1e86"
        style="margin-left:0.1rem" (click)="toggleVerticalCollapse($event)">
        <i class="fa fa-fw fa-bars"></i>
      </button>

      <span class="app-search">
        <search-dropdown class="e620060b" matFieldClass="topbarSearch" [clearOnSelect]="true" [allowScanner]="true" [altSelection]="nowEditingOrder ? 'add-product-to-order' : ''"
          (selectCallback)="onSearchSelect($event)" (selectCallbackAlt)="onSearchSelect($event, true)"></search-dropdown>
      </span>

      <button [disabled]="true" class="btn btn-sm d-block header-item cursor-auto ms-3 p-0" [ngClass]="{ 
          'opacity-0': !statusMessage, 'opacity-5': statusMessage
        }">
        <i class="bx bx-save text-white font-size-18 align-middle" style="margin-top: -2px;"></i>
        <span *ngIf="statusMessage" class="text-white align-middle ms-1">{{ statusMessage }}{{ statusProgress }}</span>
		  <span *ngIf="!statusMessage" class="text-white align-middle ms-1">All changes saved</span>
      </button>

    </div>

    <!-- LOGO -->
    <div class="d-flex navbar-brand-box">
      <a [routerLink]="['/']" class="logo">
        <span class="logo-lg">
			 <img class="rounded-circle" [src]="selectedCompany.partnerLogoUrl" alt="" style="width:100px;height:100px;"/>
        </span>
		  <span *ngIf="selectedCompany.is_test_mode" class="badge bg-pink" style="vertical-align: baseline;">Demo</span>
		  <span *ngIf="selectedCompany.is_beta_unlocked" class="badge bg-dark" style="vertical-align: baseline;">Extra</span>
      </a>
    </div>

    <!-- Installed Apps -->                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
    <div class="d-flex">
      <div class="dropdown-apps d-lg-inline-block ms-1" ngbDropdown placement="left">
        <button type="button" class="btn header-item noti-icon" ngbDropdownToggle>
          <i class="bx bx-customize"></i>
        </button>
        <div class="p-0 dropdown-menu-{{topBarApps.length > 21 ? 'lg' : 'md'}}" ngbDropdownMenu>
          <div class="p-3 border-bottom">
            <div class="row align-items-center">
              <div class="col">
                <h3 class="m-0 text-center"> Connected Platforms <i class="bx bx-link-external text-secondary"></i></h3>
              </div>
            </div>
          </div>
          <div class="row no-gutters pb-1 pt-1 ps-2 pe-2">
            <div class="col-{{topBarApps.length > 21 ? '2' : '4'}}" *ngFor="let addon of topBarApps">
              <a class="dropdown-icon-item" target="blank" [linkWarning]="__name + '.' + addon.provider" [href]="addon.env.admin_url">
               <i *ngIf="addon.status != 'ready'" class="bx font-size-14 bxs-circle" style="margin-left:-18px;margin-right:4px;" [ngClass]="{
						'text-yellow': addon.status == 'connected' || addon.status == 'warning',
						'text-danger': addon.status == 'error'
					 }"></i> 
					<img
                  [src]="addon.getIcon()"
                  style="width: 32px; height: 32px; margin-top:8px " />
					<span>{{ addon.provider }}</span>
              </a>
            </div>
            <div class="col-{{topBarApps.length > 21 ? '2' : '4'}}">
              <a class="dropdown-icon-item text-primary" ngbDropdownToggle [routerLink]="['/apps/dashboard']">
                <i class="bx bxs-plus-circle"></i>
                <span>New App</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <!-- Notifications -->
      <div class="d-inline-block" ngbDropdown placement="left">
        <button type="button" class="btn header-item noti-icon" id="page-header-notifications-dropdown"
          ngbDropdownToggle>
          <i class="bx" [ngClass]="{ 
				'bx-bell': !hasUnreadNotifications,
				'bxs-bell': hasUnreadNotifications,
				'bx-tada' : hasUnreadNotifications }"></i>
          <span class="badge bg-pink rounded-pill" *ngIf="hasUnreadNotifications"> {{ hasUnreadNotifications }}</span>
        </button>
        <div class="p-0 dropdown-menu-lg"
          aria-labelledby="page-header-notifications-dropdown" ngbDropdownMenu>
          <div class="p-3 border-bottom">
            <div class="row align-items-center">
              <div class="col">
                <h3 class="m-0 text-center"> Notifications </h3>
              </div>
            </div>
          </div>
          <ng-scrollbar visibility="hover" style="max-height:350px;">
            <ng-container *ngFor="let n of sortedNotifications">
              <a href (click)="readAndNavigate(n)" class="text-reset notification-item" ngbDropdownToggle>
                <div class="media {{ n.isUnread ? 'new' : '' }}">
                  <div class="avatar-xs">
                    <span class="avatar-title h5 rounded-circle bg-soft text-center align-middle" [ngClass]="{

							  'bg-danger' : n.status == 'error',
							  'bg-primary' : n.status == 'new',
							  'bg-success' : n.status == 'completed',
							  'bg-secondary' : n.status == 'info' || !n.isUnread
                    }">
						  <i matPrefix class="bx font-size-18" [ngClass]="{
								'bx-user': n.type.startsWith('customer'),
								'bxs-star': n.type.startsWith('membership'),
								'bx-list-check': n.type.startsWith('task'),
								'bx-shopping-bag': n.type.startsWith('product'),
								'bx-dollar': n.type.startsWith('order'),
								'bx-check-circle': n.type.startsWith('task-completed'),
								'bx-customize': n.type.startsWith('integration'),

								'text-dark' : n.isUnread,
								'text-white' : !n.isUnread
						}"></i>
                    </span>
                  </div>
                  <div class="media-body ms-3">

							<p class="mt-4 text-secondary mb-0 font-size-10 float-end text-end">
								<i class="mdi mdi-clock-outline me-1" [ngClass]="{
									'text-primary' : n.isUnread, 'text-secondary' : !n.isUnread
								}"></i> 
								<a href (click)="!!toggleFromNow($event);" class="text-primary">
									<span *ngIf="!__fromNow">{{ moment(n.timestamp).format(datetimeShort) }}</span>
									<span *ngIf="__fromNow">{{ moment(n.timestamp).fromNow() }}</span>
								</a>
								<span *ngIf="n.data['company_customers'] && n.data['company_customers'][0].track_staff_name"><br/>{{ n.data['company_customers'][0].track_staff_name }}</span>
							</p>
							
                    <h5 class="mt-0 mb-1" [ngClass]="{ 'text-secondary' : !n.isUnread }">{{ __name + '.Notifications.Titles.' + n.type + '-' + n.status | translate : { name: n.name } }}</h5>
                    <div class="font-size-11 text-secondary">

							<p class="float-start mb-0" *ngIf="n.type.startsWith('customer') && n.data['company_customers']">
								From
								<span *ngIf="!n.data['company_customers'][0].track_partner" [innerHTML]="'Models.Trackable.Values.track_channel._label' | translate : {channel:n.data['company_customers'][0].track_channel}"></span>
								<span *ngIf="n.data['company_customers'][0].track_partner" [innerHTML]="'Models.TrackableValues.track_channel._label_w_partner' | translate : { partner: n.data['company_customers'][0].track_partner, channel:n.data['company_customers'][0].track_channel}"></span>
								<span *ngIf="n.data['company_customers'][0].track_source" [innerHTML]="'Models.Trackable.Values.track_source._label' | translate: { source: n.data['company_customers'][0].track_source }"></span>
							</p>

							<p class="mb-1" *ngIf="n.text" [innerHTML]="n.text"></p>
                    </div>
                  </div>
                </div>
              </a>
            </ng-container>
				<div *ngIf="!notificationMessages.length" class="mt-3 mb-3 notifications-placeholder">

					<status-hint
						icon="bx-info-circle"
						title=""
						class="text-secondary"
						size="small"
						>
						<p>You have no recent notifications.</p>
					</status-hint>
				</div>
          </ng-scrollbar>
          <div class="p-1 border-top dropdown-footer text-end" [ngClass]="{ 'd-none' : notificationMessages.length == 0 }">
				<p class="mb-0 font-size-10">
					<header-loading-indicator [status]="loading.record" class="me-1"/>
					<a href (click)="!!readAndNavigate()">mark all as read</a>
				</p>
          </div>
        </div>
      </div>

		<!-- Notifications -->
      <div class="d-inline-block" ngbDropdown placement="left" *ngIf="authenticatedUser.badges?.length > 0">
			<button type="button" class="btn header-item noti-icon"
			  ngbDropdownToggle>
			  <i class="bx bxs-badge-check" [ngClass]="{ 
				 'bx-flashing' : hasNewBadge }"></i>
			  <span class="badge bg-purple rounded-pill" *ngIf="hasNewBadge > 0"> {{ hasNewBadge }}</span>
			</button>
			<div class="p-0 dropdown-menu-lg"
			  aria-labelledby="page-header-notifications-dropdown" ngbDropdownMenu>
			  <div class="p-3 border-bottom">
				 <div class="row align-items-center">
					<div class="col">
					  <h3 class="m-0 text-center">Achievement Badges</h3>
					</div>
				 </div>
			  </div>
			  <ng-scrollbar visibility="hover" style="max-height:350px;">
				<div class="col-4" *ngFor="let badge of authenticatedUser.badges">
					{{ badge.name }} {{ badge.level}}
					</div>
				 <ngb-alert type="info" *ngIf="!service.cachedUiKey('Alert.ke94S1')" class="ms-4 me-4"
				 (closed)="service.storeUiKey('Alert.ke94S1', 'Alert.ke94S1', 45 * 24)">
					<h6 class="">Contribute and earn</h6>
					<p>Badges are designed to reward everyone knowledgeable and helpful to the community. They are sign of your experience in using tools to build better sales and relationships with customers.</p>
					<p>Users with badges also unlock new features and perks, and may also receive cash rewards.</p>
				 </ngb-alert>
			  </ng-scrollbar>
			</div>
		 </div>

      <!-- My Account -->
      <div class="d-inline-block" ngbDropdown placement="right">
        <button type="button" class="btn header-item" ngbDropdownToggle id="page-header-user-dropdown">
          <img *ngIf="selectedCompany.logo_img?.thumbnail.url" class="rounded-circle header-profile-user" src="{{ selectedCompany.logo_img.thumbnail.url }}" alt="" />
          <span class="d-none d-xl-inline-block ms-1 me-1">{{ selectedCompany.business_name }}</span>
          <i matSuffix class="bx bx-chevron-down"></i>
        </button>
        <div class="dropdown-menu-sm" aria-labelledby="page-header-user-dropdown" ngbDropdownMenu>
          <button ngbDropdownItem routerLink="/settings/me">
            <i class="bx bx-user"></i>
            <span>My Account</span>
				<a class="badge" href="/settings/me#preferences-expertise_level" [ngClass]="{
					'bg-danger': authenticatedUser.expertise_level == 'guru',
					'bg-soft bg-danger': authenticatedUser.expertise_level == 'expert',
					'd-none': authenticatedUser.expertise_level == 'beginner' || authenticatedUser.expertise_level == 'intermediate'
				 }">{{ 'Models.User.Values.expertise_level.' + authenticatedUser.expertise_level| translate }}</a>
          </button>

          <button ngbDropdownItem (click)="darkMode = null">
            <i class="bx bx-moon"></i>
            <span>Dark Mode</span>
            <span class="badge" [ngClass]="{
              'bg-primary': darkMode == 'auto',
              'bg-success': darkMode == 'on',
              'bg-warning': darkMode == 'off'
            }">{{ 'Meta.Statuses.' + darkMode | translate }}</span>
          </button>

          <button ngbDropdownItem (click)="toggleLayoutPref($event)">
            <i class="bx bx-layout"></i>
            <span>Sidebar</span>
            <span class="badge" [ngClass]="{
                        'bg-light': menuItems?.length > 0,
                        'bg-success': !menuItems || menuItems.length == 0
                      }">
                      <span *ngIf="menuItems?.length > 0">{{ 'Meta.Statuses.off' | translate }}</span>
                      <span *ngIf="!menuItems || menuItems.length == 0">{{ 'Meta.Statuses.on' | translate }}</span>
                    </span>
          </button>

			 <button ngbDropdownItem *ngIf="_debug.length > 0 || authenticatedUser.email.match('@troly.co')" (click)="toggleDebug($event)">
            <i class="bx bx-bug"></i>
            <span>Debug</span>
				<span class="badge bg-warning" *ngIf="_debug.length > 0"> {{ _debug[0] }} </span>
				<span class="badge bg-light" *ngIf="_debug.length == 0"> OFF </span>
          </button>

          <h6>{{ selectedCompany.business_name }}</h6>

          <button ngbDropdownItem routerLink="/settings/business">
            <i class="bx bx-wrench"></i>
            <span>Company Settings</span>
			 </button>
          <button ngbDropdownItem routerLink="/settings/billing">
            <i class="bx bx-dollar-circle"></i>
            <span>Billing</span>
			 </button>

			 <button ngbDropdownItem (click)="form.patchDirty({pref_sample_data:!form.get('pref_sample_data').value})">
            <i class="bx bx-trending-up"></i>
            <span>{{ 'Demo.sample' | translate }}</span>
				<span class="badge bg-warning" *ngIf="authenticatedUser.pref_sample_data">On</span>
				<span class="badge bg-light" *ngIf="!authenticatedUser.pref_sample_data">Off</span>
          </button>

          <!-- Add help pellet activation -->
          <!--a class="dropdown-item" href="javascript: void(0);" (click)="toggleHelpPellets()" *ngIf="authenticatedUser">
                        <span *ngIf="authenticatedUser.show_help" class="badge bg-success float-end">On</span>
                        <span *ngIf="!authenticatedUser.show_help" class="badge bg-danger float-end">Off</span>
                        <i class="bx bx-help-circle"></i>
                        Help!
                    </a-->

          <ng-container *ngIf="authenticatedUser && authenticatedUser.companies">
            <h6>Your Companies</h6>

            <button *ngFor="let c of authenticatedUser.companies | sortBy" class="dropdown-item" (click)="switchCompany(c.id)"
              [ngClass]="{
                'selected': c.id == selectedCompany.id
              }" [disabled]="c.id == selectedCompany.id">
              <i><img src="{{ c.logo_img?.thumbnail.url }}" class="rounded-circle" style="height:16px;width:16px" /></i>
				  <span>{{ c.business_name }}</span>
            </button>
          </ng-container>

			 <button class="dropdown-item unavailable" [disabled]="true">
				<i class="bx bx-plus-circle"></i>
				<span>New Company</span>
			 </button>

			<ng-container *ngIf="authenticatedUser.isTrolyStaff">
				<div class="dropdown-divider"></div>
				<button ngbDropdownItem routerLink="/system">
					<i class="bx bx-pie-chart-alt-2"></i>
					<span>System Dashboard</span>
				 </button>
			</ng-container>
			 
			 <div class="dropdown-divider"></div>
			 
          <button ngbDropdownItem class="text-danger" (click)="logout()">
            <i class="bx bx-power-off text-danger"></i>
            <span>Logout</span>
			 </button>
        </div>
      </div>

      <div id="Troly_HW_Toggle" class="d-none d-lg-inline-block ms-1 dropdown">
        <button type="button" class="btn header-item noti-icon">
          <i class="bx bx-heart-circle"></i>
          <span id="Troly_HW_Unseen" class="badge bg-primary rounded-pill">3</span>
        </button>
        <span id="Troly_HW_Badge"></span>
      </div>

      <!--div class="dropdown d-none d-lg-inline-block ms-1" [ngClass]="{ 'show' : sidenavService.status == 'opened' }">
                <button type="button" class="btn header-item noti-icon" (click)="sidenavService.toggle()">
                    <i class="bx" [ngClass]="{ 
                      'bx-help-circle' : sidenavService.status == 'closed',
                      'bxs-help-circle' : sidenavService.status == 'opened'  
                    }" ></i>
                </button>
            </div-->

      <div class="dropdown d-none d-lg-inline-block ms-1" [ngClass]="{ 'show' : helpOpen }">
        <button id="Troly_Help_Toggle" type="button" class="btn header-item noti-icon" (click)="toggleHelp($event)">
          <i class="bx" [ngClass]="{ 
                    'bx-help-circle' : !helpOpen,
                    'bxs-help-circle' : helpOpen  
                  }"></i>
          <span *ngIf="helpUnread > 0" class="badge bg-primary rounded-pill">{{ helpUnread }}</span>
        </button>
      </div>

      <div class="dropdown d-none d-lg-inline-block ms-1">
        <button type="button" class="btn header-item noti-icon" data-toggle="fullscreen"
          (click)="fullscreenService.toggle()">
          <i class="bx" [ngClass]="{
                      'bx-fullscreen' : !fullscreenService.enabled,
                      'bx-exit-fullscreen' : fullscreenService.enabled
                    }"></i>
        </button>
      </div>
    </div>
  </div>

</header>

<div class="topnav" *ngIf="menuItems?.length > 0">
  <div class="container-fluid">
    <nav class="navbar navbar-light navbar-expand-lg topnav-menu active">
      <div class="collapse navbar-collapse active" id="topnav-menu-content">
        <ul class="navbar-nav">
            
            <li class="nav-item dropdown" *ngFor="let item of menuItems">  
              
              <a class="nav-link dropdown-toggle arrow-none" *ngIf="!item.subItems" href="javascript: void(0);"
                id="topnav-components" [routerLink]="item.link" role="button" routerLinkActive="active">

                <span *ngIf="!item['label']">{{ 'Sidebar.Menu.' + item.id | translate }}</span>

					 <span *ngIf="item.badge" class="badge ms-2 float-end with-link bg-{{ item.badge.variant }}">
						<a [routerLink]="item.badge.link" [fragment]="item.badge.linkFragment"
							 [queryParams]="item.badge.linkQueryParams">{{ item.badge.text }}</a>
				  	 </span>

              </a>

              <a *ngIf="item.subItems" class="nav-link dropdown-toggle arrow-none" (click)="onMenuClick($event)"
                href="javascript: void(0);" id="topnav-components" role="button">
					 <ng-container *ngIf="item.icon">
						<i *ngIf="['bx','md'].includes(item.icon.substring(0,2))"
							 class="{{ item.icon.substring(0,2) == 'bx' ? 'bx' : 'mdi' }} {{ item.icon }}"></i>
						<img [src]="item.icon" *ngIf="!['bx','md'].includes(item.icon.substring(0,2))" />
						  </ng-container>
                <span *ngIf="!item['label']">{{ 'Sidebar.Menu.' + item.id | translate }}</span>
                <div class="arrow-down"></div>
              </a>

              <div class="dropdown-menu" [ngClass]="{'dropdown-mega-menu-xl px-2':  item.subItems.length > 11}"
                aria-labelledby="topnav-dashboard" *ngIf="hasItems(item)">
                <ng-template ngFor let-i="index" let-subitem [ngForOf]="item.subItems">
                  <a *ngIf="!subitem.hidden && !hasItems(subitem)" class="col dropdown-item side-nav-link-ref"
                    [routerLink]="subitem.link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">

						  <ng-container *ngIf="subitem.icon">
							<i *ngIf="['bx','md'].includes(subitem.icon.substring(0,2))"
								 class="{{ subitem.icon.substring(0,2) == 'bx' ? 'bx' : 'mdi' }} {{ subitem.icon }}"></i>
							<img [src]="subitem.icon" *ngIf="!['bx','md'].includes(subitem.icon.substring(0,2))" />
					  		</ng-container>

                    <span *ngIf="subitem['label']">{{subitem.label }}</span>
                    <span *ngIf="!subitem['label']">{{ 'Sidebar.Menu.' + subitem.id | translate }}</span>

						  <span *ngIf="subitem.badge" class="badge ms-2 me-1 float-none with-link bg-{{ subitem.badge.variant }}">
							<a [routerLink]="subitem.badge.link" [fragment]="subitem.badge.linkFragment"
								 [queryParams]="subitem.badge.linkQueryParams">{{ subitem.badge.text }}</a>
							</span>
                  </a>
                </ng-template>
              </div>
            </li>
          
        </ul>
      </div>
    </nav>
  </div>
</div>