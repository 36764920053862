<div class="card {{ loading.record }}" [ngClass]="{ 'is-modal': bsCssClassPrefix == 'modal' }">
	<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown)="submitOnEnter($event)" class="{{ form.cssClass }}">

        <div class="{{ bsCssClassPrefix }}-header">
            <h4 class="{{ bsCssClassPrefix }}-title" *ngIf="!form.get('id')?.value">Create a new Product</h4>
            <h4 class="{{ bsCssClassPrefix }}-title" *ngIf="form.get('id')?.value">Customer Details for {{ form.get('name').value }}
            </h4>
            <button type="button" class="btn-close" [disabled]="form.seconds > 0"
                (click)="resolveModal('x')"></button>
        </div>
        <div class="{{ form.loading }} {{ bsCssClassPrefix }}-body">

            <form-status [formGroup]="form" (dismissCallback)="resolveModal($event)" successAction="redirect"
                [record_label]="form.get('name').value">

            </form-status>

            <div class="row">
                <div class="col-6">
                    <mat-form-field>
                        <mat-label>Name</mat-label>
                        <input matInput formControlName="name" type="text" />
                        <mat-error *ngIf="form.get('name').errors?.required">Required to search for this product
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <mat-form-field>
                        <mat-label>Type</mat-label>
                        <mat-select formControlName="variety">
                            <ng-container *ngFor="let option of productVarieties">
                                <h6 *ngIf="option[0] == '_'">{{ 'Models.Product.Values.variety.' + option | translate }}
                                </h6>
                                <mat-option *ngIf="option[0] != '_'" [value]="option">
                                    {{ 'Models.Product.Values.variety.' + option | translate }}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                        <mat-error *ngIf="form.get('variety').errors?.required">Required to classify this product</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12" *ngIf="form.get('variety').value == 'bundle' && !form.get('id').value">
                    <ngb-alert type="warning" [dismissible]="false" *ngIf="!form.get('units_produced').value">
                        <h6>Is {{ form.get('name').value || 'this bundle' }} pre-packed?</h6>
                        <p>Entering a stock level will make this a pre-packed product. Prices and weight will be managed separately and not
                            automatically be calculated based on content, which cannot change at the time of
                            transaction.</p>
                    </ngb-alert>
                    <ngb-alert type="success" [dismissible]="false" *ngIf="form.get('units_produced').value">
                        <h6>{{ form.get('name').value || 'This bundle' }} is pre-packed!</h6>
                        <p>Entering a stock level makes this a pre-packed product. Prices and weight are not
                            automatically calculated based on content, which also cannot change at the time of
                            transaction.</p>
                    </ngb-alert>
                </div>
                <div class="col-3">
                    <mat-form-field floatLabel="always">
                        <mat-label>SKU</mat-label>
                        <input matInput formControlName="number" type="text" />
                    </mat-form-field>
                </div>
                <div class="col-3">
                    <mat-form-field floatLabel="always">
                        <mat-label>Units in Stock</mat-label>
                        <input matInput formControlName="units_produced" type="number" />
                        <mat-error>Must be positive</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-3">
                    <mat-form-field floatLabel="always">
                        <mat-label>Price</mat-label>
                        <input matInput formControlName="price" type="number" />
                        <mat-error>Must be positive</mat-error>
                        <span matPrefix>{{ 'Meta.Currency.Symbol' | translate }}</span>
                    </mat-form-field>
                </div>
                <div class="col-3">
                    <mat-form-field floatLabel="always">
                        <mat-label>Weight</mat-label>
                        <input matInput formControlName="weight" type="number" />
                        <span matSuffix>{{ 'Meta.Units.Weight' | translate }}</span>
								<mat-hint>Enables shipping</mat-hint>
                        <mat-error *ngIf="form.get('weight').errors?.min">Must be positive</mat-error>
								<mat-error *ngIf="form.get('weight').errors?.server">{{ form.get('weight').errors?.server }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
				<debug [form]="form"></debug>
        </div>
        <div class="{{ bsCssClassPrefix }}-footer">
            <button type="submit" class="btn btn-primary {{ form.loading }}"
                [disabled]="form.cannotSubmit">
                Save
            </button>
        </div>
    </form>
</div>