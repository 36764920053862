import { NgModule } from '@angular/core';
import { MAT_COLOR_FORMATS, NGX_MAT_COLOR_FORMATS, NgxMatColorPickerModule } from '@vipstorage/material-color-picker';

import { MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MAT_RADIO_DEFAULT_OPTIONS, MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';

const VERBOSE_DATE_FORMAT = {
	parse: {
	  dateInput: ['LL', 'YYYY-MM-DD' ],
	},
	display: {
	  dateInput: 'YYYY-MM-DD',
	  monthYearLabel: 'MMM YYYY',
	  dateA11yLabel: 'LL',
	  monthYearA11yLabel: 'MMMM YYYY',
	},
 }
 
@NgModule({
    declarations: [],
    imports: [
		MatMenuModule,
		MatInputModule,
		MatSelectModule,
		MatSnackBarModule,
		MatIconModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatExpansionModule,
		MatAutocompleteModule,
		MatChipsModule,
		MatRadioModule,
		MatSlideToggleModule,
		MatSliderModule,
		MatMenuModule,
		MatAutocompleteModule,
		MatSidenavModule,
		MatTabsModule,

		MatDatepickerModule,
		MatNativeDateModule,
		MatMomentDateModule,

		NgxMatColorPickerModule
	 ],
    exports: [

		MatMenuModule,
		MatInputModule,
		MatSelectModule,
		MatSnackBarModule,
		MatIconModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatExpansionModule,
		MatAutocompleteModule,
		MatChipsModule,
		MatRadioModule,
		MatSlideToggleModule,
		MatSliderModule,
		MatMenuModule,
		MatAutocompleteModule,
		MatSidenavModule,
		MatTabsModule,

		MatDatepickerModule,
		MatNativeDateModule,
		MatMomentDateModule,

		NgxMatColorPickerModule,

    ],
    providers: [
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' }},
		  
		  { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    	  { provide: MAT_DATE_FORMATS, useValue: VERBOSE_DATE_FORMAT },

		  { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { color: 'primary' } },
		  { provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: { color: 'primary' } },
		  { provide: MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS, useValue: { color: 'primary' } },

		{ provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS }
	]
})
export class TrolyMaterialModule {}