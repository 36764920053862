import { Validators } from "@angular/forms";
import { TrolyObject } from "../troly_object";
import { uuid } from "../utils.models";

/** Notes on interfaces vs clases 
 * Angular will not automatically type cast responses from httpClient into an object.
 * Normall an interface 'acts as a contract' and allows for typescript to accurately check types on compile.
 * However interfaces don't have reusable methods, which translates to typ-ecasting from httpClient responses 
 * to only enforce object attributes, not methods so we need to lean on a 'helper' or 'handler' or 'Model' class to do that, and that needs to be type-casted by hand
 * 
 * see: https://stackoverflow.com/questions/50693272/angular-casting-httpclient-response-class-or-interface
 * 
 * Finally, given httpClient will not 'type-cast' into a class, but the compiler will use the class definitition like it does an interface.
 * so there's no real advantades to using an interface in our case: if we want methods on objects returned through httpclient calls, we need to manually cast into a class of any sort.
 * 
 * The only problem left is the lack of a recognition of an interface (TrolyObject) being implemented by a parent class (TrolyObject) and not recognised as 'implemented' by the child class.
*/
export class ShippingRule extends TrolyObject {

	override _trolyPropertyArray = { providers: Array, prices: Array, destinations: Array, warehouse_ids: Array, clubs: Array } // although clubs are a property of prices, we have a little hack passing this across to the child object in TrolyFormGroup.patchValue()

	declare label: string
	_label: Validators[] = [Validators.required, Validators.maxLength(75)];
	
	declare starts_at: Date
	declare ends_at: Date

	declare prices: IShippingPrice[];
	declare is_scale_pricing:boolean;
	
	/** price to charge. if % is included, this is a % based on carrier costs. else, this if a fixed price. */
	declare price_customers: number;
	_price_customers: Validators[] = [Validators.required];
	declare price_members: number;
	declare price_unit: string;
	_price_unit: Validators[] = [Validators.required, Validators.pattern('flat|margin')];

	declare company_id: uuid;

	/** list of warehouses for which this price applies. empty means all */
	declare warehouse_ids: {id:string}[];

	/** List of country-alpha2, or states abbrv, or postcodes, or postcode-ranges to apply this price to */
	declare destinations: {id:string,name:string}[];

	declare providers: {id:string,name:string}[];

	/** Minimum order size to apply this price to */
	declare rule_unit: string;
	_rule_unit: Validators[] = [Validators.required, Validators.pattern('dollars|cartons|units|weight')];

	constructor(values?: Object) {
		super('shipping_rule', values)
	}

	public beforeSave(originalObject:ShippingRule): boolean {
		
		if (this.warehouse_ids) {
			//we need to add the warehouses which are selected but not dirty -- the shipping service expects ALL elements to be sent, unlike other services (Customers.addresses for instance) which will merge "by default" -- this is the Listable behaviour 
			const wids = this.warehouse_ids.map((_:{id:string}) => _.id);
			this.warehouse_ids.filter(w => !wids.includes(w.id)).map((w:any) => this.warehouse_ids.push(w) );
		}
		if (this.providers) {
			// ditto, as per warehouses -- we need to add the PROVIDERS which are selected but not dirty as expected by the shipping service, ShippingRule.providers doesn't use Listable. This also means old providers will be cleaned up here, if not already done by the api
			const pids = this.providers.map((_:{name:string}) => _.name);
			this.providers.filter(p => !pids.includes(p.id)).map((p:any) => this.providers.push(p) );
		}
		if (this.destinations) {
			this.destinations = this.destinations.filter((_:{id:string,name:string}) => _.name != ""); // an empty element is added as placeholder to show the user where to type
		}
		return super.beforeSave(originalObject);
	}
}

export interface IShippingPrice {
	from: number;
	to: number;
	price: number;
	clubs: {id:uuid,price:number}[];
}
