import { CdnMountedImage, TrolyObject } from "../troly_object";
import { uuid } from "../utils.models";
import { Company } from "./company.model";
import { Customer } from "./customer.model";
import { Membership } from "./membership.model";
import { Order } from "./order.model";
import { Product } from "./product.model";

export class Document extends TrolyObject {

	declare user_id: uuid;
	declare customer_id: uuid;
	declare company_id: uuid;
	declare record_ids: uuid[];
	declare data: CdnMountedImage;
	declare tags: string[]

	declare access_token: string;
	declare media_type: string;//'application/pdf'|'text/csv';
	declare doc_type: string;//'customer_invoice'|'packing_list'|'shipping_label'|'manifest'|'personal_message';
	declare ident:string;

	declare permanent_url:string;
	declare versioned_url:string;

	declare documentable:Customer|Membership|Order|Product|Company;
	
	declare documentable_type: string;
	declare documentable_id: uuid;

	declare filename: string;

	constructor(values?: Object) {
		super('document', values);

		if (values && values['documentable']) {
			if (values['documentable_type'] == 'Product') { this.documentable = new Product(this.documentable['product']) }
			if (values['documentable_type'] == 'Customer') { this.documentable = new Customer(this.documentable['customer']) }
			if (values['documentable_type'] == 'Company') { this.documentable = new Company(this.documentable['company']) }
			if (values['documentable_type'] == 'Order') { this.documentable = new Order(this.documentable['order']) }
			if (values['documentable_type'] == 'Membership') { this.documentable = new Membership(this.documentable['membership']) }
		}

	}

	public getIcon(doc_type?:string, media_type?:string): string | null {
		
		doc_type ||= this.doc_type;
		media_type ||= this.media_type;

		switch(media_type) {
			case 'text/csv': return 'bx bxs-file-export'; 
			case 'application/pdf': return 'bx bxs-file-pdf';
			case 'image/svg+xml': return 'bx bxs-file-image'
		}
	}

	public get ordersCount(): number {
		return this.tags.filter(t => t.startsWith('order-')).length || 0;
	}
}
