import { ValidatorFn, Validators } from "@angular/forms";
import { TrolyObject } from "../troly_object";
import { uuid } from "../utils.models";
import { Integration } from "./integration.model";
import { IProcessingStatusDetail } from "./order.model";
import { User } from "./user.model";


/** Notes on interfaces vs clases 
 * Angular will not automatically type cast responses from httpClient into an object.
 * Normall an interface 'acts as a contract' and allows for typescript to accurately check types on compile.
 * However interfaces don't have reusable methods, which translates to typ-ecasting from httpClient responses 
 * to only enforce object attributes, not methods so we need to lean on a 'helper' or 'handler' or 'Model' class to do that, and that needs to be type-casted by hand
 * 
 * see: https://stackoverflow.com/questions/50693272/angular-casting-httpclient-response-class-or-interface
 * 
 * Finally, given httpClient will not 'type-cast' into a class, but the compiler will use the class definitition like it does an interface.
 * so there's no real advantades to using an interface in our case: if we want methods on objects returned through httpclient calls, we need to manually cast into a class of any sort.
 * 
 * The only problem left is the lack of a recognition of an interface (TrolyObject) being implemented by a parent class (TrolyObject) and not recognised as 'implemented' by the child class.
*/
export class Shipment extends TrolyObject {

	declare company_id: uuid
	declare orders_id: uuid
	declare customer_id: uuid
	declare manifest_id: uuid

	declare status: 'draft' | 'quoted' | 'quote_error' | 'labelled' | 'labelling_error' | 'dispatched' | 'dispatching_error' | 'completed' | 'cancelled'
	declare status_details: IProcessingStatusDetail[];
	declare delivery_status: 'preprocessing' | 'notified' | 'in_transit' | 'out_for_delivery' | 'delivery_warning' | 'delivered' | 'tracking_error'
	declare delivery_status_details: IProcessingStatusDetail[];
	declare signature_option: 'company_default' | 'carrier_default' | 'signature_required' | 'authority_to_leave'
	declare insurance_option: 'company_default' | 'carrier_default' | 'insurance_no' | 'insurance_yes' | 'insurance_extra'

	declare tracking_codes: string[]

	declare delivery_line1: string
	declare delivery_line2: string
	declare delivery_suburb: string
	declare delivery_postcode: string
	declare delivery_state: string
	declare delivery_country: string
	declare delivery_instructions: string;
	declare delivery_phone: string;
	declare delivery_email: string;
	
	declare delivery_lat: string
	declare delivery_lng: string
	
	declare selected_carrier: string;
	declare selected_service: string;

	declare shipping_quotes: ShippingQuote[]
	declare provider_data: {
		users: User[]
	}
	declare shipping_cost: number;
	declare shipping_rule_id: number;

	/** 
	 * Shipment processing tracking
	 */
	declare quoted_at: Date
	declare carrier_selected_at: Date
	declare carrier_selected_by_id: uuid
	declare quoted_by_id: uuid
	declare dispatched_at: Date;
	declare dispatched_by_id: uuid;
	declare first_in_transit_at: Date
	declare last_in_transit_at: Date
	declare delivered_at: Date;
	declare completed_by_id: uuid

	//* When true, will trigger the record to be deleted from the database, when passed to OrderService.save (given there's no direct Shipping API endpoit!) */
	declare _destroy:boolean 

	constructor(values?: Object) {
		super('shipment', values);

		if (values) {
			if (values['provider_data']) {
				this.delivery_phone = values['provider_data']['delivery_phone'];
				this.delivery_email = values['provider_data']['delivery_email'];
			}
		}
	}

	public get usersInvolved(): User[] { return (this.provider_data?.users || []).map(_ => new User(_)) }
	public user(id:uuid): User | null {
		return super._user(id, this.usersInvolved)
	}

	getIcon(carrier?:string): string | null {
		switch (carrier || this.selected_carrier) {
			case 'cheapest':
				return 'bx bx-truck';
		}
	}

	getImgUrl(carrier?:string) {

		if ((carrier || this.selected_carrier) == 'cheapest') {
			return null;
		}
		const integration = new Integration()
		return integration.getIcon(carrier || this.selected_carrier);
	}

}

export class ShippingCarrier extends TrolyObject  {

	declare name:string;	
	_name: ValidatorFn[] = [Validators.required];

	declare logo:string;

	declare label_layout:string
	_label_layout: ValidatorFn[] = [Validators.required];

	declare status: 'enabled'|'disabled'|'restricted'

	constructor(values?:Object) {
		super('shipping_carrier', values);
	}

}
export class ShippingQuote extends TrolyObject {

	declare cost: number;
	declare carrier: string;
	declare service: string;
	declare details: string;
	declare label: string;
	declare trackable: boolean;
	declare delivery_days: number;
	declare delivery_label: string;
	
	constructor(values?:{}) {
		super('shipping_quote', values);
	}
}