import { Component, Input, SimpleChanges, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { onlyUnique } from 'src/app/core/components/base.component';
import { TrolyListComponent } from 'src/app/core/components/list.component';
import { ITrolySocketNotification } from 'src/app/core/models/form_objects';
import { Company } from 'src/app/core/models/troly/company.model';
import { IntegrationLog } from 'src/app/core/models/troly/integration_log.model';
import { uuid } from 'src/app/core/models/utils.models';
import { IntegrationService } from 'src/app/core/services/troly/integration.service';
import { IntegrationLogService } from 'src/app/core/services/troly/integration_log.service';
import { WebsocketService } from 'src/app/core/services/troly/websocket.service';
import { TrolyAngularModule } from '../../../angular.module';
import { TrolySharedModule } from '../../../shared.module';

@Component({
	selector: 'list-apps-events-card',
	templateUrl: './list-apps-events.card.html',
	styleUrls: ['./list-apps-events.card.scss'],
	standalone: true,
	imports: [ TrolyAngularModule, TrolySharedModule]
})
export class ListAppsEventsCard extends TrolyListComponent<IntegrationLog> {

	override readonly __name:string = 'ListAppsEventsCard'
	override readonly __path:string = 'shared/ui/integrations/list-apps-events'
	
	/**
	 * Define the prefix for all querystring parameters tracked by this component.
	 */
	override readonly __qParamPrefix:string = 'LAE.'

	/**
	 * The currently loaded Company as advertised by `@this.service` and subscribed to by `@this.subscribeRecord`.
	 * @see TrolyComponent.record (Override)
	 */
	declare record:Company;

	@Input() cardTitle: string = '<em>Real-Time Tracking</em>';
	@Input() containerCss: string = 'card';
	@Input() toolbar: string[] = ["_all"];
	@Input() defaultFilter: {};

	@Input() helpCollection: string = "";

	@Input() pagination: string = "_all"; //
	@Input() advancedCol: boolean = true;
	@Input() applicationCol: boolean = true;

	@Input() loggable_type?: 'Customer' | 'Product' | 'Order'
	@Input() loggable_id?: uuid;

	@Input() processor?: string;
	@Input() provider?: string;
	@Input() integration_id?: uuid;

	/**
	 * Additional services used this this component. 
	 * ? Keeping in mind CompanyService and UserService are already available in TrolyComponent. 
	 */
	protected integrationLogService: IntegrationLogService = inject(IntegrationLogService);
	protected ws: WebsocketService = inject(WebsocketService);
	protected integrationService: IntegrationService = inject(IntegrationService);

	constructor() {
		super();
			
		// setting the default service for forms + obtaining/saving data in this component.
		this.service = this.integrationLogService;
		
		// overrides the default service notifier to ensure we are only notified of these (POS-ony) records. must be set before cache key so that we don't use the default service.recordsNotifier
		this.listNotifier = new BehaviorSubject<IntegrationLog[]>([]); 

		this.selectingRecords = true; // selection is used to show the technical details
		this.queryParamsListener = ['severity','loggable_type','processor','user_id'];

	}

	protected receiveInputParams(changes: SimpleChanges): void {

		this.api_record_restrictions['with_loggable'] = true;

		//This widget is used in various contexts
		// 1 - Tracking a single specific record (loggable_id and loggable_type being set)
		// 2 - Tracking a single application installed (integration_id is set)
		// 3 - Tracking a all applications for a given provider (provider is set)
		// 4 - Tracking operations onto a TYPE of record with (loggable_type being set, but no loggable_id)

		if (changes.loggable_type?.currentValue) {
			this.api_record_restrictions['loggable_type'] = this.loggable_type;
		}

		if (changes.loggable_id?.currentValue) {
			this.api_record_restrictions['loggable_id'] = this.loggable_id;
		}

		if (changes.provider?.currentValue) {
			this.api_record_restrictions['provider'] = this.provider;
		}

		if (changes.processor?.currentValue) {
			this.api_record_restrictions['processor'] = this.processor;
		}

		if (changes.integration_id?.currentValue) {
			this.api_record_restrictions['integration_id'] = this.integration_id;
		}

		super.receiveInputParams(changes);
	}

	protected loadData(): void {
		if (this.api_record_restrictions['with_loggable']) { super.loadData(); } // ensures that we have received params before loading data
	}



	/**
	 * Applies the filtering business logic before life adding // updating the record in the list
	 * @param event 
	 */
	addFromSocket(event:ITrolySocketNotification): 'added'|'updated'|'deleted'|null {

		const loggable_id_filter = this.queryApplied({loggable_id:true}, 'any', true);
		const loggable_type_filter = this.queryApplied({loggable_type:true}, 'any', true);
		const provider_filter = this.queryApplied({provider:true}, 'any', true);
		const processor_filter = this.queryApplied({processor:true}, 'any', true);
		const severity_filter = this.queryApplied({severity:true}, 'any', true);

		if (loggable_id_filter?.['loggable_id'] != event['data']['loggable_id']) { return; }
		if (loggable_type_filter?.['loggable_type'] != event['data']['loggable_type']) { return; }
		if (provider_filter?.['provider'] != event['data']['provider']) { return; }
		if (processor_filter?.['processor'] != event['data']['processor']) { return; }
		if (severity_filter?.['severity'].includes(event['data']['severity'])) { return; }

		return super.addFromSocket(event)
	}

	processorsInstalled(): string[] {
		return this.selectedCompany.addonsInstalled().map(_ => _.primaryCapabilities).reduce((accumulator, value) => accumulator.concat(value), []).filter(onlyUnique)
	}
}
