import { ValidatorFn, Validators } from '@angular/forms';
import { TrolyObject } from '../troly_object';
import { uuid } from "../utils.models";
import { Company } from "./company.model";
import { CompanyUser, IPermission } from "./company_user.model";

export class User extends TrolyObject {

	override _trolyPropertyArray = { company_users: Array, permissions: Object } // permissions are NOT an attribute on the user, HOWEVER setting this on the user means the FormObject will use and create (see patchValue around line 230)

	declare fname: string;
	_fname: ValidatorFn[] = [Validators.required];

	declare lname: string;
	declare anonimo: string;

	declare email: string;
	_email: ValidatorFn[] = [Validators.email, Validators.required];
	declare unconfirmed_email: string;
	_unconfirmed_email: ValidatorFn[] = [Validators.email];

	declare password: string;
	_password: ValidatorFn[] = [Validators.minLength(10)];

	declare reset_password_token: string;
	declare confirmation_token: string;
	declare confirmed_at: Date;
	declare unlock_token: string;

	declare mobile: string;
	declare mobile_masked: string;
	_mobile: ValidatorFn[] = [Validators.pattern(/^(\+\d{1,2}\s?)?\(?\d{3,4}\)?[\s.-]?\d{3}[\s.-]?\d{3,4}$/)];

	declare phone: string;
	
	declare provider_data: {};
	declare companies: Company[];
	declare company_users: CompanyUser[];

	declare badges: any[];

	declare otp_required_for_login: boolean;
	declare authentication_token: string;

	declare current_sign_in_at: Date;
	declare current_sign_in_ip: string;

	declare readonly: boolean;

	/**
	 * This record will be 'trashed' on deletion, appear in the trash, and can be restored, or will forcefully be deleted after a period of time, or manually.
	 */
	declare trashed_at: Date
	declare trashed_by_id: uuid
	declare trashed_reason: string

	declare pref_platform_tours: {} // stores which platform tours steps were viewed/completed
	declare pref_sample_data:boolean
	declare pref_timezone:string
	declare pref_utc_offset:number
	declare pref_utc_alpha2:string
	
	declare pref_locale:string
	/** examples 'Seasonal', 'Regular', 'Limited', etc. */
	declare expertise_level: 'intermediate'|'beginner'|'expert'|'guru';


	constructor(values?: Object) {
		super('user', values);

		if (values) {
			let k:string='company_users'
			if (values[k] && values[k].length && !values[k][0]['_trolyModelName']) {
				values[k].map((obj,i) => { this[k][i] = new CompanyUser(obj) });
			}

			k='companies'
			if (values[k] && values[k].length && !values[k][0]['_trolyModelName']) {
				values[k].map((obj,i) => { this[k][i] = new Company(obj) });
			}

		}
	}

	/**
	 * Applies certain transformations to an object about to be sent to the API
	 * @param toBeSaved 
	 * @returns 
	 */
	public beforeSave(originalObject:User): boolean {

		if (this.company_users) {
			this.company_users.forEach((cu,i) => {
				cu.beforeSave(originalObject.company_users.find(_ => _.id == cu.id));
			})
		}
		return super.beforeSave(originalObject);
	}

	get fullname(): string {
		return this.lname ? `${this.fname} ${this.lname[0]}.` : this.fname
	}

	public isReadOnly(company_id?:uuid):boolean {
		return this.id == null || this.readonly || this.cu(company_id)?.readonly
	}

	public primaryRole(company_id:uuid): string {
		
		if (this.readonly || this.cu(company_id)?.readonly) { return 'readonly' }
		
		const roles = ['owner', 'admin', 'finances', 'hr', 'sales', 'marketing', 'channels', 'operations','technology']

		const isOwner = this.isRole('owner', 'read', company_id) ? ['owner'] : []
		const hasGrant = this.rolesbyLevel('grant', company_id)
		const hasCreate = this.rolesbyLevel('create', company_id)
		const hasWrite = this.rolesbyLevel('write', company_id)
		const hasRead = this.rolesbyLevel('read', company_id)

		const mostRelevant = isOwner.length > 0 ? isOwner : (hasGrant.length > 0 ? hasGrant : (hasCreate.length > 0 ? hasCreate : (hasWrite.length > 0 ? hasWrite : (hasRead.length > 0 ? hasRead : ['']))))
		return mostRelevant[0]
	}

	/**
	 * Gets the CompanyUser associated with this object for a specific company_id
	 * @param company_id 
	 * @returns 
	 */
	public cu(company_id:uuid): CompanyUser {
		return this.company_users?.find(_ => _.company_id == company_id)
	}

	public toString(full:boolean=true) : string {
		if (full) { return `${this.fname} ${this.lname} (${this.email})` }
		else if (this.lname?.length >=1 ) { return `${this.fname} ${this.lname[0]}.` }
		else { return this.fname }
	}

	/**
	 * Retrieve the roles/permissions available at a given level
	 * @param level 
	 * @param company_id 
	 * @returns 
	 */
	public rolesbyLevel(level:IPermission, company_id: uuid): string[] {
		const roles = ['owner', 'admin', 'finances', 'hr', 'sales', 'marketing', 'channels', 'operations','technology']
		return roles.filter(_ => this.isRole(_,level,company_id))
	}

	/**
	 * Checks whether this user has a specific role for a specific level (or above).
	 * @param role 
	 * @param level 
	 * @param company_id 
	 * @returns 
	 */
	isRole(role: string, level:IPermission, company_id: uuid): boolean {
		
		const cc = this.cu(company_id)
		const permission = cc?.permissions[role] || ""
		
		if (level == 'grant') {
			return [level].includes(permission)
		} else if (level == 'create') {
			return [level,'grant'].includes(permission)
		} else if (level == 'write') {
			return [level,'create','grant'].includes(permission)
		} else if (level == 'read') {
			return permission != ""
		}

	}

	/**
	 * 
	 * @param value the uuid of the walkthrough or PageIdentifier as per @see `troly-content/app.troly.co/json/CustomersSummaryPage,json`
	 * @returns true if the page or uuid was marked as completed already
	 */
	hasCompletedWalkthrough(value: string): boolean {

		if (this.pref_platform_tours) {
			// uuids are always 8 alpha-numeric characters and in lowercase
			if (value.length == 8 && value.toLocaleLowerCase() != value) {
				return this.pref_platform_tours['all'] && this.pref_platform_tours['all'][value] != null

			} else { // if value is NOT a uuid, then we check that the user has completed ALL steps
				return Object.keys(this.pref_platform_tours).includes(value)
			}
		}
		return false;
	}

	get completedWalkthroughs(): number {
		return this.pref_platform_tours && Object.keys(this.pref_platform_tours).length - 1 || 0
	}

	toLocalStorage(action?: string): User {
		action = action || 'logout';

		if (action == 'login') {
			//return this.cleanObjectProperties(new User(this)) as User;
			return this;
		} else {
			return new User({
				fname: this.fname,
				email: this.email,
				otp_required_for_login: this.otp_required_for_login,
			});
		}
	}

	current_company(company_id: uuid): Company {
		return this.companies.find(_ => _.id == company_id)
	}

	expertise(level:'intermediate'|'beginner'|'expert'|'guru'): boolean {
		let echelons = [level]
		
		if (level == 'beginner') { echelons = echelons.concat(['guru','expert','intermediate']); }
		if (level == 'intermediate') { echelons = echelons.concat(['guru','expert']); }
		if (level == 'expert') { echelons = echelons.concat(['guru']); }

		return echelons.includes(this.expertise_level);
	}

	get isTrolyStaff(): boolean {
		return this.email.endsWith('@troly.co');
	}

	/**
	 * Returns the most accurate last signed in at date, based on whether we have company_user data or not
	 * company_users are only loaded when a company_id is passed.
	 */
	get sign_in_at(): Date {
		if (this.company_users?.length > 0 && this.company_users[0].current_sign_in_at) {
			return this.company_users.length[0].current_sign_in_at
		} else {
			return this.current_sign_in_at;
		}
	}
	get sign_in_ip(): string {
		if (this.company_users?.length > 0 && this.company_users[0].current_sign_in_ip) {
			return this.company_users.length[0].current_sign_in_ip
		} else {
			return this.current_sign_in_ip;
		}
	}

	
	/** holds this user lat+lng coordinates as captured by the browser / resquestd to the user */
	declare coords: { lat:number, lng:number };
	public seekLocation(force=false): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			this.coords ||= { lat:null, lng:null };
			if (force || !this.coords.lat || !this.coords.lng) {
				navigator.geolocation.getCurrentPosition((position) => {
					this.coords.lat = position.coords.latitude; 
					this.coords.lng = position.coords.longitude;
					resolve(true);
				}, (error) => {
					resolve(false);
				});
			} else {
				resolve(true);
			}
		});
	}


	public get language(): string {
		return (this.pref_locale || navigator.language).split('-')[0].toLowerCase();
	}
}