<div class="status-container {{ form?.hideFormStatusMessage ? 'hidden' : '' }}">
    <ng-container *ngIf="false">
        [info:{{ form.infoCode}}]
        [success:{{ form.successCode}}]
        [error:{{ form.errorCode}}]
        ({{ form.seconds }})
        [ {{ record_label }} ]
        <!--
            useage of !! with (click)
            https://github.com/angular/angular/issues/2042#issuecomment-105689213
            see also; https://github.com/ng-bootstrap/ng-bootstrap/issues/142

        -->
    </ng-container>

    <!--ngb-alert type="danger" [dismissible]="false" class="mb-0 ml-auto mr-auto">
        Could not connect to the server, please refresh the page
    </ngb-alert>

    <ngb-alert type="danger" [dismissible]="true" class="mb-0 ml-auto mr-auto">
        Could not connect to the server, please refresh the page
    </ngb-alert-->

    <ng-container *ngFor="let msg of allMessages | keyvalue">

        <!-- Custom SUCCESS Messages -->
        <div role="alert" class="alert alert-success {{classes}} seconds_{{form.seconds}}" *ngIf="form.successCode == msg.key">
            <span *ngIf="!form.errorDetails.length" [innerHtml]="msg.value"></span>
            <h6 *ngIf="form.errorDetails.length" [innerHTML]="msg.value"></h6>
            <span *ngIf="allActions[msg.key]">&nbsp;<a href (click)="!!resolveModal(allActions[msg.key])">{{ 'SharedI18n.Widgets.' + __name + '.ResolveActions.' + allActions[msg.key] | translate }}</a>
					<small *ngIf="form.seconds > 0">&nbsp;({{ form.seconds }})</small>
				</span>
            <!--span *ngIf="allActions[msg.key]"><a href (click)="!!resolveModal(allActions[msg.key])">{{ 'FormStatusComponent.ResolveActions.' + allActions[msg.key] | translate }}</a> <i class="ms-2 bx bx-loader-alt bx-spin"></i></span-->
            <ul class="mb-0" [ngClass]="{ 'd-none': !form.errorDetails || !form.errorDetails.length}">
					<li *ngFor="let err of form.errorDetails" [innerHTML]="err | documentToUrl">
					</li>
				</ul>
        </div>
        
        <!-- Custom INFO Messages -->
        <div role="alert" class="alert alert-info {{classes}} seconds_{{form.seconds}}" *ngIf="form.infoCode == msg.key">
            <span *ngIf="!form.errorDetails.length" [innerHtml]="msg.value"></span>
            <h6 *ngIf="form.errorDetails.length" [innerHTML]="msg.value"></h6>
            <ul class="mb-0" [ngClass]="{ 'd-none': !form.errorDetails || !form.errorDetails.length}"><li *ngFor="let err of form.errorDetails" [innerHTML]="err | documentToUrl"></li></ul>
        </div>

        <!-- Custom DANGER Messages -->
        <div role="alert" class="alert alert-danger {{classes}}" *ngIf="form.errorCode == msg.key" >
            <span *ngIf="!form.errorDetails.length" [innerHtml]="msg.value"></span>
            <h6 *ngIf="form.errorDetails.length" [innerHTML]="msg.value"></h6>
            <ul class="mb-0" [ngClass]="{ 'd-none': !form.errorDetails || !form.errorDetails.length}"><li *ngFor="let err of form.errorDetails" [innerHTML]="err | documentToUrl"></li></ul>
        </div>

    </ng-container>

    <!-- Standard SUCCESS Messages -->
    <div *ngIf="form.code == 'SAVED' && !allMessages['SAVED']" role="alert" class="alert alert-success {{classes}} seconds_{{form.seconds}}">
        <p>Done, your changes <span *ngIf="record_label">for {{ record_label }}</span> were <strong>saved successfully</strong>.
        <span *ngIf="successAction"><a href (click)="!!resolveModal(successAction)">{{ 'SharedI18n.Widgets.' + __name + '.ResolveActions.' + successAction | translate }}</a>
			<small *ngIf="form.seconds > 0">&nbsp;({{ form.seconds }})</small>
		</span>
    </div>

    <div *ngIf="form.code == 'CREATED' && !allMessages['CREATED']" role="alert" class="alert alert-success {{classes}} seconds_{{form.seconds}}">
        <p>Your details were recorded<span *ngIf="record_label"> and {{ record_label }} was <strong>successfully created</strong></span>.
            <span *ngIf="successAction">
					<a href (click)="!!resolveModal(successAction)">{{ 'SharedI18n.Widgets.' + __name + '.ResolveActions.' + successAction | translate }}</a>
					<small *ngIf="form.seconds > 0">&nbsp;({{ form.seconds }})</small>
				</span>
        </p>
    </div>

    <div *ngIf="form.code == 'NOCHANGE' && !allMessages['NOCHANGE']" role="alert" class="alert alert-warning {{classes}} seconds_{{form.seconds}}">
        <p>No changes made. Did you mean to <a href (click)="!!resolveModal('dismiss')">close</a> instead?</p>
    </div>

    <!-- Standard ERROR Messages -->
    <div *ngIf="form.code == 'UNKNOWN' && !allMessages['UNKNOWN']" role="alert" class="alert alert-danger {{classes}}"><p>An error was detected. Please <a href (click)="!!resolveModal('logout')">logout</a> and try again.</p><small class="text-secondary">ERR: {{ form.errorDetails[0] }}</small></div>
    <div *ngIf="form.code == 'SERVER' && !allMessages['SERVER']" role="alert" class="alert alert-danger {{classes}}"><h6>Operation failed</h6><ul class="mb-0"><li *ngFor="let err of form.errorDetails" [innerHTML]="err"></li></ul></div>
</div>