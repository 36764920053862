import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { CompanyLockedGuard } from './core/guards/company-locked.guard';
import { RoleGuard } from './core/guards/role.guard';

import { AppComponent, Layouts } from './app.component';
import { SystemGuard } from './core/guards/system.guard';
import { RedirectComponent } from './modules/redirect.component';

const standardCanActivates = [AuthGuard, CompanyLockedGuard, RoleGuard]

export const routes: Routes = [

	/**
	 * read this for routing modules and features; https://medium.com/swlh/angular-organizing-features-and-modules-e582611a720e
	 */

	{ path: '', pathMatch: 'full', redirectTo: '/start/now' },
	
	{ 	
		path: '0/:code', component: RedirectComponent,
		canActivate: [], data: { layout: Layouts.Public }
	},

	{
		path: 'p',
		loadChildren: () => import('./modules/public/public.module').then((m) => m.PublicModule),
		canActivate: [], data: { layout: Layouts.Public }
  	},
	{
		path: 'start',
		loadChildren: () => import('./modules/signin/signin.module').then((m) => m.SignupModule),
		canActivate: [], data: { layout: Layouts.Public }
	},

	{
		path: 'dashboard',
		loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
		canLoad: [AuthGuard], canActivate: standardCanActivates, data: { layout: Layouts.Admin }
	},
	{
		path: 'customers',
		loadChildren: () => import('./modules/customers/customers.module').then((m) => m.CustomersModule),
		canLoad: [AuthGuard], canActivate: standardCanActivates, data: { layout: Layouts.Admin }
	},
	{
		path: 'products',
		loadChildren: () => import('./modules/products/products.module').then((m) => m.ProductsModule),
		canLoad: [AuthGuard], canActivate: standardCanActivates, data: { layout: Layouts.Admin }
	},
	{
		path: 'apps',
		loadChildren: () => import('./modules/integrations/integrations.module').then((m) => m.IntegrationsModule),
		canLoad: [AuthGuard], canActivate: standardCanActivates, data: { layout: Layouts.Admin }
	},
	{
		path: 'orders',
		loadChildren: () => import('./modules/orders/orders.module').then((m) => m.OrdersModule),
		canLoad: [AuthGuard], canActivate: standardCanActivates, data: { layout: Layouts.Admin }
	},
	{
		path: 'settings',
		loadChildren: () => import('./modules/settings/company.module').then((m) => m.CompanyModule),
		canLoad: [AuthGuard], canActivate: standardCanActivates, data: { layout: Layouts.Admin }
	},
	{
		path: 'support',
		loadChildren: () => import('./modules/support/support.module').then((m) => m.SupportModule),
		canLoad: [AuthGuard], canActivate: standardCanActivates, data: { layout: Layouts.Admin }
	},
	{
		path: 'system',
		loadChildren: () => import('./modules/system/system.module').then((m) => m.SystemModule),
		canLoad: [SystemGuard], canActivate: standardCanActivates, data: { layout: Layouts.Admin }
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {
		// See: https://www.bennadel.com/blog/3545-enabling-the-second-click-of-a-routerlink-fragment-using-onsameurlnavigation-reload-in-angular-7-1-3.htm
		// Tell the router to use the hash instead of HTML5 pushstate.
		//useHash: true,
		// In order to get anchor / fragment scrolling to work at all, we need to
		// enable it on the router.
		anchorScrolling: "enabled",
		// Once the above is enabled, the fragment link will only work on the
		// first click. This is because, by default, the Router ignores requests
		// to navigate to the SAME URL that is currently rendered. Unfortunately,
		// the fragment scrolling is powered by Navigation Events. As such, we
		// have to tell the Router to re-trigger the Navigation Events even if we
		// are navigating to the same URL.
		onSameUrlNavigation: "reload",
		scrollOffset: [0, 141] // the offset is used to account for the fixed header and toppage nav
	})],
	exports: [RouterModule]
})
export class AppRoutingModule {
	static components = [ AppComponent ];
}
