import { AfterContentInit, Directive, ElementRef, inject } from '@angular/core';
import { WindowService } from 'src/app/core/services/window.service';

@Directive({
    selector: 'div[selectable]',
    host: {
        '[class.selected-card]': 	'selectedCard == thisCardId',
		  '[class.selected-field]': 	'selectedField == thisFormField',
    },
})
export class SelectableDirective implements AfterContentInit {

	protected selectedCard:string;
   protected selectedField:string;
   protected thisCardId:string;
	protected thisFormField:string;

	protected windowService: WindowService = inject(WindowService);

   constructor(protected element: ElementRef) {

		this.thisCardId = element.nativeElement.id;
		this.thisFormField = element.nativeElement.id;

	}

	ngAfterContentInit(): void {

		this.windowService.currentCardTargeted$.subscribe(id => { this.selectedCard = id })

		this.windowService.currentFieldTargeted$.subscribe(id => { 
			this.selectedField = id;
			
			if (this.element.nativeElement.children[0].tagName == 'MAT-FORM-FIELD') {
				let inputElement = this.element.nativeElement;
				try {
					inputElement = this.element.nativeElement.children[0].children[0].children[0].children[0].children[0];
				} catch (e) {
				} 
				if (inputElement.getAttribute('formcontrolname') == this.selectedField) {
					if (!this.element.nativeElement.children[0].classList.contains('bx-pulsating')) {
						this.element.nativeElement.children[0].classList.add('bx-pulsating')
					}
				}
			}
		});
	}
}
