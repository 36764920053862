

// from https://github.com/ngx-translate/core/blob/master/packages/core/lib/util.ts
export function isObject(item: any): boolean {
	return (item && typeof item === 'object' && !Array.isArray(item));
}
// from https://github.com/ngx-translate/core/blob/master/packages/core/lib/util.ts
export function mergeDeep(target: any, source: any): any {
	let output = Object.assign({}, target);
	if (isObject(target) && isObject(source)) {
	  Object.keys(source).forEach((key: any) => {
		 if (isObject(source[key])) {
			 if (!(key in target)) {
			  Object.assign(output, {[key]: source[key]});
			} else {
			  output[key] = mergeDeep(target[key], source[key]);
			}
		 } else {
			Object.assign(output, {[key]: source[key]});
		 }
	  });
	}
	return output;
}