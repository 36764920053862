import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Directive, inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { Company } from 'src/app/core/models/troly/company.model';
import { User } from 'src/app/core/models/troly/user.model';
import { environment } from 'src/environment/environment';
import { uuid } from '../models/utils.models';
import { WindowService } from './window.service';

@Directive({
	providers: [ HttpClient, WindowService ]
})
export abstract class TrolyApi {

	/**
	 * The name or identifier of the current class, not otherwise available when running in "production mode". 
	 * It is used to output debugging information on the console, and also attached to translations of labels, product tours, etc
	 */
	public readonly __name: string = 'TrolyApi';

	/**
	 * 
	 */
	protected apiBaseUrl;

	/**
	 * The UI nofifier sends "user preferences changed" notification, anything which is used locally to render certain ui elements, or in a certain way, and cached.
	 */
	public uiNotifier$: BehaviorSubject<{}>;

	/**
	 * The DATA notifier sends "data changed" notifications. Data changed is cached records/lists of records, that will be refreshed via API calls.
	 */
	public dsNotifier$: BehaviorSubject<{}>;


	protected httpClient: HttpClient = inject(HttpClient);
	/**
	 * 
	 * @param httpClient 
	 */
	constructor() {

		let db = JSON.parse(window.localStorage.getItem(this.STORAGE_KEYS.USER_CACHE)) || {};
		Object.keys(db).forEach((key) => {
			if (db[key]['expires'] < Date.now()) { // same as this.storedWithExpiry(key), just more effective
				delete db[key];
			}
		})

		this.uiNotifier$ = new BehaviorSubject<{}>(
			Object.keys(db).filter(_ => _.match(/^ui\./)).reduce((_ui, _uiKey) => {
				_ui[_uiKey.replace(/^ui\./, '')] = db[_uiKey]['value'];
				return _ui;
			}, {})
		)
		this.dsNotifier$ = new BehaviorSubject<{}>(
			Object.keys(db).filter(_ => _.match(/^ds\./)).reduce((_ds, _dsKey) => {
				_ds[_dsKey.replace(/^ds./, '')] = db[_dsKey]['value'];
				return _ds;
			}, {})
		)
	}

	/**
	 * 
	 * @param verb 
	 * @param requestUrl 
	 * @param params 
	 * @param body 
	 * @param headers 
	 * @returns 
	 */
	protected call<T>(verb, requestUrl, params?, body?, headers?): Observable<HttpResponse<T>> {
	
		headers ||= {};
		const withCredentials = headers['withCredentials'] != undefined ? headers['withCredentials'] : true;
		delete headers['withCredentials']

		const options = {
			headers: this.apiHeaders(headers),
			observe: 'response' as 'response',
			params: params,
			body: body,
			withCredentials: withCredentials,
		};

		if (this.apiBaseUrl == undefined) {
			const region = localStorage.getItem('db') || 'us-nyc-1';
			this.apiBaseUrl = environment.TROLY_API_URL.replace('//api.', `//api-${region}.`);
		}

		return this.httpClient.request<T>(verb, `${this.apiBaseUrl}${requestUrl}`, options);
	}

	/**
	 * 
	 * @param verb 
	 * @param url 
	 * @param options 
	 * @returns 
	 */
	public httpCall<T>(verb, url, options?: {}): Observable<HttpResponse<T>> {
		return this.httpClient.request<HttpResponse<T>>(verb, url, options);
	}

	/**
	 * Ensures the default API headers required by all Troly API service calls. 
	 * The X-Active-Company allows setting and (in some cases) changing the current active company
	 * The X-CSRF-Token is used to enforce CORS in all mutable (create/update) requests
	 * @param headers 
	 * @returns 
	 */
	private apiHeaders(headers?: any): {} {

		headers = headers || {};

		if (!headers['Accept']) {
			headers['Accept'] = 'application/vnd.troly.co-v3, application/json, text/plain, */*, ';
		}

		if (!headers['Content-Type']) {
			headers['Content-Type'] = 'application/json';
		}

		let co = this.storedCompany();
		if (co && co.id && !headers['X-Active-Company']) {
			headers['X-Active-Company'] = co.id.toString();
		}

		let csrf = this.storedCsrf();
		if (csrf && !headers['X-CSRF-Token']) {
			headers['X-CSRF-Token'] = csrf;
		}

		return headers;
	}

	/**
	 * Defines the localStorage keys used by the services for various purposes.
	 */
	private readonly STORAGE_KEYS = {
		CSRF_TOKEN: 'cs',
		SELECTED_COMPANY: 'co',
		ACTIVE_USER: 'us',
		USER_CACHE: 'uc',
	}

	/**
	 * 
	 * @param csrf 
	 * @returns 
	 */
	public storedCsrf(csrf?: string): string {
		if (csrf) {
			if ((typeof csrf) == undefined) {
				window.localStorage.removetItem(this.STORAGE_KEYS.CSRF_TOKEN);
			} else {
				window.localStorage.setItem(this.STORAGE_KEYS.CSRF_TOKEN, csrf);
			}
		}
		return window.localStorage.getItem(this.STORAGE_KEYS.CSRF_TOKEN);
	}

	/**
	 * 
	 * @param user 
	 * @param action 
	 * @returns 
	 */
	public storedUser(user?: User, action?: string): User {
		action = action || 'logout'

		if (user) {
			if ((typeof user.id) == undefined) {
				window.localStorage.removeItem(this.STORAGE_KEYS.ACTIVE_USER);
			} else {
				if (user instanceof User) {
					window.localStorage.setItem(this.STORAGE_KEYS.ACTIVE_USER, JSON.stringify(user.toLocalStorage(action)));
				} else {
					console.warn(`storedUser has not updated, data received is NOT instanceof User`, user)
				}
			}
		}
		let result = window.localStorage.getItem(this.STORAGE_KEYS.ACTIVE_USER);
		return result ? new User(JSON.parse(result)) : new User();
	}

	/**
	 * 
	 * @param company 
	 * @param action 
	 * @returns 
	 */
	public storedCompany(company?: Company, action?: string): Company {

		action = action || 'logout'

		if (company) {
			if ((typeof company.id) == undefined) {
				window.localStorage.removetItem(this.STORAGE_KEYS.SELECTED_COMPANY);
			} else {
				if (company instanceof Company) {
					window.localStorage.setItem(this.STORAGE_KEYS.SELECTED_COMPANY, JSON.stringify(company.toLocalStorage(action)));
				} else {
					console.warn(`storedCompany has not updated, data received is NOT instanceof Company`, company)
				}
			}
		}
		let value = window.localStorage.getItem(this.STORAGE_KEYS.SELECTED_COMPANY);
		return value ? new Company(JSON.parse(value)) : new Company();
	}

	/**
	 * forcefully updates a new value
	 * @param key 
	 * @param value 
	 * @param expiry in hours
	 * @returns 
	 */
	public storeDbKey<T>(key: string, value?: T, expiry: number = 48): T {
		let result = this.storedWithExpiry(`ds.${key}`, value, expiry);
		if (result?.value !== undefined) {
			let o = this.dsNotifier$.value || {}; 
			o[key] = result.value;
			this.dsNotifier$.next(o)
		}
		return result ? (result.value as T) : null;
	}

	/**
	 * Checks and retrieve or returns default value
	 * @param key 
	 * @param value 
	 * @returns 
	 */
	public cachedDbKey<T>(key: string, defaultValue?: T): T {
		let result = this.storedWithExpiry(`ds.${key}`, undefined, 0);
		return result ? (result.value as T) : defaultValue;
	}

	/**
	 * @param key forcefully stores a new UI setting
	 * @param value 
	 * @returns 
	 */
	public storeUiKey<T>(key: string, value?: T, expiry_in_hours: number = 720): T {
		let result = this.storedWithExpiry(`ui.${key}`, value, expiry_in_hours);
		if (result?.value !== undefined) {
			let o = this.uiNotifier$.value || {}; 
			o[key] = result.value;
			this.uiNotifier$.next(o)
		}
		return result ? (result.value as T) : null;
	}

	/**
	 * Checks and retrieve or returns default value
	 * @param key 
	 * @param value 
	 * @returns 
	 */
	public cachedUiKey<T>(key: string, defaultValue?: T): T {
		let result = this.storedWithExpiry(`ui.${key}`, undefined, 0);
		return result ? (result.value as T) : defaultValue;
	}

	/**
	 * 
	 * @param key 
	 */
	protected deleteKey(key: string): void {
		this.storedWithExpiry(key, undefined, -1);
	}

	/**
	 * 
	 * @param key 
	 */
	public deleteDbKey(key: string): void {
		this.deleteKey(`ds.${key}`);
	}

	/**
	 * 
	 * @param key 
	 */
	public deleteUiKey(key: string): void {
		this.deleteKey(`ui.${key}`);
	}

	/**
	 * retrieved stored keys matching a certain pattern
	 * @param target 
	 * @returns 
	 */
	private keysToMatch(target: string): string[] {
		let db = JSON.parse(window.localStorage.getItem(this.STORAGE_KEYS.USER_CACHE)) || {}
		return Object.keys(db).filter(_ => _.match(target))
	}

	/**
	 * retrieves all keys patching the target, and increments by a certain value
	 * @param target 
	 * @param increment
	 */
	public incrementUiKey(target: string, increment: number = 1) {
		this.keysToMatch(`^ui.${target}`).forEach((k) => {
			let cache = this.storedWithExpiry(k, undefined, 0); // passing 0 as expiry prevents deletion -- required as we bypass cachedDbKey|cachedUiKey so that we can get the entire object
			let newValue = cache ? (parseInt(cache.value) + increment) : increment
			this.storeUiKey(k.replace(/^ui\./,''), newValue, 48);
		});
	}

	/**
	 * Deletes all stored setting matching a target expression, alternatively, deletes all non-persistent setting
	 * This is used to clear product-list- when receiving an updated Product through sockets, or clearing user-count when a new User is received.
	 * Any key that ends in .persist is not deleted (and also not deleted on logout -- see {TrolyComponent.logout})
	 * @param target 
	 */
	public flushLocalStorage(target?: string) {
		if (target) {
			this.keysToMatch(target).forEach((k) => {
				this.deleteKey(k);
			});
		} else { // when loggin out, we just call flushLocalStorage()
			this.keysToMatch('[a-z]').forEach((k) => {
				if (!k.match(/persist$/)) {
					this.deleteKey(k);
				}
			})
		}
	}

	/**
	 * 
	 * 
	 * storedWithExpiry(k) => retrieves if exists, checks for expiry
	 * storedWithExpiry(k, v) => sets to v
	 * storedWithExpiry(k, ?, <0 ) => forcefully deletes
	 * * storedWithExpiry(k, ?, <0 ) => forcefully deletes
	 * @param key 
	 * @param value 
	 * @param expiry 
	 * @returns 
	 */
	private storedWithExpiry(key: string, value: any = '-', expiry: number = -1): { value: any, expires: number } {

		let mode = (value == '-' && expiry < 0) ? 'delete' : (value != '-' && expiry > 0 ? 'set' : 'get');

		//let notify: boolean = false;
		let result = null;

		let db = JSON.parse(window.localStorage.getItem(this.STORAGE_KEYS.USER_CACHE)) || {}

		if (mode == 'set') {
			result = {
				value: value,
				expires: (Date.now() + (expiry * 60 * 60 * 1000))
			}
			db[key] = result;
			window.localStorage.setItem(this.STORAGE_KEYS.USER_CACHE, JSON.stringify(db));
			//notify = true;
		} else if (db[key]) {
			if (mode == 'delete' || db[key]['expires'] < Date.now()) {
				delete db[key]
				window.localStorage.setItem(this.STORAGE_KEYS.USER_CACHE, JSON.stringify(db));
				//notify = true;
			} else {
				result = db[key];
			}
		}

		return result;
	}

	/** 
	 * External related API calls. Should be used exceptionally
	 */
	public externalGet(requestUrl, params?, additionalOptions?, headers?, method?): Observable<any> {

		let options = {
			headers: new HttpHeaders(headers),
			observe: 'response' as 'response',
			params: params,
		};

		if (additionalOptions) {
			options = { ...options, ...additionalOptions };
		}

		if (!method || method == 'get') {
			return this.httpClient.get<any>(requestUrl, options);

		} else if (method == 'delete') {
			return this.httpClient.delete<any>(requestUrl, options);

		} else if (method == 'put') {
			delete options['params'];
			return this.httpClient.put<any>(requestUrl, params, options);

		} else if (method == 'post') {
			delete options['params'];
			return this.httpClient.post<any>(requestUrl, params, options);

		}
	}

	/**
	 * 
	 * @param size 
	 * @returns 
	 */
	genRanHex = size => [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');
	genUUID = ():uuid => `${this.genRanHex(8)}-${this.genRanHex(4)}-${this.genRanHex(4)}-${this.genRanHex(4)}-${this.genRanHex(12)}`;
}
