import { Component, Input, SimpleChanges } from '@angular/core';
import { ApexAxisChartSeries, ApexXAxis } from 'ng-apexcharts';
import { TChartComponent } from 'src/app/core/components/chart.component';

@Component({
    selector: 'bar-chart-widget',
    templateUrl: './bar-chart.widget.html',
    styleUrls: ['./bar-chart.widget.scss'],
})
export class BarChartWidget extends TChartComponent {

	@Input() series : ApexAxisChartSeries;
	@Input() xaxis : ApexXAxis;
	@Input() height : number;

	 constructor() { 

			 super('bar');

			 this.chartOptions.series = []
		}

		protected receiveInputParams(changes: SimpleChanges): void {

			if (changes.series && changes.series.currentValue ) {
				this.chartOptions.series = changes.series.currentValue
			}
			if (changes.xaxis && changes.xaxis.currentValue ) {
				this.chartOptions.xaxis = changes.xaxis.currentValue
			}
			if (changes.height && changes.height.currentValue ) {
				this.chartOptions.chart.height = changes.height.currentValue
			}
		}
}
