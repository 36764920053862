import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FileItem, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import { TrolyComponent } from 'src/app/core/components/troly.component';
import { CloudinaryService } from '../../../core/services/cloudinary.service';

/** Documentation from Cloudinary:
 * Upload using form: https://cloudinary.com/documentation/upload_images#example_1_upload_multiple_files_using_a_form_unsigned
 *
 */
@Component({
	selector: 'app-uploader',
	templateUrl: './uploader.component.html',
	styleUrls: ['./uploader.component.scss'],
	encapsulation: ViewEncapsulation.None, // allows css styles from uploader to be made available outside of the template itself
})
export class UploaderComponent extends TrolyComponent implements OnInit, OnChanges {

	override readonly __name: string = 'UploaderComponent';

	public uploader: FileUploader;
	public hasDropHover: boolean = false;
	public progress: number;
	public status: 'pending' | 'uploading' | 'done' | 'error';

	@ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;

	@Input() tags?: string;
	@Input() allowedMimeTypes: string = 'image/png,image/jpg,image/jpeg';
	@Input() multiple?: boolean = false;

	/**
	 * The path to which the file will be uploaded, within the company folder.
	 */
	@Input() destinationPath: string = '';

	/**
	 * The desired filename (without extension) of the file to be uploaded.
	 */
	@Input() destinationFile: string = '';

	public responses: Array<any> = [];

	@Output() selectCallback = new EventEmitter();
	@Output() successCallback = new EventEmitter();

	public cloudinaryService: CloudinaryService = inject(CloudinaryService);

	constructor(
		private sanitizer: DomSanitizer
	) {
		super();
		this.service = this.companyService
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.progress = -1;
		this.status = 'pending';
	}

	ngOnInit(): void {

		this.uploader = new FileUploader(this.cloudinaryService.getUploaderOptions(this.allowedMimeTypes));

		this.uploader.onAfterAddingFile = (fileItem) => {

			this.status = 'uploading';
			this.progress = 0;

			let file = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(fileItem._file));
			
			if (this.selectCallback) {
				this.selectCallback.emit({ file: fileItem, tmpPath: file });
			}
		};

		this.uploader.onBuildItemForm = (fileItem: FileItem, form: FormData): any => {
			
			let params_to_sign = {
				public_id: this.public_id(fileItem.file.name),
				folder: this.destinationFolder(),
				tags: this.tags.split(',')
			}
			
			const signature = this.cloudinaryService.generateSignature(params_to_sign);

			Object.keys(params_to_sign).forEach(key => {
				form.append(key, params_to_sign[key]);
			});

			form.append('api_key', signature.apiKey);
			form.append('file', fileItem as any);
			form.append('secure', 'true');
			form.append('signature', signature.hash);

			// we need to set this or we will be locked by CORS
			fileItem.withCredentials = false;

			return { fileItem, form };
		};

		this.uploader.onSuccessItem = (item: any, response: string, status: number) => {
			const resp = JSON.parse(response);

			const imagePath = resp.secure_url.split(`${this.cloudinaryService.cloudName}/`).pop();

			if (this.successCallback) {
				this.successCallback.emit({ 
					path: imagePath,
					url: resp.secure_url, 
					publicId: resp.public_id,
					uploader: 'cloudinary',
					response: resp });
			}
		};

		// Update model on completion of uploading a file
		this.uploader.onCompleteItem = (item: any, response: string, status: number, headers: ParsedResponseHeaders) => {
			//this.updateResponse({ file: item.file, status, data: JSON.parse(response) });
			this.status = status == 200 ? 'done' : 'error';
			this.progress = -1;
		};

		// Update model on upload progress event
		this.uploader.onProgressItem = (fileItem: any, progress: any) => {
			if (progress == 100 && this.progress == 0) {

				// this here is just a dity trick for making up a progress bar when the file was already uploaded previously (upload cached with by the browser)
				this.progress = 40;
				setTimeout(() => { this.progress = 65; }, 550);
				setTimeout(() => { this.progress = 100; }, 1250);
			} else {
				//this.updateResponse({ file: fileItem.file, progress, data: {} });
				this.progress = progress;
			}
		};
	}

	private public_id(uploadedName:string): string {
		if (this.destinationFile) {
			return this.destinationFile;
		} else {
			const newName = uploadedName.split('.'); newName.pop();
			return newName.join('.');
		}
	}

	private destinationFolder(): string {
		if (this.destinationPath) {
			return `companies/${this.selectedCompany.id}/${this.destinationPath}`;
		} else {
			return `companies/${this.selectedCompany.id}`;
		}
	}

	fileOverBase(e: any): void {
		this.hasDropHover = e;
	}

}
