import { ValidatorFn, Validators } from "@angular/forms";
import { TrolyObject } from "../troly_object";
import { uuid } from "../utils.models";



/** Notes on interfaces vs clases 
 * Angular will not automatically type cast responses from httpClient into an object.
 * Normall an interface 'acts as a contract' and allows for typescript to accurately check types on compile.
 * However interfaces don't have reusable methods, which translates to typ-ecasting from httpClient responses 
 * to only enforce object attributes, not methods so we need to lean on a 'helper' or 'handler' or 'Model' class to do that, and that needs to be type-casted by hand
 * 
 * see: https://stackoverflow.com/questions/50693272/angular-casting-httpclient-response-class-or-interface
 * 
 * Finally, given httpClient will not 'type-cast' into a class, but the compiler will use the class definitition like it does an interface.
 * so there's no real advantades to using an interface in our case: if we want methods on objects returned through httpclient calls, we need to manually cast into a class of any sort.
 * 
 * The only problem left is the lack of a recognition of an interface (TrolyObject) being implemented by a parent class (TrolyObject) and not recognised as 'implemented' by the child class.
*/
export class Carton extends TrolyObject {

	/** Internal name for humans to recognise/identify this packaging */
	declare name: string;
	_name: ValidatorFn[] = [Validators.required];

	/** Maximum, outer width for this packaging */
	declare external_width: number;
	_external_width: ValidatorFn[] = [Validators.required];

	/** Maximum, outer heignt for this packaging */
	declare external_height: number;
	_external_height: ValidatorFn[] = [Validators.required];

	/** (optional) Maximum, outer depth for this packaging, if left empty, the packaging is interpreted as a bag, with limited shipping volume / capability */
	declare max_depth: number;
	_max_depth: ValidatorFn[] = [Validators.required];

	/** Maximum CARRYING capacity for the packaging */
	declare max_weight: number;

	/** Maximum number of products that can fit in this packaging, also capped by volume */
	declare max_units: number;
	_max_units: ValidatorFn[] = [Validators.required];

	/** Number of "small" or "soft/flexible" items that can be fitted in. This allows allocating X additional products which are labeled as "squeezable" */
	declare extra_units: number
	_extra_units: ValidatorFn[] = [Validators.min(0), Validators.max(6), Validators.maxLength(1)];

	/** The actual packaging weight, excluding any content */
	declare carton_weight: number
	/** Cardboard thickness -- used to calculate the carton weight */
	declare gsm: number
	/** Cardboard density -- used to calculate the carton carrying capacity */
	declare density: number

	/** (optional) Internal storage width, used for calculating how many products fit in (eg. for packaging that is quite thick) */
	declare internal_width: number
	/** (optional) Internal storage depth, used for calculating how many products fit in (eg. for packaging that is quite thick) */
	declare external_depth: number
	/** (optional) Internal storage height, used for calculating how many products fit in (eg. for packaging that is quite thick) */
	declare internal_height: number


	/** How many of these are available to use */
	declare inventory: number
	/** Internal notes to staff on how to use/locate/handle this packaging */
	declare notes: string

	/** List of product ids allocated to this carton (only when stored against an order) */
	declare readonly content: uuid[]

	/** Calculated overall shipping weight, including content and packaging (only when stored against an order) */
	declare readonly shipping_weight: number;

	/** Allocated packaging volume (only when stored against an order) */
	declare readonly allocated_volume: number;

	constructor(values?: Object) {
		super('carton', values);
	}

}
