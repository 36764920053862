import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mini-nav-widget',
  templateUrl: './mini-nav.widget.html',
  styleUrls: ['./mini-nav.widget.scss']
})
export class MiniNavWidget {

	_focusOn!:string;
	get focusOn() { return this._focusOn; }

	@Input() menus:MiniNavWidgetMenu[]=[];

	// two way binding -- https://angular.io/guide/two-way-binding
	// input must be `whatever` and output must be `whateverChange`
	@Output() selectedChange: EventEmitter<string> = new EventEmitter<string>();
	@Input('selected') set focusOn(value) {
		if (this._focusOn !== value) {
			this._focusOn = value;
			this.selectedChange.emit(this._focusOn)
		}
	}
}

export interface MiniNavWidgetMenu {
	label:string;
	icon:string;
	disabled?:boolean;
	visible?:boolean;
	statusIcon?:string;
	value:string;
}