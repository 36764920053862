import { inject, Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, filter, Subject } from 'rxjs';


const duration = 15000;

@Injectable({
	providedIn: 'root',
})
export class WindowService {

	/**
	* The name or identifier of the current class, not otherwise available when running in "production mode". 
	* It is used to output debugging information on the console, and also attached to translations of labels, product tours, etc
	*/
	public readonly __name: string = 'WindowService';
	
	
	private snackBar: MatSnackBar = inject(MatSnackBar);
	private messageTimeout;

	indicator = new BehaviorSubject<string>('');
	pendingChanges:number=0;

	public layoutActionNotifier = new BehaviorSubject(null);

	/**
	 * 
	 */

	public onAnyModalClose$: Subject<[any, any]> = new Subject<[any, any]>();

	private setClearTimer() {
		if (this.messageTimeout) {
			clearTimeout(this.messageTimeout);
		}

		this.messageTimeout = setTimeout(() => {
			this.indicator.next('');
		}, duration);
	}

	saving(message?: string) {
	  this.pendingChanges = this.pendingChanges + 1;
	  this.indicator.next(message || `Saving ${'.'.repeat(this.pendingChanges)}`);
	  this.setClearTimer();
	}

	saved(message?: string) {
	  this.pendingChanges = this.pendingChanges - 1;
	  if (this.pendingChanges == 0) {
		this.indicator.next(message);
		this.setClearTimer();
		return true;
	  } 
	  return false;
	}

	failed(message?: string) {
	  this.pendingChanges = this.pendingChanges - 1;
	  this.indicator.next(message || 'Could not save changes');
	  //this.setClearTimer();
	}

	// @description Open a material snack-bar with a message and optional action
	// @param message [String] the message to display on the snackbar
	// @param style [String] the styling of the snackbar, e.g. success, error, warning
	// @param manualDismiss [Boolean] whether or not the snackbar should auto dismiss
	// @param actionCallback [Function] a function to run when the snackbar action is clicked
	// @param actionString [String] the action button string
	openSnackBar(message: string, style?:'success'|'error'|'warning'|'none', manualDismiss?: boolean, actionCallback?: Function, actionString?: string) {
	  // set the config up
	  let config: MatSnackBarConfig = {
		//horizontalPosition: 'left',
		verticalPosition: 'top',
		panelClass: ['troly-snackbar-x', `troly-snackbar-${style}`],
	  };

	  // if we aren't dismissable then auto dismiss after 5 seconds
	  if (manualDismiss) {
		// TODO - enable manual dismiss, requires some extra logic
		// See material link above 'Dismissal' header
	  } else {
		config.duration = 3000;
	  }

	  // open returns a ref to the snackbar which we need to use
	  // if an action was passed in
	  let snackBarRef = this.snackBar.open(message, actionString, config);

	  // if we have an action then we subscribe to 'onAction'
	  // this is triggered when the snackbar is closed by any means
	  // e.g. action is clicked or duration expires
	  if (actionString) {
		snackBarRef.onAction().subscribe(() => {
			actionCallback();
		});
	  }
	}

	recordUpdateNotification(message: string, url?: string) {
		let snackBarRef = this.snackBar.open(message, (url ? 'View' : ''), {
		  duration: 90000,
		  verticalPosition: 'bottom',
		  horizontalPosition: 'right',
		  panelClass: ['troly-snackbar-x', `troly-snackbar-bottom`],
		});

		snackBarRef.onAction().subscribe(() => {
			debugger;
			document.location = url;
		});
	 }


	public currentCardTargeted$: BehaviorSubject<string> = new BehaviorSubject("");
	public currentFieldTargeted$: BehaviorSubject<string> = new BehaviorSubject("");
	protected route: ActivatedRoute = inject(ActivatedRoute);

	constructor() {
		
		this.route.fragment.pipe(filter(_ => !!_)).subscribe((_) => {
			const parts = _.split('-');
			this.currentCardTargeted$.next(parts[0]);

			if (parts.length > 1) {
				this.currentFieldTargeted$.next(parts[1]);
			} else {
				this.currentFieldTargeted$.next("");
			}

		});

	}
	
	public currentModal?: NgbModalRef; 
	protected modalService: NgbModal  = inject(NgbModal); // allows opening and interacting with modals in a TrolyComponent-standard fashion */

	public openModal(template, options?: any): boolean {
		this.currentModal = this.modalService.open(template, options);

		this.currentModal.result.then((result) => {
			this.onAnyModalClose$.next([template,result]);
		}, (reason) => { 
			this.onAnyModalClose$.next([template,reason]);
		});
		return this.modalService.hasOpenModals();
	}

	public clearModal(): void {
		if (this.currentModal) { 
			this.currentModal.dismiss(); 
		}
	}
	public readyToOpen(): boolean {
		return !this.modalService.hasOpenModals();
	}

}
