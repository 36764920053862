import { Component } from '@angular/core';
import { BaseLayout } from './base.layout';

@Component({
  selector: 'public-layout',
  templateUrl: './public.layout.html',
  styleUrls: ['./public.layout.scss']
})
export class PublicLayout extends BaseLayout {

}
