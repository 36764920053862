<ng-container *ngIf="layout == 'vertical'">
   <div class="text-center size-{{size}}">
      <i *ngIf="loading" class="mb-2 bx bx-loader-alt bx-spin text-primary"></i>
      <i *ngIf="!loading" class="mb-2 bx {{ icon }}"></i>
      <h3 *ngIf="title && !loading" [innerHTML]="title"></h3>
      <h3 *ngIf="title && loading">&nbsp;</h3>
		<p *ngIf="description" [ngClass]="{
			'font-size-12': size == 'small',
			'font-size-16': size == 'medium',
			'font-size-20': size == 'large'
		}">{{description}}</p>
		<ng-content></ng-content>
   </div>
</ng-container>

<ng-container *ngIf="layout == 'horizontal'">
   <div class="text-left size-{{size}}">
      <div class="float-start">
         <i *ngIf="loading" class="bx bx-loader-alt bx-spin text-primary"></i>
         <i *ngIf="!loading" class="bx {{ icon }}"></i>
      </div>
      <h3 *ngIf="!loading" [innerHTML]="title"></h3>
      <h3 *ngIf="loading">&nbsp;</h3>
		<p *ngIf="description" [ngClass]="{
			'font-size-12': size == 'small',
			'font-size-16': size == 'medium',
			'font-size-20': size == 'large'
		}">{{description}}</p>
   </div>
</ng-container>