import { AfterViewChecked, Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
	selector: '[orderable]'
})
export class OrderableDirective implements AfterViewChecked {

	constructor(private renderer: Renderer2, private host: ElementRef) { }

	ngAfterViewChecked() {
		const children: HTMLElement[] = Array.from(this.host.nativeElement.children);
		if (children) {
			this.host.nativeElement.replaceChildren(...this.sortChildren(children));
		}
	}

	protected sortChildren(elements: HTMLElement[]) {

		const sortedElements: HTMLElement[] = [];
		let sortedButtonsElements: HTMLElement[] = [];
		let sortCurrentH6: boolean=false;

		elements.forEach(element => {
			if (element.tagName === 'H6') {
				
				// everytime we encounter an H^, dump the previous children, if any
				sortedButtonsElements.forEach(button => {
					sortedElements.push(button);
				})

				// push the divider and reset the buttons accumulator
				sortedElements.push(element);
				sortedButtonsElements = [];
				sortCurrentH6 = !element.classList.contains('not-orderable');

			} else if (element.tagName === 'BUTTON') {

				sortedButtonsElements.push(element);
				if (sortCurrentH6) {
					sortedButtonsElements.sort(this.sortItems);
				}

			}
		});

		// once done, dump the previous children, if any
		sortedButtonsElements.forEach(button => {
			sortedElements.push(button);
		})

		return sortedElements;
	}

	private sortItems(a, b) {
		return a.textContent.toLowerCase() > b.textContent.toLowerCase() ? 1 : -1;
	}
}