import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CloudinaryModule } from '@cloudinary/ng';
import { NgbAlertModule, NgbDatepickerModule, NgbModule, NgbNavModule, NgbPaginationModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { EditorModule } from '@tinymce/tinymce-angular';
import { NgApexchartsModule } from 'ng-apexcharts';
import { FileUploadModule } from 'ng2-file-upload';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { NgScrollbarModule } from 'ngx-scrollbar';


@NgModule({
	declarations: [],
	imports: [

		CommonModule,

	],

	exports: [

		FormsModule,
		ReactiveFormsModule, // allows binding [formGroup] to forms..

		FileUploadModule,

		TranslateModule,

		RouterModule, // allows to use the routerLink attribute

		//		ClickOutsideModule,
		//		PerfectScrollbarModule, // although this is not stritcly angular..
		EditorModule, // also not strightly angular -- tinymce

		// Angular Bootstrap components;
		NgbNavModule,
		NgbTooltipModule,
		NgbDatepickerModule,
		NgbPaginationModule,
		NgbAlertModule,
		NgbModule,

		// Charting modules
		//		NgChartsModule,
		NgApexchartsModule,

		CloudinaryModule,

		NgScrollbarModule, // used as a replacement of perfect-scrollbar, allows for better handling of container scroll events and styling

		NgxBarcode6Module,
		
		CommonModule, // we want this to become available to every module importing AngularModule

	],
	providers: []
})
export class TrolyAngularModule { }