import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { take } from 'rxjs/operators';

import { AuthService } from 'src/app/core/services/auth.service';
import { CompanyService } from '../services/troly/company.service';

@Injectable({
    providedIn: 'root',
})
export class CompanyLockedGuard  {

	protected companyService: CompanyService = inject(CompanyService);

	constructor(private router: Router, private authService: AuthService) {}

	// angular definition to lock routes that require authentication
	// returns either true or false depending on if the active company is locked
	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		
		let selectedCompany;

		// get the active company from our authenticated user
		this.companyService.record$.pipe(take(1)).subscribe((company) => {
			selectedCompany = company;
		});

		// if its locked then redirect to the billing page
		if (selectedCompany.is_locked_at) {
			//this.router.navigate(['/settings/billing']);
			//return false;
		}

		// not locked so return true
		return true;
	}
}
