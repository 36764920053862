import { Injectable } from '@angular/core';

import { Product, ProductWarehouse } from '../../models/troly/product.model';
import { ITrolyService, TrolyService } from './troly.service';

import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { TrolySearch } from '../../models/form_objects';
import { Award } from '../../models/troly/award.model';
import { StockStat } from '../../models/troly/stats.model';
import { ProductTag, Tag } from '../../models/troly/tag.model';
import { uuid } from '../../models/utils.models';
import { ITaggableService, TaggableModule } from './modules/taggable.module';

@Injectable({
	providedIn: 'root',
})

/*
  This class is in charge of all loading and unloading of a tag's profile.

  There can only ever be a single profile loaded for a given app instance.

  i.e. if you load an order, it will load and set the current tag to the tag in question
*/
export class ProductService extends TrolyService<Product> implements ITaggableService<ProductTag>,  ITrolyService<Product> {

	/**
	 * The name or identifier of the current class, not otherwise available when running in "production mode". 
	 * @see TrolyService.__name
	 */
	override readonly __name:string = 'ProductService';

	/**
	 * And provides additional (reused) functions for dealing with tags within the context of a Customer
	 */
	public taggableModule: TaggableModule = new TaggableModule(this);

	constructor() { super('products'); }

	/**
	 * Is responsible for updating a tag attached to this a Product record.
	 * @param verb 
	 * @param id 
	 * @param obj 
	 * @param record 
	 * @returns 
	 */
	public updateTag(verb, id:uuid, obj?:ProductTag): Observable<ProductTag> {
		const method = `${obj?.product_id ? obj.product_id + '/' : ''}tags/${id}`;
		// this uses a call (instead of create/save/delete) to more easily handle every operations in one call. Also must handles a raw api HttpResponse 
		return this.call<ProductTag>(verb, null, method).pipe(map(_ => new ProductTag(_.body['product_tag'])))
	}

	public searchTags(params?: {}, record?: Product): Observable<TrolySearch<Tag>> {

		let obj = this.make({id: params['product_id'] || record?.id })
		delete params['product_id']

		return this.call<TrolySearch<Tag>>('get', obj, 'tags/search', params).pipe(map(_ => {
			let search = new TrolySearch<Tag>(_.body, 'tags');
			search.results = search.results.map(_ => new Tag(_));
			return search;
		 }))
	}

	public makeObjectTag(payload: {}): ProductTag { return payload instanceof ProductTag ? payload : new ProductTag(payload); }
	
	public make(payload: {} = {}): Product { return payload instanceof Product ? payload : new Product(payload); }

	public createAward(payload: Award, record?:Product): Observable<Award> {
		return this.post(payload, `${record?.id ||  this.record$.value.id}/awards`).pipe(map(_ => new Award(_.body['award'])));
	}
	public removeAward(id: uuid, record?:Award): Observable<Award> {
		return this.delete(new Award({ id: record?.id || this.record$.value.id }), `/awards/${id}`).pipe(map(_ => new Award(_.body['award'])))
	}

	/**
	 * 
	 * @param service 
	 * @returns 
	 */
	public loadProductTags(params?: {}, record?: Product): Observable<ProductTag[]> {

		params ||= {}
		record ||= this.record$.value

		let obj = this.make({id: params['product_id'] || record.id })
		delete params['product_id']

		const force = record && record.isStale('product_tags')

		if (!force && record.product_tags) { return of(record.product_tags) }

		return this.getList<ProductTag>(obj, 'tags', params, force, 'product_tags').pipe(filter(_ => !!_), map(list => list.map(o => new ProductTag(o)) ))
	}

	/**
	 * 
	 * @param service 
	 * @returns 
	 */
	public loadWarehouses(params?: {}, record?: Product): Observable<ProductWarehouse[]> {

		params ||= {}
		record ||= this.record$.value

		let obj = this.make({id: params['product_id'] || record.id })
		delete params['product_id']

		const force = record && record.isStale('product_warehouses')

		if (!force && record.product_warehouses) { return of(record.product_warehouses) }

		return this.getList<ProductWarehouse>(obj, 'warehouses', params, force, 'product_warehouses').pipe(filter(_ => !!_), map(list => list.map(o => new ProductWarehouse(o)) ))
	}

	/**
	 * 
	 * @param service 
	 * @returns 
	 */
	public loadCategories(params?: {}): Observable<{name:string,count:number}[]> {

		let force=false
		return this.getList<{name:string,count:number}>(new Product(), 'categories', params, force).pipe(filter(_ => !!_), map(list => list))
	}

	public loadStockStats(params?: {}, record?: Product): Observable<StockStat[]> {
		
		params ||= {}
		record ||= this.record$.value
		
		params['limit'] = params['limit'] || 2
		params['range'] = params['range'] || 'month'

		let obj = this.make({id: params['product_id'] || record?.id })  // in order to load different warehouses or products, or else, we must explicitly set in params
		delete params['product_id']

		const force = record && record.isStale('stats')
		
		return this.getList<StockStat>(obj, 'stats', params, force, 'stock_stats').pipe(filter(_ => !!_)).pipe(map(list => list.map(o => new StockStat(o)) ))
	}


	public saveProductWarehouse(payload:ProductWarehouse, method?: string): Observable<ProductWarehouse> {

		if (payload.warehouse_id) {
			method ||= `${this.record$.value.id}/warehouses/${payload.warehouse_id}`;

			delete payload['warehouse_id'];
		}

		return this.put(payload, method, { 'with_warehouse': true }).pipe(map(_ => new ProductWarehouse(_.body['product_warehouse'])))
	}

}
