import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ApexAxisChartSeries, ApexXAxis } from 'ng-apexcharts';
import { TChartComponent } from 'src/app/core/components/chart.component';

@Component({
	selector: 'datapoints-with-charts-card',
	templateUrl: './datapoints-w-charts.card.html',
	styleUrls: ['./datapoints-w-charts.card.scss']
})
export class DatapointsWithChartsCard extends TChartComponent implements OnChanges {

	@Input('heading') heading:string;
	@Input('loading') loadingstatus:string='';
	@Input('helpCollection') helpCollection:string;

	@Input('icon') icon:string;
	@Input('cardClass') cardClass:string;

	@Input('numberOne') numberOne:TrolyWidgetDatapoint;
	@Input('numberTwo') numberTwo:TrolyWidgetDatapoint;

	@Input('chartData') chartData:ApexAxisChartSeries;
	@Input('chartRange') chartRange:ApexXAxis;
	@Input('chartHeight') chartHeight:number=65;

	constructor() { 
		super('area');
		
		//this.chartOptions.chart.height = this.chartHeight;
		this.chartOptions.chart.sparkline = {enabled:true}; // "makes the chart ultra 'simplified'"
		this.chartOptions.colors = [this.COLOURS.LIGHT]

    }
	
	ngOnChanges(changes: SimpleChanges): void {
		this.chartOptions.chart.height = this.chartHeight;
	}

}

export interface TrolyWidgetDatapoint {
	value:number,
	unit?:string,
	icon?:string,
	variation?:number,
	variation_unit?:string,
	variation_less_is_best?:boolean,
	legend?:string
}