import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import * as CryptoJS from "crypto-js";
import { uuid } from '../../models/utils.models';

@Injectable({
  providedIn: 'root'
})
export class AvatarService {

	protected cache:{};
	public DefaultAvatarSize:number = 72;
	private date = new Date();
	private expiry;

	constructor(
		private http: HttpClient,
	) { 

		this.cache = JSON.parse(localStorage.getItem('av') || '{}')
		this.expiry = this.date.setDate(this.date.getDate() + 1);
	}

	public url(id: uuid, email: string, size: number = this.DefaultAvatarSize): Promise<string> {

		let promise = new Promise<string>((resolve, reject) => {

			let _cache = this.getCache(id) || '';
			switch (_cache[0]) {
				case 'G':  resolve(this._gravatarUrl(_cache.substring(1),size)); break;
				//case 'none': resolve(''); break;
			}

			if (email) {
				this.checkGravatar(email).then((_uuid:string) => {
					if (_uuid) { 
						this.updateCache(id, `G${_uuid}`);
						resolve(this._gravatarUrl(_uuid, size)); 
					}
				});
			} else {
				return '';
			}

		});
		return promise;
	}

	protected checkGravatar(email:string): Promise<string> {

		const emailHash = CryptoJS.MD5(email.toLowerCase());

		let options = {
			observe: 'response' as 'response',
		};

		let url = this._gravatarUrl(emailHash,16);
		return new Promise((resolve) => {
			this.http.request('get', url, { responseType:'blob' }).subscribe({
				next: (_response) => {
					if (_response.size != 888) { // the default size for the 16px placeholder returned by gravatar is 888bytes
						resolve(emailHash)
					} 
					resolve(null);
				},
				error: (_err) => { resolve(null); } // any errors reported is assumed as not not available.
			});
		});
	}
	protected _gravatarUrl(emailHash:string, size:number): string {
		//const emailHash = CryptoJS.MD5(email.toLowerCase());
		return `https://www.gravatar.com/avatar/${emailHash}?s=${size}`;
	}


	protected getCache(id:uuid):string {
		const idHash = CryptoJS.MD5(id.toString());
		if (this.cache[idHash]) { 
			if (this.cache[idHash].expires >= this.date.getDate()) {
				return this.cache[idHash].value
			} else {
				// clear the cache if we have a value that has expired.
				delete this.cache[idHash];
				localStorage.setItem('av', JSON.stringify(this.cache));
			}
		}
		return null;
	}

	protected updateCache(id:uuid, value?) {
		const idHash = CryptoJS.MD5(id.toString());
		this.cache[idHash] = { value:value, expires: this.expiry };
		localStorage.setItem('av', JSON.stringify(this.cache));
	}
}