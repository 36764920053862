import { Component } from '@angular/core';

@Component({
	selector: 'order-tracking',
	templateUrl: './order-tracking.widget.html',
	styleUrls: ['./order-tracking.widget.scss'],
})
export class OrderTrackingWidget {

	
}