<apx-chart
    class="apex-charts"
    dir="ltr"
    [series]="chartData.series"
    [chart]="chartData.chart"
    [colors]="chartData.colors"
    [plotOptions]="chartData.plotOptions"
    [dataLabels]="chartData.dataLabels"
>
</apx-chart>
