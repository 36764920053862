import { Component, Input } from '@angular/core';

import { TrolyCard } from 'src/app/core/components/troly.card';
import { Product } from 'src/app/core/models/troly/product.model';
import { uuid } from 'src/app/core/models/utils.models';

@Component({
    selector: 'recent-card',
    templateUrl: './recent.card.html',
    styleUrls: ['./recent.card.scss']
})
export class RecentRecordsCard extends TrolyCard {

    @Input() company_id:uuid;

    @Input() maxRecords:number=9;

    @Input() showAccessTime:boolean=true;

    @Input() showLabel:boolean=true;

    @Input() modelName:'customer'|'product'; 

	 @Input() helpCollection:string;
	 @Input() cssClass:string
	 @Input() header:string

    recent_records:any[]=[];
    
    ngOnInit(): void {
        this.recent_records = this.recentService.fetchList(this.modelName, this.company_id);
    }

	private product = new Product();
	getProductIcon(variety:string):string {
		return this.product.getIcon(variety)
	}
}