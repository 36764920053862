import { AfterViewInit, Component, Input } from "@angular/core";

@Component({
   selector: 'status-hint',
   templateUrl: './status-hint.component.html',
   styleUrls: ['./status-hint.component.scss'],
})

export class StatusHintComponent implements AfterViewInit {

   @Input() loading:boolean=false;
   @Input() icon:string;
   @Input() title?:string;
	@Input() description?:string;
   
   @Input() size:'small'|'medium'|'large'='medium';
   @Input() layout:'vertical'|'horizontal'='vertical';

   ngAfterViewInit(): void {
      //throw new Error("Method not implemented.");
   }

}
