<div *ngIf="hint" class="card-help-pellet">
    <div class="pellet-wrapper">
        <i class="pellet-icon fas fa-exclamation-circle text-info"></i>
        <div class="pellet-tooltip pellet-tooltip-{{ position }} introjs-tooltip">
            <div class="pellet-content introjs-tooltiptext bg-dark text-light font-size-12"
                [innerHtml]="hint"
                (click)="innerHtmlClickHandler($event)"
            ></div>
        </div>
    </div>
</div>