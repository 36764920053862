import { ValidatorFn, Validators } from "@angular/forms";
import { TrolyObject } from '../troly_object';
import { uuid } from "../utils.models";
import { Carton } from "./carton.model";
import { ProductWarehouse } from "./product.model";
import { StockStat } from "./stats.model";

export class Warehouse extends TrolyObject {

	declare company_id: uuid
	declare business_name: string;
	_business_name: ValidatorFn[] = [Validators.required];

	/** 2-3 characters to identify this warehouse on order number */
	declare code: string;

	declare line1: string;
	declare line2: string;
	declare suburb: string;
	declare state_abbr: string;
	declare postcode: string;
	declare country: string;
	declare phone: string;
	declare alpha2: string

	declare lat: number
	declare lng: number

	declare packaging: Carton[];

	declare customer_access_intructions: string
	declare carrier_pickup_intructions: string

	/**
	 * This record will be 'trashed' on deletion, appear in the trash, and can be restored, or will forcefully be deleted after a period of time, or manually.
	 */
	declare trashed_at: Date
	declare trashed_by_id: uuid
	declare trashed_reason: string

	declare manager_id: uuid

	declare stat?: StockStat // stats are marked as optional -- the code should ensure they are sometimes not generated on the fly and missing.
	declare stats?: StockStat[]
	declare product_warehouses?: ProductWarehouse[]

	declare provider_data: {}

	constructor(values?: Object) {
		super('warehouse', values);

		if (values) {
			let k = 'stat' // when cached/stored in localStorage and not retrieved+constructed, we need to make sure we have correct objects to work with
			if (values[k]) { this[k] = new StockStat(values[k]); }

			k = 'product_warehouses' // when cached/stored in localStorage and not retrieved+constructed, we need to make sure we have correct objects to work with
			if (values[k] && values[k].length) {
				values[k].map((obj,i) => { this[k][i] = new ProductWarehouse(obj) });
			}
		}
	}

	public toString(full:boolean=true) : string {
		//const code = 
		return full ? `${this.business_name} ${this.code ? '('+this.business_name+')' : ''}` : `${this.business_name}`
	}
}