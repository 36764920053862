import { TrolyObject } from '../troly_object';

/** Notes on interfaces vs clases 
 * Angular will not automatically type cast responses from httpClient into an object.
 * Normall an interface 'acts as a contract' and allows for typescript to accurately check types on compile.
 * However interfaces don't have reusable methods, which translates to typ-ecasting from httpClient responses 
 * to only enforce object attributes, not methods so we need to lean on a 'helper' or 'handler' or 'Model' class to do that, and that needs to be type-casted by hand
 * 
 * see: https://stackoverflow.com/questions/50693272/angular-casting-httpclient-response-class-or-interface
 * 
 * Finally, given httpClient will not 'type-cast' into a class, but the compiler will use the class definitition like it does an interface.
 * so there's no real advantades to using an interface in our case: if we want methods on objects returned through httpclient calls, we need to manually cast into a class of any sort.
 * 
 * The only problem left is the lack of a recognition of an interface (TrolyObject) being implemented by a parent class (TrolyObject) and not recognised as 'implemented' by the child class.
*/
export class Message extends TrolyObject {

	/**
	 * 
	 */
	declare channel: 'call'|'sms'|'email'|'social';

	constructor(values?: Object) {
		super('message', values);
	}

}