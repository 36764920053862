import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'circular-chart-widget',
    templateUrl: './circular-chart.widget.html',
    styleUrls: ['./circular-chart.widget.scss'],
})
export class CircularChartWidget implements OnInit {
    @Input() chartColor;
    @Input() members;
    @Input() percentage;

	 @Input() size:number=65;

    chartData;

    constructor() {}

    ngOnInit(): void {
        this.chartData = {
            series: [this.percentage],
            chart: {
                type: 'radialBar',
                width: this.size,
                height: this.size,
                sparkline: {
                    enabled: true,
                },
            },
            dataLabels: {
                enabled: false,
            },
            colors: [this.chartColor],
            plotOptions: {
                radialBar: {
                    hollow: {
                        margin: 0,
                        size: '60%',
                    },
                    track: {
                        margin: 0,
                    },
                    dataLabels: {
                        show: true,
                        name: {
                            show: false,
                        },
                        value: {
                            show: true,
                            color: '#74788d',
                            offsetY: 5,
									 fontSize: this.size >= 60 ? '13px' : '10px',
                            formatter: (val) => {
                                return this.members;
                            },
                        },
                    },
                },
            },
        };
    }
}
