import { Company } from "./troly/company.model";
import { Customer } from "./troly/customer.model";
import { Order } from "./troly/order.model";
import { Product } from "./troly/product.model";
import { Task } from "./troly/task.model";
import { User } from "./troly/user.model";
import { TrolyObject } from "./troly_object";
import { uuid } from "./utils.models";

export class TrolyIndexedObject extends TrolyObject {

	declare heading:string;
	declare subheading:string;
	
	declare highlights:{
		heading?:string,
		subheading?:string
	};

	declare metadata: JSON

	declare primary_key: uuid;
	declare class_name: 'Customer'|'Product'|'Order'|'Task'|'User'
	declare model: Customer|Product|Order|Task|User

	company_ids: uuid[]
	tokens: string[]

	constructor(values) {
		super('',values);
		
		if (values) {

			//this.metadata = JSON.parse(values['metadata'])
			this.metadata['id'] = values['primary_key']
			
			if (values['class_name'] == 'Order') 		{	this.model = new Order(this.metadata) }
			if (values['class_name'] == 'Product') 	{	this.model = new Product(this.metadata) }
			if (values['class_name'] == 'Task') 		{	this.model = new Task(this.metadata) }
			if (values['class_name'] == 'User') 		{	this.model = new User(this.metadata) }
			if (values['class_name'] == 'Customer') 	{	this.model = new Customer(this.metadata) }

		}

	}
	public getIcon() {
		return (this.model as Product).getIcon()
		//if (this.model instanceof Product) { return this.model.getIcon(this.metadata['variety']) }
	}

	public genderBgClass() {
		return (this.model as Customer).genderBgClass()
		//if (this.model instanceof Customer) { return this.model.genderBgClass(this.metadata['gender']) }
	}
}


export class TrolyTokenSearch extends TrolyObject {

	declare redirect: string;

	declare company: Company;
	declare product: Product;
	declare order: Order;

	constructor(values?: Object) {
		super('', values)
		
		if (values) {
			if (values['company']) { this.company = new Company(values['company']); }
			
			if (values['product']) { this.product = new Product(values['product']); }
			
			if (values['order']) { this.order = new Order(values['order']); }
		}

	}
}