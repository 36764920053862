import { ValidatorFn, Validators } from "@angular/forms";
import { TopbarNotification } from "../topbar.models";
import { TrolyFormGroup } from "../troly_form";
import { TrolyObject } from "../troly_object";
import { uuid } from "../utils.models";
import { Interaction } from "./interaction.model";
import { Order } from "./order.model";

export class Membership extends TrolyObject {

	declare company_id: uuid;
	declare club_id: uuid;
	_club_id: ValidatorFn[] = [Validators.required]
	declare customer_id: uuid;

	declare name: string;
	declare status: string;
	declare starts_at: Date;
	declare ends_at: Date;

	declare segment_id: string;

	/** Contains a copy of the terms and incentives of this membership club, as at the creation or activation of this membership */
	declare params: {
		settings: {}
		incentives: {}
	}

	/** The number of purchases made to maintain/upgrade this membership */
	declare purchases: number
	/** The commitment (per purchase) required of this membership */
	declare commitment_required: number;
	/** The commitment UNIT for this membership commitment_required, as dollars, weight or units */
	declare commitment_unit: number;
	/** The commitment (OVERALL) measured for the membership */
	declare commitment_measured: number;

	declare preferred_card_id: uuid
	declare delivery_address_id: uuid

	/** statuses and notes for each purchase */
	declare purchases_details: {
											purchase_all_notes: string,
		purchase_1_status: string, purchase_1_notes: string, purchase_1_deleted_by_id: uuid,
		purchase_2_status: string, purchase_2_notes: string, purchase_2_deleted_by_id: uuid,
		purchase_3_status: string, purchase_3_notes: string, purchase_3_deleted_by_id: uuid,
		purchase_4_status: string, purchase_4_notes: string, purchase_4_deleted_by_id: uuid,
		purchase_5_status: string, purchase_5_notes: string, purchase_5_deleted_by_id: uuid,
		purchase_6_status: string, purchase_6_notes: string, purchase_6_deleted_by_id: uuid,
		purchase_7_status: string, purchase_7_notes: string, purchase_7_deleted_by_id: uuid,
		purchase_8_status: string, purchase_8_notes: string, purchase_8_deleted_by_id: uuid,
	}

	/** the date at which a purchase is expected. This is picked up by automated club runs. */
	declare next_purchase_date: Date;
	/** membership override to force orders for this membership to be created at -- overrides company, club and customer settings */
	declare warehouse_id: uuid;

	/** Date at which the FIRST of X purchases was recorded ( @see Club.purchases ) */
	declare purchase_1_date: Date
	/** Order which was recorded as FIRST of X purchases */
	declare purchase_1_id: uuid
	declare purchase_2_date: Date
	declare purchase_2_id: uuid
	declare purchase_3_date: Date
	declare purchase_3_id: uuid
	declare purchase_4_date: Date
	declare purchase_4_id: uuid
	declare purchase_5_date: Date
	declare purchase_5_id: uuid
	declare purchase_6_date: Date
	declare purchase_6_id: uuid
	declare purchase_7_date: Date
	declare purchase_7_id: uuid
	declare purchase_8_date: Date
	declare purchase_8_id: uuid
	declare purchase_9_date: Date
	declare purchase_9_id: uuid
	declare purchase_10_date: Date
	declare purchase_10_id: uuid
	declare purchase_11_date: Date
	declare purchase_11_id: uuid
	declare purchase_12_date: Date
	declare purchase_12_id: uuid

	declare purchase_value: number;

	/** membership record this one was CREATED from */
	declare from_id: uuid; // not sure whether this is ever going to be useful on the frontend 🤷‍♀️
	/** membership record this one is RENEWED to */
	declare to_id: uuid; // not sure whether this is ever going to be useful on the frontend 🤷‍♀️

	/**
	 * Hold / Cancellation tracking
	 */
	/** Reason for holding or cancelling this membership */
	declare suspend_until_date: Date;
	declare cancelled_at: Date;
	declare suspend_by_id: uuid;
	declare suspend_reason: string;
	declare suspend_reason_other: string;

	/**
	 * Membership Prepayments
	 */
	declare prepayment_schedule: string
	declare prepayment_value: number;
	declare purchase_min_spend: number;
	declare purchase_max_spend: number;

	/**
	 * Stats tracking
	 */
	/** System that originated/created this record from */
	declare track_source: string;
	/** The channel this membership came from to the company */
	declare track_channel: string;
	_track_channel: ValidatorFn[] = [Validators.required]
	/** The partner who has signed up this member for the company */
	declare track_partner: string;
	/** internal marketing campaign name */
	declare track_campaign: string;

	declare track_staff_name: string;
	declare track_staff_id: uuid;
	declare since_date: Date;

	declare precommitment_payments:number;

	/**
	 * API Model Relationships
	 */

	orders?: Order[];
	interactions?: Interaction[]

	constructor(values?: Object) {
		super('membership', values);
	}

	public toNotification(notification?: Partial<TopbarNotification>): TopbarNotification {
		return super.toNotification(Object.assign({
			link: `/customers/${this.customer_id}/subscriptions`,
			linkFragment: 'clubs',
			linkParams: { highlight: this.id } // will trigger the highlightable directive to highlight the record
		}, notification)) as TopbarNotification
	}

	public toString() : string {
		return this.name;
	}

	public patchPurchaseNotes(which:string, notes:string, form?:TrolyFormGroup) {
		let patch = Object.assign(this.purchases_details || {}, { [`purchase_${which}_notes`]: notes})

		if (form) {
			form.patchValue({purchase_notes: patch})
		}
		this.purchases_details = patch as any // avoid having to explicitely declare the
		return this.purchases_details
	}
	public purchaseNotes(which:string): string {
		return this.purchases_details ? this.purchases_details[`purchase_${which}_notes`] : ''
	}

	get pendingOrders(): Order[] {
		return [];
	}

	public get purchase_value_w_commitment():number {

		let settings = this.params['precommitment']

		const applyPrecommitmentSaving = (level:string, value):number => {

			if (level.endsWith('%')) {
				return value - (value * (parseFloat(level.substring(0,level.length-1)) / 100))
			} else if (level.endsWith('$')) {
				return value - parseFloat(level.substring(0,level.length-1))
			}
			return value
		}
		return applyPrecommitmentSaving(settings[`pay${this.precommitment_payments}_discount`], this.purchase_value);
	}
}