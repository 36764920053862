import { inject, Injectable, NgZone, OnDestroy } from '@angular/core';
import { BehaviorSubject, filter, Observable, Subject, take } from 'rxjs';
import { environment } from 'src/environment/environment';
import { ITrolySocketNotification } from '../../models/form_objects';

import * as ActionCable from '@rails/actioncable';
import * as CryptoJS from "crypto-js";
import { IJobStatusDetail, Job } from '../../models/troly/job.model';
import { User } from '../../models/troly/user.model';
import { uuid } from '../../models/utils.models';
import { AuthService } from '../auth.service';
import { CompanyService } from './company.service';

@Injectable({
	providedIn: 'root',
})

export class WebsocketService implements OnDestroy {

	/**
	 * The name or identifier of the current class, not otherwise available when running in "production mode". 
	 * It is used to output debugging information on the console, and also attached to translations of labels, product tours, etc
	 */
	public readonly __name:string = 'WebsocketService';

	private wsBaseUrl: string;
	private cable: ActionCable.Connection;

	protected notifiers: { [key: string]: Subject<ITrolySocketNotification> } = {}
	protected channels: { [key: string]: ActionCable.Subscriptions } = {}

	protected companyService:CompanyService = inject(CompanyService)
	protected authService: AuthService = inject(AuthService)

	constructor() {

		let user: User = JSON.parse(window.localStorage.getItem('us')) || {}

		let trolyApiBaseUrl = environment.TROLY_API_URL;

		this.wsBaseUrl = trolyApiBaseUrl.replace(/^http/, 'ws').replace(/\/$/, '') + `/cable/${user.authentication_token}` // supports https => wss, reading the suer auth token which is always stored after login. this needs to be adjusted for the service to work in a "unauthenticated" mode.

	}


	public status: BehaviorSubject<IWebsocketStatus> = new BehaviorSubject('pending');

	protected tokens = {};

	/**
	 * 
	 * @param company_id 
	 */
	public connectCompanyChannel(company_id: uuid): Subject<ITrolySocketNotification> {
		if (!company_id) { throw `${this.__name}.connectCompanyChannel invalid company_id (${company_id})` }
		const channel = CryptoJS.MD5(`troly:${company_id}:company`).toString();
		return this.connect(channel, 'CompanyChannel');
	}

	/**
	 * 
	 * @param email 
	 * @returns 
	 */
	public connectUserChannel(email: string): Subject<ITrolySocketNotification> {
		if (!email) { throw `${this.__name}.connectUserChannel invalid email (${email})` }
		const channel = CryptoJS.MD5(`troly:${email}:user`).toString();
		return this.connect(channel, 'UserChannel');
	}
	protected userChannel: ActionCable.Subscription;

	/**
	 * 
	 * @param channel 
	 * @param scope 
	 * @returns 
	 */
	public connect(channel: uuid, scope: string): Subject<ITrolySocketNotification> {

		const key = channel.toString();

		if (!this.notifiers[key] && this.init()) {

			this.notifiers[key] = new Subject<ITrolySocketNotification>();

			if (scope == 'UserChannel') {
				if (this.userChannel) {
					throw "UserChannel already exists cannot connect multiple user channels"
				}
				this.userChannel = this.cable.subscriptions.create({ channel: scope, uuid: channel });
			} else {
				this.cable.subscriptions.create({ channel: scope, uuid: channel });
			}

		}
		return this.notifiers[key];
	}

	public send(message, channel) {
		if (this.channels[channel] && this.cable.readyState == WebSocket.OPEN) {
			this.channels[channel].send(JSON.stringify(message));
		}
	}

	protected zone: NgZone = inject(NgZone);
	private init(): boolean {

		if (this.cable == null) {

			//ActionCable.startDebugging();
			this.zone.runOutsideAngular(() => {

				this.cable = ActionCable.createConsumer(this.wsBaseUrl);
				this.cable.connection.open();

				this.cable.connection.webSocket.addEventListener('message', (_json) => { this.receiveMessage(_json) });
				this.cable.connection.webSocket.addEventListener('close', () => { this.close(); });

			})

		} else if (this.cable.connection.disconnected) {
			this.cable.connection.open();
		}
		return this.cable != null;

	}

	/**
	 * Close any channel or all, and terminates the cable connection when no more channels are subscribed to.
	 * @param channel 
	 */
	close(channel?: string) {

		if (this.status.value == 'connecting') { return }

		if (!channel && Object.keys(this.channels).length) {

			// close all channels
			console.warn(this.channels);
			debugger
			Object.keys(this.channels).forEach((_channel) => {
				console.log('closing channel', _channel)
				this.close(_channel);
			});

		} else {

			// close a single channel
			if (channel && this.channels[channel]) {
				if (this.channels[channel].consumer.connection.isOpen()) {
					this.channels[channel].close();
				}
				this.channels[channel].billing_periods.remove();
				delete this.channels[channel]
			}

			// if this was the last close the connection
			if (Object.keys(this.channels).length == 0) {
				if (this.userChannel && this.userChannel.consumer.connection.isOpen()) {
					this.userChannel.close(); 
				}
				this.status.next('disconnected')
			}

		}
	}

	/**
	 * 
	 * @param _json 
	 */
	receiveMessage(_json: any) {
		const msg = JSON.parse(_json.data);
		//this.status.next(this.cable.getState());
		//https://docs.anycable.io/misc/action_cable_protocol
		switch (msg.type) {

			case 'welcome':
				this.status.next('connected');
				break;

			case 'disconnect':
				console.log('disconnect', msg)
				this.status.next('disconnected');
				if (msg.reconnect) { window.location.reload(); }
				if (!msg.reconnect) { this.authService.logout(msg.reason) }
				
				break;

			case 'confirm_subscription':
				break;

			case 'reject_subscription':
				break;

			case 'ping':
				this.status.next('ping');
				setTimeout(() => { this.status.next('connected'); }, 500);
				if (this.userChannel) {
					this.userChannel.send({ action: 'pong' });
					if (this._currentTimeoutTimer) { clearTimeout(this._currentTimeoutTimer); }
					this._currentTimeoutTimer = setTimeout(() => { 
						console.log('ping Timeout Reached -- reloading')
						window.location.reload(); 
					}, 60*1000)
				}
				break;

			default:

				let identifier = JSON.parse(msg.identifier)
				if (this.notifiers[identifier.uuid]) {
					this.notifiers[identifier.uuid].next(msg.message); // this replaces the need to have each subscriptions.create to handle incoming messages
					
					//if (msg.message.model == 'job' && msg.message.operation == 'completed') { this.notifiers[identifier.uuid].complete(); delete this.notifiers[identifier.uuid]; }

				} else {
					console.error('socket message received after completion', msg)
				}
				
				break;
		}
	}
	private _currentTimeoutTimer;

	ngOnDestroy(): void {
		this.cable.close();
	}

	public subscribeSocketJob(jobObserver: Observable<Job>): Observable<IJobStatusDetail> {

		return new Observable<IJobStatusDetail>(socketObserver => {
			
			socketObserver.next({ // sending out an indicator that the channel has been received and the job is now queued
				message: "Base.sending", type: 'sending'
			} as IJobStatusDetail); 
			
			jobObserver.pipe(take(1)).subscribe({
				next: (job: Job) => {
					
					socketObserver.next({ // sending out an indicator that the channel has been received and the job is now queued
						message: "Base.queued", type: 'queued', track_job_id:job.id
					} as IJobStatusDetail); 

					this.connect(job.id, 'CompanyChannel').pipe(
						filter(_ => ['queued', 'start', 'update', 'pulse', 'end', 'error', 'result','completed'].includes(_.operation)),
					).subscribe({
						next: (_) => {  
							socketObserver.next(_.data['last_status_details'] as IJobStatusDetail)
						},
						complete: () => { 
							setTimeout(() => {
								socketObserver.complete();
							}, 5000) // wait for any outstanding progress messages
						}
					});
				},
				error: (_) => { socketObserver.error(_) }

			});
		});
	}
}


export type IWebsocketStatus = 'pending'|'ping'|'connecting'|'connected'|'disconnected'|'error';