<form [formGroup]="form" class="no-live-loading">
	<form-status [formGroup]="form">
		<span #status code="UNKNOWN_ADDRESS">Address doesn't exist, please try again</span>
	</form-status>
	<mat-form-field class="no-live-loading">
		<mat-label>{{ fieldName }}</mat-label>
		<i matPrefix class="bx bx-search-alt"></i>
		<input matInput placeholder="Search ..." formControlName="area" type="text"
			[matAutocomplete]="geoSearch" autocomplete="off" />
		<mat-autocomplete #geoSearch="matAutocomplete" (optionSelected)="searchOptionSelected($event.option.value)"
			class="search autocomplete-{{ fieldSize }} {{ form.loading }}">

			<div *ngIf="!records && ['loading','loaded'].includes(form.loading)" class="search-intro">
				<div class="d-flex justify-content-center">
					<div *ngFor="let scope of ['home','buildings','map']" class="avatar-title h5 bg-secondary bg-soft rounded-circle">
						<i class="bx bx-{{ scope }}"></i>
					</div>
				</div>
				<h6>Search for any address</h6>
			</div>

			<div *ngIf="form.loading == 'loaded' && records?.length === 0" class="search-intro text-danger">
				<div class="d-flex justify-content-center">
					<div class="avatar-title h5 rounded-circle bg-danger bg-soft text-dark">
						<i class="bx bx-x" style="font-size:24px;"></i>
					</div>
				</div>
				<h6>No results found, try again.</h6>
			</div>

			<div *ngIf="form.loading == 'loading-error'" class="search-intro text-danger">
				<div class="d-flex justify-content-center">
					<div class="avatar-title h5 rounded-circle bg-danger bg-soft text-dark">
						<i class="bx bx-x" style="font-size:24px;"></i>
					</div>
				</div>
				<h6>No results to received. {{ form.errorCode }}</h6>
			</div>

			<div style="max-height:225px;overflow:scroll">
				<mat-option *ngFor="let result of records" [value]="result" class="search-result two-lines-option icon-vertical-center">
					
					<div class="avatar-xs text-center bg-light my-auto rounded-circle">
						<i class="text-dark mdi mdi-map{{ result.precision == 'ROOFTOP' ? '-marker':''}} font-size-22"></i>
					</div>
					<div>
						<h6>{{ result.line1 }}</h6>
						<p>{{ result.line3 }}, {{ result.line4 }}</p>
					</div>

				</mat-option>
			</div>

			<div class="{{ bsCssClassPrefix }}-footer">

				<p *ngIf="form.loading == 'loaded' && !records"> Start typing to search.. </p>
				<p *ngIf="form.loading == 'loaded' && records?.length == 0"> Start typing to search.. </p>
				<p *ngIf="form.loading == 'loaded' && records?.length > 0"> Showing {{ records.length > pageSize ? pageSize : records.length }} of {{ totalRecords }} results</p>
				<p *ngIf="form.loading == 'loading'"> Searching.. </p>

			</div>
			<mat-option class="d-none"><!-- without an option present, the panel doesn't show up (instructions and footer are not clickable options) --></mat-option>
		</mat-autocomplete>
		<mat-hint *ngIf="fieldHint">{{ fieldHint }}</mat-hint>
	</mat-form-field>
</form>